import {
  CLEAR_NAVIGATE_PATH,
  CLEAR_TAB_NAME,
  GET_NAVIGATE_PATH,
  GET_TAB_NAME,
} from "../actionType";

export const getNavigatePath = (data) => {
  return {
    type: GET_NAVIGATE_PATH,
    payload: data,
  };
};

export const clearNavigatePath = (data) => {
  return {
    type: CLEAR_NAVIGATE_PATH,
    payload: data,
  };
};

export const getTabName = (data) => {
  return {
    type: GET_TAB_NAME,
    payload: data,
  };
};

export const clearTabName = (data) => {
  return {
    type: CLEAR_TAB_NAME,
    payload: data,
  };
};
