import React from "react";
import mail from "../../../assets/images/Little kid with oversize shoes.svg";
import { withTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const RegistrationComplete = ({ t }) => {
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate("/");
  };
  const handleNavigateUpload = () => {
    navigate("/UploadDadId");
  };
  return (
    <div className="option-detail">
      <div className="container">
        <div className="row align-items-center column-r Ottimo_section  registration_complete_section">
          <div className="col-lg-7">
            <div className="right-side text-center ">
              <div className="meditation-yaga-section">
                <h2>{t("La registrazione è completa!")}</h2>
                <p>
                  {t(
                    "Adesso puoi accedere ai tuoi questionari. Se vuoi, puoi anche caricare i documenti del padre del bambino."
                  )}
                </p>
              </div>
            </div>
            <div className="btn-section text-center">
              <button className="solid-btn" onClick={handleNavigateUpload}>
                {t("Carica i documenti")}
              </button>
            </div>
            <div className="documenti-content text-center pt-4">
              <p onClick={handleNavigate}>{t("Procedi ai questionari")}</p>
            </div>
          </div>
          <div className="col-lg-5">
            <div className="left-side box-shadow-none bg-none justify-content-center p-0">
              <div className="meditation-yaga-img">
                <img src={mail} alt="Mail" className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(RegistrationComplete);
