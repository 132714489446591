import {
  GET_PRIVACY_AND_TERMS_LIST,
  GET_PRIVACY_AND_TERMS_LIST_FAILED,
  GET_PRIVACY_AND_TERMS_LIST_SUCCESS,
} from '../actionType'

const initialState = {
  privacy: null,
  terms: null,
  loading: false,
  error: null,
}

function PrivacyAndTermsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_PRIVACY_AND_TERMS_LIST:
      return {
        ...state,
        loading: true,
      }
    case GET_PRIVACY_AND_TERMS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        privacy: action.payload.privacy,
        terms: action.payload.terms,
      }
    case GET_PRIVACY_AND_TERMS_LIST_FAILED:
      return {
        ...state,
        loading: false,
        error: 'Something went wrong',
      }
    default:
      return state
  }
}

export default PrivacyAndTermsReducer
