import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserById, updateProfile } from "../../../redux/user/userAction";
import { useNavigate } from "react-router-dom";
import { withTranslation } from "react-i18next";

const Residenacy = ({ t }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [userDetails, setUserDetails] = useState({});
  const [error, setError] = useState({});
  const userData = useSelector((state) => state?.UserReducer?.userDetails);
  const userId = localStorage.getItem("userId");

  useEffect(() => {
    dispatch(getUserById(userId));
  }, [dispatch, userId]);

  useEffect(() => {
    if (userData !== null) {
      const update = {
        ...userData,
        piazzaWay:
          userData?.piazzaWay === undefined
            ? userData?.address?.indir_resid
            : userData?.piazzaWay,
        civic:
          userData?.civic === undefined
            ? userData?.address?.civico_resid
            : userData?.civic,
        city:
          userData?.city === undefined
            ? userData?.address?.cap_resid
            : userData?.city,
        CAP:
          userData?.CAP === undefined
            ? userData?.address?.comune_resid
            : userData?.CAP,
        province:
          userData?.province === undefined
            ? userData?.address?.prov_resid
            : userData?.province,
      };
      const finalValue = { ...update };
      setUserDetails(finalValue);
    }
  }, [userData]);

  const handleChange = (e) => {
    if (e.target.name === "province") {
      const value = e.target.value.toUpperCase();
      setUserDetails((prev) => ({
        ...prev,
        [e.target.name]: value,
      }));
    } else {
      setUserDetails((prev) => ({
        ...prev,
        [e.target.name]: e.target.value,
      }));
    }
  };

  const handleSubmit = () => {
    dispatch(
      updateProfile({
        body: userDetails,
        id: userId,
        callBack: () => {
          navigate("/user-details");
        },
      })
    );
  };


  useEffect(() => {
    if (userDetails) {
      const userDetailsCopy = { ...userDetails };
      delete userDetailsCopy.isDeleted;
      delete userDetailsCopy.isBlock;
      delete userDetailsCopy.__v;

      const newErrors = {};

      if (!userDetailsCopy.piazzaWay) {
        newErrors.piazzaWay = "Inserisci una Via/Piazza valida";
      }
      if (!userDetailsCopy.civic) {
        newErrors.civic = "Inserisci un Civico valido";
      }
      if (!userDetailsCopy.city) {
        newErrors.city = "Inserisci un Comune di nascita valido";
      }
      // CAP validation: 5 digits, no letters or special characters
      if (!userDetailsCopy.CAP || !/^\d{5}$/.test(userDetailsCopy.CAP)) {
        newErrors.CAP = "Inserisci un CAP valido.";
      }

      // Province validation: 2 uppercase letters, no numbers or special characters
      if (
        !userDetailsCopy.province ||
        !/^[A-Z]{2}$/.test(userDetailsCopy.province)
      ) {
        newErrors.province =
          "Inserisci la sigla della provincia. Massimo due lettere.";
      }

      setError(newErrors);
    }
  }, [userDetails]);

  return (
    <div className="option-detail residenza-section">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="right-side address-detail mt-0">
              <div className="input-box">
                <div className="option-box box-shadow-none bg-none p-0">
                  <h3>{t("Residenza")}</h3>
                </div>
                <div className="address-detail-inner desktop-details">
                  <form action method="POST">
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                        <div className="form-group">
                          <div className="error">{error?.piazzaWay || ""}</div>
                          <label className="profile_details_text">
                            {t("Via/Piazza")}
                          </label>
                          <input
                            type="text"
                            name="piazzaWay"
                            className="form-control"
                            value={userDetails?.piazzaWay || ""}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                        <div className="form-group">
                          <div className="error">{error?.civic || ""}</div>
                          <label className="profile_details_text">
                            {t("Civico")}
                          </label>
                          <input
                            type="text"
                            name="civic"
                            className="form-control"
                            value={userDetails?.civic || ""}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className="form-group">
                          <div className="error">{error?.city || ""}</div>
                          <label className="profile_details_text">
                            {t("Comune di")}
                          </label>
                          <input
                            type="text"
                            name="city"
                            className="form-control"
                            value={userDetails?.city || ""}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className="form-group">
                          <div className="error">{error?.CAP || ""}</div>
                          <label className="profile_details_text">CAP</label>
                          <input
                            type="text"
                            name="CAP"
                            className="form-control"
                            value={userDetails?.CAP || ""}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className="form-group">
                          <div className="error">{error?.province || ""}</div>
                          <label className="profile_details_text">
                            {t("Provincia")}
                          </label>
                          <input
                            type="text"
                            name="province"
                            className="form-control"
                            value={userDetails?.province || ""}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                {Object.keys(error).length > 0 ? (
                  <>
                    <div
                      style={{
                        margin: "15px 30px 0px 30px",
                        textAlign: "center",
                      }}
                    >
                      <span style={{ color: "red" }}>
                        Per procedere e necessario compilare tutti i campi
                      </span>
                    </div>
                  </>
                ) : (
                  <></>
                )}
                <div className="btn-section text-center mt-50 salva-button">
                  <button
                    disabled={Object.keys(error).length > 0}
                    onClick={handleSubmit}
                    className={`solid-btn ${
                      Object.keys(error).length > 0 ? "fade-btn" : "bg-color"
                    } `}
                  >
                    {t("Salva ed esci")}{" "}
                    <span className="lock-img">
                      {Object.keys(error).length > 0 ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="1em"
                          height="1em"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="currentColor"
                            d="M6 22q-.825 0-1.412-.587T4 20V10q0-.825.588-1.412T6 8h1V6q0-2.075 1.463-3.537T12 1t3.538 1.463T17 6v2h1q.825 0 1.413.588T20 10v10q0 .825-.587 1.413T18 22zm0-2h12V10H6zm6-3q.825 0 1.413-.587T14 15t-.587-1.412T12 13t-1.412.588T10 15t.588 1.413T12 17M9 8h6V6q0-1.25-.875-2.125T12 3t-2.125.875T9 6zM6 20V10z"
                          />
                        </svg>
                      ) : (
                        ""
                      )}
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(Residenacy);
