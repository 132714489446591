import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { otpVerify } from "../../../redux/auth/authAction";
import { withTranslation } from "react-i18next";

const VerificationAccount = ({ t }) => {
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const phoneNumber = localStorage.getItem("phoneNumber");
  const userId = localStorage.getItem("userId");
  const emailValue = localStorage.getItem("emailValue");

  useEffect(() => {
    const handleOTP = () => {
      const enteredOTP = otp?.join("");
      const sendData = {
        otpverification: enteredOTP,
        phoneNumber: phoneNumber,
        userId: userId,
        email: emailValue,
      };
      dispatch(
        otpVerify({
          sendData,
          callBack: () => {
            navigate("/auth/abbiamoQuasi");
          },
          onError: () => {
            setError(
              t(
                "Qualcosa è andato storto. Inserisci di nuovo il codice o inserisci di nuovo il numero di telefono."
              )
            );
          },
        })
      );
      setOtp(["", "", "", "", "", ""]); // Clear OTP
    };

    if (otp.every((digit) => digit !== "")) {
      handleOTP();
    }
  }, [otp, phoneNumber, userId, dispatch, navigate, t, emailValue]);

  const handleChange = (index, value) => {
    setError("");
    if (/^\d*$/.test(value) && value.length <= 1) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
    }

    // Move focus based on input length
    if (value.length > 0 && index < 5) {
      const nextIndex = index + 1;
      if (nextIndex < 6) {
        const nextInput = document.getElementById(`otp__field__${nextIndex}`);
        if (nextInput) {
          nextInput.focus();
        }
      }
    } else if (value.length === 0 && index > 0) {
      const previousIndex = index - 1;
      const previousInput = document.getElementById(
        `otp__field__${previousIndex}`
      );
      if (previousInput) {
        previousInput.focus();
      }
    }
  };

  const handleKeyDown = (event, index) => {
    if (event.key === "Backspace" && index > 0 && otp[index] === "") {
      // Move focus to the previous input field if Backspace is pressed and current field is empty
      document.getElementById(`otp__field__${index - 1}`).focus();
    } else if (
      event.key >= "0" &&
      event.key <= "9" &&
      index < 5 &&
      otp[index] !== ""
    ) {
      // Move focus to the next input field if a digit is entered and current field is not empty
      document.getElementById(`otp__field__${index + 1}`).focus();
    }
  };

  const handlePaste = (event) => {
    const paste = event.clipboardData.getData("text");
    const pastedOtp = paste.split("").slice(0, 6);
    if (pastedOtp.every((char) => /^\d$/.test(char))) {
      setOtp(pastedOtp.concat(Array(6 - pastedOtp.length).fill("")));
      document.getElementById(`otp__field__${pastedOtp.length}`).focus();
    }
    event.preventDefault();
  };

  /***Handles the navigation ***/
  const handleNavigation = () => {
    navigate(`/auth/accountconfermo/${userId}`, {
      state: { resendCode: true },
    });
  };

  return (
    <div className="option-detail">
      <div className="container">
        <div className="row align-items-center column-r Ottimo_section account_confermato_section verifica-account">
          <div className="col-lg-7">
            <div className="right-side address-detail">
              <div className="address-detail-inner">
                <div className="form-group mt-0">
                  <div className="otp-input-fields">
                    {otp.map((value, index) => (
                      <input
                        key={index}
                        id={`otp__field__${index}`}
                        type="text"
                        className="otp__digit otp__field"
                        value={value}
                        name="otpverification"
                        onChange={(e) => handleChange(index, e.target.value)}
                        onKeyDown={(e) => handleKeyDown(e, index)}
                        onPaste={handlePaste}
                        maxLength={1}
                      />
                    ))}
                  </div>
                  {error && <div className="error">{t(error)}</div>}
                </div>
              </div>
              <div className="link text-center">
                <p className="link" onClick={handleNavigation}>
                  {t("Inserisci di nuovo il numero di telefono")}
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-5">
            <div className="meditation-yaga-section">
              <h2>{t("Verifica account")}</h2>
              <p>{t("Inserisci il codice a 6 cifre ricevuto via SMS")}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(VerificationAccount);
