import React from "react";
import laptopLayingChill from "../../../assets/images/laptop-laying-chill.svg";
import { withTranslation } from "react-i18next";

const Contact = ({ t }) => {
  return (
    <div className="option-detail faq-section main-contact">
      <div className="container">
        <div className="row align-items-center contact_d">
          <div className="col-lg-7">
            <div className="right-side">
              <div className="meditation-yaga-section faq-content text-center contact-text">
                <h2>{t("Contattaci")}</h2>
                <h3>Contatta un’operatrice</h3>
              </div>

              <div className="contact-detail">
                <h4>{t("Paola Lorusso")}</h4>
                <h5> +39 06 99722189</h5>
              </div>

              <div className="contact-detail">
                <h4>{t("Chelo Greta Salatino")}</h4>
                <h5> +39 06 99722190</h5>
              </div>

              <div className="contact-detail-email">
                <h4>{t("Scrivici una mail a")}</h4>
                <div className="contact-detail">
                  <h6> indaconati@deplazio.it</h6>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-5">
            <div className="box-shadow-none bg-none justify-content-center">
              <div className="contact-img">
                <img
                  src={laptopLayingChill}
                  alt="laptop-laying-chill"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(Contact);
