import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { clearNavigatePath, getNavigatePath } from "../../../redux/navigatePath/navigateAction";
import { useDispatch, useSelector } from "react-redux";
import { getQuestionsFormDetails } from "../../../redux/questionaries/questionariesAction";

const FormIntro = ({ t }) => {
  const formDetails = useSelector((state) => state?.QuestionariesReducer?.formDetails);
  const userDetails = useSelector((state) => state?.UserReducer?.userDetails);

  const { state } = useLocation();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getQuestionsFormDetails(state?.id))
    dispatch(
      getNavigatePath({
        title: t("Compila"),
        path: `/form/${state?.id}`,
      })
    );
  }, [dispatch, t, state?.id]);

  useEffect(() => () => dispatch(clearNavigatePath()), [dispatch]);
  return (
    <>
      <div>
        <div className="option-detail login_section py-100 formintro ">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-5">
                <div className="left-side box-shadow-none bg-none justify-content-center">
                  <div className="meditation-yaga-img">
                    <img src={formDetails?.image} alt="login" className="img-fluid" />
                  </div>
                </div>
              </div>
              <div className="col-lg-7">
                <div className="right-side text-center">
                  <div className="meditation-yaga-section">
                    <h2>{t('Ciao')}, {userDetails?.firstName}{" "}{userDetails?.lastName}</h2>
                    <p>
                      {formDetails?.description}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withTranslation()(FormIntro);
