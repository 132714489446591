import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import loginImg from "../../../assets/images/Completeimg.png";
import { CModal, CModalBody, CModalTitle } from "@coreui/react";
import { withTranslation } from "react-i18next";
import { getPrivacyAndTerms } from "../../../redux/privacyAndTerms/privacyAndTermsAction";

const Privacy = ({ t }) => {
  // const PrivacyAndTermsData = useSelector(
  //   (state) => state?.PrivacyAndTermsReducer
  // );
  const [visible, setVisible] = useState({ terms: false, privacy: false });

  const [lastClickedCheckbox, setLastClickedCheckbox] = useState({
    terms: "",
    privacy: "",
  });

  const [error, setError] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [accordation, setAccordation] = useState({});
  const teams = localStorage.getItem("teams");
  const privacy = localStorage.getItem("privacy");
  useEffect(() => {
    dispatch(getPrivacyAndTerms());
  }, [dispatch]);

  const handleVisibleModel = (value) => {
    if (value === "terms") {
      setVisible({ ...visible, terms: true });
    } else {
      setVisible({ ...visible, privacy: true });
    }
    setError();
  };

  const handleProceed = (event) => {
    if (
      lastClickedCheckbox.terms !== "" &&
      lastClickedCheckbox.privacy !== ""
    ) {
      localStorage.setItem("teams", lastClickedCheckbox.terms);
      localStorage.setItem("privacy", lastClickedCheckbox.privacy);
      navigate("/auth/title");
    } else {
      setError("Please tick the checkbox!");
    }
  };

  useEffect(() => {
    if (teams && privacy) {
      setLastClickedCheckbox({
        terms: teams,
        privacy: privacy,
      });
    }
  }, [teams, privacy]);

  return (
    <>
      <div className="option-detail login_section py-100 privacy-section">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5">
              <div className="left-side box-shadow-none bg-none justify-content-center">
                <div className="meditation-yaga-img privacy">
                  <img src={loginImg} alt="login" className="img-fluid complete-image" />
                </div>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="right-side text-center">
                <div className="meditation-yaga-section">
                  <h2>{t("Consenso e partecipazione")}</h2>
                  <p>
                    {t(
                      "Prima di procedere, per favore leggi e accetta i termini di “informativa dello studio” e di “Informativa al trattamento dei dati personali”. La tua consapevolezza e la tua privacy sono importanti per noi!"
                    )}
                  </p>
                </div>
                <div className="option-box login_check_box bg-none box-shadow-none mb-0 privacycheck">
                  <div className="checkbox">
                    <label className="lns-checkbox">
                      <input
                        type="checkbox"
                        checked={lastClickedCheckbox?.privacy}
                        onClick={() => handleVisibleModel("privacy")}
                      />
                      <span>{t("Informativa dello studio")}</span>
                    </label>
                  </div>
                  <div className="checkbox">
                    <label className="lns-checkbox">
                      <input
                        type="checkbox"
                        checked={lastClickedCheckbox?.terms}
                        onClick={() => handleVisibleModel("terms")}
                      />
                      <span>
                        {t("Informativa al ")}
                        <br />
                        {t("trattamento dei dati")}
                        <br />
                        {t("personali")}
                      </span>
                    </label>
                  </div>
                </div>
                <span className="error">{error}</span>
              </div>
              <div className="btn-section text-center">
                <button href="#" className="solid-btn" onClick={handleProceed}>
                  {t("Procedi")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <CModal
        scrollable
        visible={visible.privacy}
        onClose={() => setVisible(false)}
        aria-labelledby="ScrollingLongContentExampleLabel2"
        className="custom-model c_modal"
      >
        <CModalBody>
          <div className="login_detail_nner">
            <CModalTitle component="h1" id="ScrollingLongContentExampleLabel2">
              Scheda informativa e manifestazione del consenso al trattamento
              dei dati personali*
            </CModalTitle>
            <div className="h-24"></div>
            <h2>Versione 1 del 5 marzo 2024</h2>
            <div className="h-24"></div>
            <p className="small">
              * (Ai sensi delle linee guida per i trattamenti di dati personali
              nell’ambito delle sperimentazioni cliniche di medicinali –
              adottate dal Garante per la protezione dei dati personali con
              Deliberazione n.52 del 24 luglio 2008 G.U. n. 190 del 14 agosto
              2008, in conformità con il Testo unico D.L. 30 giugno 2003 n. 196
              “ Codice in materia di protezione dei dati personali e in
              conformità con il Regolamento Generale sulla Protezione dei Dati,
              UE 2016/679)
            </p>
            <p className="mb-0">
              Ai sensi del Regolamento UE 2016/679 General Data Protection
              Regulation (GDPR) relativo alla protezione delle persone fisiche
              con riguardo al trattamento dei dati personali, nonché alla libera
              circolazione di tali dati, ti informiamo che tutte le informazioni
              personali raccolte saranno trattate nel rispetto delle
              disposizioni del Regolamento UE 2016/679, anche detto GDPR, e
              della normativa italiana riguardanti la tutela dei dati personali
              (d.lgs. 196/2003 ss.mm.ii.). Ti informano che i tuoi dati saranno
              trattati secondo le modalità di seguito riportate.
            </p>

            <div className="main-accordation">
              <div className="accordion">
                <div className="accordion-item">
                  <button
                    id="accordion-button-1"
                    onClick={() => {
                      setAccordation({
                        accordionbutton1: !accordation.accordionbutton1,
                      });
                    }}
                    aria-expanded={accordation.accordionbutton1}
                  >
                    <h2 className="accordion-title">
                      Finalità del trattamento dei dati
                    </h2>
                    <span className="icon" aria-hidden="true" />
                  </button>
                  <div className="accordion-content">
                    <div className="h-24"></div>
                    <p>
                      La Regione Lazio in qualità di titolare del trattamento,
                      in accordo alle responsabilità previste dalle norme di
                      buona pratica clinica (D.L. 211/2003), tratterà attraverso
                      il responsabile del trattamento individuato nel
                      Dipartimento di Epidemiologia del Sistema Sanitario della
                      Regione Lazio i tuoi dati personali (nome, cognome, luogo
                      e data di nascita, codice fiscale, indirizzo di domicilio,
                      indirizzo di residenza, email, recapiti telefonici), i
                      tuoi dati sulle abitudini di vita e sullo stato
                      socioeconomico, sulle esposizioni ambientali e la salute
                      soltanto nella misura in cui siano indispensabili in
                      relazione all'obiettivo dello studio, ai sensi del
                      Provvedimento del Garante per la protezione dei dati
                      personali n. 146 del 5 giugno 2019, recante le
                      prescrizioni relative al trattamento di categorie
                      particolari di dati, ai sensi dell’art. 21, comma 1 del D.
                      Lgs. 10 agosto 2018, n. 101, ai sensi del GDPR e del D.
                      Lgs. 101/2018 di adeguamento della normativa nazionale
                      alle disposizioni del GDPR.
                    </p>
                    <p>
                      Il trattamento dei dati personali relativi a nome,
                      cognome, luogo e data di nascita, codice fiscale,
                      indirizzo di domicilio, indirizzo di residenza, email,
                      recapiti telefonici, è indispensabile allo svolgimento
                      dello studio: il rifiuto di conferirli non ti consentirà
                      di parteciparvi.
                    </p>
                  </div>
                </div>

                <div className="accordion-item">
                  <button
                    id="accordion-button-2"
                    onClick={() => {
                      setAccordation({
                        accordionbutton2: !accordation.accordionbutton2,
                      });
                    }}
                    aria-expanded={accordation.accordionbutton2}
                  >
                    <h2 className="accordion-title">
                      Base giuridica del trattamento dei dati personali
                    </h2>
                    <span className="icon" aria-hidden="true" />
                  </button>

                  <div className="accordion-content">
                    <div className="h-24"></div>
                    <p>
                      La partecipazione al presente studio avviene su base
                      volontaria, libera e consapevole mediante la
                      sottoscrizione del consenso al trattamento dei dati
                      personali, ai sensi degli artt. 6, par. 1, lett. a) e 9,
                      par. 2, lett. a) GDPR. La tua partecipazione allo studio è
                      importante perché offre un aiuto per l’avanzamento della
                      ricerca scientifica e, aumentando il bagaglio delle
                      conoscenze disponibili, per il progresso della società.
                    </p>
                    <p>
                      Il tuo consenso esplicito può essere liberamente revocato
                      in ogni momento e con la facilità con cui lo ha conferito,
                      ai sensi dell’art. 17, par.1, lett. b) GDPR, senza
                      compromettere lo studio e/o la validità dei suoi
                      risultati; l'eventuale revoca avrà valore solo per il
                      futuro, restando valido il trattamento eseguito fino a
                      quel momento.
                    </p>
                  </div>
                </div>

                <div className="accordion-item">
                  <button
                    id="accordion-button-3"
                    onClick={() => {
                      setAccordation({
                        accordionbutton3: !accordation.accordionbutton3,
                      });
                    }}
                    aria-expanded={accordation.accordionbutton3}
                  >
                    <h2 className="accordion-title">
                      Natura dei dati trattati
                    </h2>
                    <span className="icon" aria-hidden="true" />
                  </button>

                  <div className="accordion-content">
                    <div className="h-24"></div>
                    <p>
                      Verranno trattati alcuni tuoi dati personali (quali luogo
                      e data di nascita, codice fiscale, indirizzo di domicilio,
                      indirizzo di residenza, email, recapiti telefonici), e
                      dati relativi alla tua salute e a quella di tuo figlio; in
                      base alle finalità della ricerca e alle caratteristiche
                      dello studio, saranno raccolti dati relativi alla storia
                      medica, alla situazione socioeconomica e agli stili di
                      vita tua e di tuo figlio.
                    </p>
                  </div>
                </div>

                <div className="accordion-item">
                  <button
                    id="accordion-button-4"
                    onClick={() => {
                      setAccordation({
                        accordionbutton4: !accordation.accordionbutton4,
                      });
                    }}
                    aria-expanded={accordation.accordionbutton4}
                  >
                    <h2 className="accordion-title">
                      Modalità del trattamento
                    </h2>
                    <span className="icon" aria-hidden="true" />
                  </button>

                  <div className="accordion-content">
                    <div className="h-24"></div>
                    <p>
                      Tutte le informazioni raccolte nell'ambito dello studio
                      saranno trattate nel rispetto delle disposizioni del GDPR
                      e della normativa italiana riguardante la gestione e la
                      tutela dei dati personali.
                    </p>
                    <p>
                      I tuoi dati personali saranno trattati con la massima
                      riservatezza, nel rispetto delle norme di sicurezza per
                      gli archivi informatici e cartacei dalla normativa, e
                      verranno utilizzati solo da personale autorizzato
                      esclusivamente per scopi di ricerca scientifica.
                    </p>
                    <p>
                      Il trattamento dei dati personali è realizzato per mezzo
                      delle operazioni indicate all’art. 4, par. 1, n. 2 GDPR e
                      più precisamente: raccolta, registrazione, conservazione,
                      consultazione, utilizzo, elaborazione, comunicazione,
                      cancellazione e distruzione dei dati.
                    </p>
                    <p>
                      Ti informiamo, inoltre, che il trattamento dei dati
                      avviene mediante strumenti idonei a garantire la sicurezza
                      e la riservatezza e può essere effettuato anche attraverso
                      strumenti automatizzati atti a memorizzare e gestire i
                      dati stessi, con tecniche di cifratura o l'utilizzo di
                      codici identificativi tali da consentire l'identificazione
                      solo in caso di necessità.
                    </p>
                    <p>
                      I dati sono pseudonimizzati in quanto, dopo la raccolta,
                      sono conservati separatamente da quelli identificativi
                      (nome, cognome ecc.), essendo assegnato un codice ai
                      partecipanti che non ne permette la diretta
                      identificazione. I dati sono crittografati nel rispetto
                      delle misure di sicurezza per la tutela della privacy e
                      della confidenzialità dei dati, previste dalla normativa
                      sopra indicata.
                    </p>
                    <p>
                      Gli autorizzati al trattamento (medici, statistici e altre
                      figure professionali operanti per il Responsabile) hanno
                      accesso ai dati esclusivamente in forma pseudonimizzata
                      ogni volta che si renda necessaria l'identificazione
                      univoca, quindi privati del codice identificativo, in
                      tutte le altre istanze, sempre unicamente per gli scopi
                      della ricerca. L'accesso ai soli dati personali avviene
                      per esigenze di follow-up. I dati dello studio Indaco -
                      Coorte dei Nati sono ospitati su un server dedicato di
                      proprietà del Dipartimento di Epidemiologia del SSR del
                      Lazio, parte integrante dell’infrastruttura informatica in
                      uso e fisicamente presente all’interno dei locali adibiti
                      a sede dello stesso. Ciò garantirà la sicurezza del dato e
                      ne consentirà la gestione e l’analisi. La gestione del
                      database e le analisi statistiche saranno effettuati dal
                      Dipartimento di Epidemiologia del SSR del Lazio. L'accesso
                      alle virtual machine e l’amministrazione software delle
                      macchine è consentito esclusivamente al personale
                      competente appartenente al Dipartimento di Epidemiologia
                      del Sistema Sanitario della Regione Lazio. Le informazioni
                      raccolte sono strettamente confidenziali e saranno usate
                      in modo aggregato e anonimo per la preparazione di
                      rapporti scientifici, nei quali i partecipanti allo studio
                      non saranno in alcun modo identificabili. I risultati
                      potranno essere divulgati in forma strettamente anonima e
                      aggregata, su riviste scientifiche nazionali e
                      internazionali e sul sito Internet{" "}
                      <a href="/">www.progettoindaco.it</a>
                    </p>
                  </div>
                </div>

                <div className="accordion-item">
                  <button
                    id="accordion-button-5"
                    onClick={() => {
                      setAccordation({
                        accordionbutton5: !accordation.accordionbutton5,
                      });
                    }}
                    aria-expanded={accordation.accordionbutton5}
                  >
                    <h2 className="accordion-title">
                      Categorie di soggetti cui i dati possono essere comunicati
                    </h2>
                    <span className="icon" aria-hidden="true" />
                  </button>

                  <div className="accordion-content">
                    <div className="h-24"></div>
                    <p>
                      I dati saranno trattati all’interno dell’ente Responsabile
                      da soggetti autorizzati al trattamento sotto la
                      responsabilità dello stesso ente per le finalità sopra
                      riportate nel rispetto delle regole deontologiche e del
                      codice etico. Ti informiamo, inoltre, che i dati raccolti
                      saranno utilizzati in forma pseudonimizzata per le analisi
                      statistiche ed epidemiologiche e potranno essere
                      comunicati a soggetti partner della ricerca. Tutte le
                      operazioni di trattamento, effettuate solo da personale
                      debitamente istruito ed autorizzato dal Responsabile,
                      secondo le indicazioni del Titolare, avverranno nel
                      rispetto del segreto professionale, del segreto d'ufficio
                      e dei principi di correttezza, liceità e trasparenza,
                      secondo quanto disposto dalle normative vigenti.
                    </p>
                  </div>
                </div>

                <div className="accordion-item">
                  <button
                    id="accordion-button-6"
                    onClick={() => {
                      setAccordation({
                        accordionbutton6: !accordation.accordionbutton6,
                      });
                    }}
                    aria-expanded={accordation.accordionbutton6}
                  >
                    <h2 className="accordion-title">
                      Diffusione e trasferimento dei dati
                    </h2>
                    <span className="icon" aria-hidden="true" />
                  </button>

                  <div className="accordion-content">
                    <div className="h-24"></div>
                    <p>
                      Il Dipartimento di Epidemiologia, nell'ambito di progetti
                      collaborativi con finalità coerenti con quelle del studio
                      Indaco - Coorte dei nati e a seguito di approvazione da
                      parte del Comitato Etico competente e di sottoscrizione di
                      un accordo, potrà trasferire dati socio-demografici,
                      epidemiologici, clinici e molecolari, o eventuali campioni
                      biologici, in forma anonima (con utilizzo di fake ID) a
                      enti e istituti di ricerca, partnership pubbliche o
                      private nel campo della ricerca scientifica, organismi
                      pubblici, fondazioni scientifiche, associazioni senza
                      scopo lucro aventi finalità di ricerca ubicati in Paesi
                      dell'Unione Europea. Per quanto concerne l’eventuale
                      trasferimento dei dati verso Paesi Terzi, il Titolare
                      rende noto che il trasferimento avverrà nel rispetto degli
                      artt. 44 e ss. del GDPR (Trasferimento sulla base di una
                      decisione di adeguatezza, trasferimento soggetto a
                      garanzie adeguate) con esclusione dell'art. 49 a oggetto
                      “Deroghe in specifiche situazioni”. Maggiori informazioni
                      sono disponibili presso il Titolare e Responsabile,
                      scrivendo all’indirizzo sopra indicato.
                    </p>
                    <p>
                      I partner dello studio si impegnano formalmente a non
                      trattare i dati per fini diversi da quelli indicati nello
                      studio condiviso, a non comunicarli o trasferirli
                      ulteriormente a terzi, a non utilizzarli per fini di lucro
                      o commerciali. Sarà creato un nuovo codice identificativo
                      specifico per ciascun nuovo studio. La connessione tra il
                      nuovo codice e il codice dello studio Indaco - Coorte dei
                      nati sarà mantenuta presso il Dipartimento di
                      Epidemiologia del Sistema Sanitario della Regione Lazio e
                      non sarà accessibile all’esterno.
                    </p>
                  </div>
                </div>

                <div className="accordion-item">
                  <button
                    id="accordion-button-7"
                    onClick={() => {
                      setAccordation({
                        accordionbutton7: !accordation.accordionbutton7,
                      });
                    }}
                    aria-expanded={accordation.accordionbutton7}
                  >
                    <h2 className="accordion-title">
                      Periodo di conservazione dei dati personali
                    </h2>
                    <span className="icon" aria-hidden="true" />
                  </button>

                  <div className="accordion-content">
                    <div className="h-24"></div>
                    <p>
                      I dati saranno conservati fino al compimento del 18° anno
                      di vita dei bambini arruolati nella coorte. Oltre questo
                      termine, i dati verranno resi anonimi in maniera
                      irreversibile e archiviati o se necessario verrà chiesto
                      un ulteriore consenso per il trattamento dei dati.
                    </p>
                    <p>
                      I dati personali raccolti durante i singoli studi, dietro
                      specifica procedura di consenso informato, sono conservati
                      fino all’eventuale richiesta di cancellazione da parte
                      dell’iscritto (art.17 paragrafo 1 del GDPR).
                    </p>
                  </div>
                </div>

                <div className="accordion-item">
                  <button
                    id="accordion-button-8"
                    onClick={() => {
                      setAccordation({
                        accordionbutton8: !accordation.accordionbutton8,
                      });
                    }}
                    aria-expanded={accordation.accordionbutton8}
                  >
                    <h2 className="accordion-title">
                      Diritti dell'interessato e modalità di esercizio dei
                      diritti
                    </h2>
                    <span className="icon" aria-hidden="true" />
                  </button>

                  <div className="accordion-content">
                    <div className="h-24"></div>
                    <p>
                      In relazione al trattamento dei tuoi dati personali, il
                      Capo III del GDPR Le riconosce specifici diritti e, in
                      particolare, il diritto, se applicabile, di:
                    </p>
                    <ul>
                      <li>
                        A. accedere ai tuoi dati personali e alle informazioni
                        relative agli stessi;
                      </li>
                      <li>
                        B. chiedere l’aggiornamento, la rettifica dei dati
                        inesatti e l’integrazione di quelli incompleti;
                      </li>
                      <li>
                        C. chiedere la cancellazione dei tuoi dati personali al
                        verificarsi di una delle condizioni indicate all’art.
                        17, paragrafo 1 del GDPR e nel rispetto delle eccezioni
                        previste al paragrafo 3 del medesimo articolo e purché
                        non sussistano obblighi di conservazione del dato
                        previsti dalla legge;
                      </li>
                      <li>
                        D. chiedere la limitazione del trattamento dei tuoi dati
                        al ricorrere di una delle ipotesi previste dall’art. 18
                        del GDPR;
                      </li>
                      <li>
                        E. chiederne la trasmissione dei tuoi dati ad altro
                        Titolare in formato leggibile con le più comuni
                        applicazioni informatiche (cd. “diritto alla
                        portabilità”), ove tecnicamente possibile;
                      </li>
                      <li>
                        F. opporsi in qualunque momento al trattamento dei tuoi
                        dati personali e al ricorrere di situazioni particolari
                        che ti riguardano;
                      </li>
                      <li>
                        G. revocare il consenso in qualsiasi momento,
                        limitatamente alle ipotesi in cui il trattamento sia
                        basato sul tuo consenso per una o più specifiche
                        finalità e riguardi dati personali comuni oppure
                        particolari categorie di dati. Il trattamento basato sul
                        consenso, ed effettuato antecedentemente alla revoca
                        dello stesso, conserva, comunque, la sua liceità;
                      </li>
                      <li>
                        H. presentare reclamo all’Autorità Garante per la
                        Protezione dei Dati Personali (i cui recapiti sono
                        rinvenibili sul sito www.garanteprivacy.it) in caso di
                        illecito trattamento o di ritardo nella risposta del
                        Titolare a una richiesta inerente i tuoi diritti;
                      </li>
                      <li>
                        I. interrompere in ogni momento e senza fornire alcuna
                        giustificazione la tua partecipazione allo studio: in
                        tal caso, i campioni biologici correlati verranno
                        distrutti. Non saranno raccolti, inoltre, ulteriori dati
                        che ti riguardano, ferma restando l'utilizzazione di
                        quelli eventualmente già raccolti per determinare, senza
                        alterarli, i risultati della ricerca.
                        <br />
                        La richiesta per l'esercizio dei diritti di cui sopra
                        deve essere presentata rivolgendosi direttamente al
                        promotore dello studio:
                        <br />
                        Daniela Porta
                        <br />
                        Dipartimento di Epidemiologia del Sistema Sanitario
                        della Regione Lazio- ASL Roma 1<br />
                        Via Cristoforo Colombo, 112
                        <br />
                        Telefono:<a href="tel:06.99722188"> 06.99722188</a>
                        <br />
                        Email:
                        <a href="mailto:someone@example.com">
                          d.porta@deplazio.it
                        </a>
                        <br />
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="accordion-item">
                  <button
                    id="accordion-button-9"
                    onClick={() => {
                      setAccordation({
                        accordionbutton9: !accordation.accordionbutton9,
                      });
                    }}
                    aria-expanded={accordation.accordionbutton9}
                  >
                    <h2 className="accordion-title">Profilazione</h2>
                    <span className="icon" aria-hidden="true" />
                  </button>

                  <div className="accordion-content">
                    <div className="h-24"></div>
                    <p>
                      Il Titolare non utilizza processi automatizzati
                      finalizzati alla profilazione.
                    </p>
                  </div>
                </div>

                <div className="accordion-item">
                  <button
                    id="accordion-button-10"
                    onClick={() => {
                      setAccordation({
                        accordionbutton10: !accordation.accordionbutton10,
                      });
                    }}
                    aria-expanded={accordation.accordionbutton10}
                  >
                    <h2 className="accordion-title">Nominativi e contatti</h2>
                    <span className="icon" aria-hidden="true" />
                  </button>

                  <div className="accordion-content">
                    <div className="h-24"></div>
                    <h3>Responsabile scientifico dello studio</h3>
                    <p>
                      Paola Michelozzi
                      <br /> Dipartimento di Epidemiologia del Servizio
                      Sanitario della Regione Lazio- ASL Roma 1<br /> Via
                      Cristoforo Colombo, 112
                      <br /> Tel:06.99722153
                      <br /> Email: p.michelozzi@deplazio.it
                    </p>
                    <div className="h-24"></div>
                    <h3>Titolare del trattamento/protezione dei dati</h3>
                    <p>
                      Regione Lazio
                      <br />
                      Via Cristoforo Colombo 212, 00147 Roma
                      <br />
                      Email: dpo@regione.lazio.it
                    </p>
                    <div className="h-24"></div>
                    <h3>Responsabile del trattamento/protezione dei dati</h3>
                    <p>
                      Dipartimento di Epidemiologia del Servizio Sanitario della
                      Regione Lazio- ASL Roma 1 Via Cristoforo Colombo 112,
                      00147 Roma
                      <br />
                      Email:<a href="mailto:dpo@aslroma1.it">dpo@aslroma1.it</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <h2>Consenso</h2>
            <div className="h-24"></div>
            <ul>
              <li>
                Ho letto e compreso per intero il documento informativo
                riguardante la raccolta, la conservazione e l’utilizzo, anche
                futuro, dei dati personali del partecipante allo studio
              </li>
              <li>
                Ho letto e compreso le finalità e le modalità del trattamento
                dei dati personali e di aver avuto sufficiente tempo per
                decidere, liberamente e volontariamente, se prestare o meno il
                consenso.
              </li>
            </ul>

            {/* <p>{nl2br(PrivacyAndTermsData?.privacy?.description)}</p> */}
            <div className="h-24"></div>
            <h2>Dichiaro di</h2>
            <div className="h-24"></div>
            <div className="option-box login_check_box bg-none box-shadow-none p-0">
              <div className="checkbox">
                <label className="lns-checkbox">
                  <input
                    type="radio"
                    className="form__radio-input"
                    name="privacy"
                    checked={lastClickedCheckbox.privacy === "privacy1"}
                    onClick={() => {
                      setVisible(false);
                      setLastClickedCheckbox({
                        ...lastClickedCheckbox,
                        privacy: "privacy1",
                      });
                    }}
                  />
                  <span className="form__radio-label">
                    Autorizzare il trattamento dei miei dati personali e di
                    quelli di mio figlio per le finalità dello studio in oggetto
                    ai sensi del Regolamento EU 679/2016 regolamento Europeo e
                    della normativa italiana in materia della Protezione dei
                    dati personali (d.lgs. 101/2018), avendo ottenuto il
                    consenso del padre del bambino/a.
                  </span>
                  <div className="form__radio-button"></div>
                </label>
              </div>
              <div className="checkbox">
                <label className="lns-checkbox">
                  <input
                    type="radio"
                    className="form__radio-input"
                    name="privacy"
                    checked={lastClickedCheckbox.privacy === "privacy2"}
                    onClick={() => {
                      setVisible(false);
                      setLastClickedCheckbox({
                        ...lastClickedCheckbox,
                        privacy: "privacy2",
                      });
                    }}
                  />
                  <span className="form__radio-label">
                    Autorizzare il trattamento dei miei dati personali e di
                    quelli di mio figlio per le finalità dello studio in oggetto
                    ai sensi del Regolamento EU 679/2016 regolamento Europeo e
                    della normativa italiana in materia della Protezione dei
                    dati personali (d.lgs. 101/2018), in qualità di unico
                    genitore
                  </span>
                  <div className="form__radio-button"></div>
                </label>
              </div>
            </div>
          </div>
        </CModalBody>
      </CModal>

      <CModal
        visible={visible.terms}
        onClose={() => setVisible(false)}
        aria-labelledby="ScrollingLongContentExampleLabel2"
        scrollable
        className="custom-model c_modal"
      >
        <CModalBody>
          <div className="login_detail_nner">
            <CModalTitle component="h1" id="ScrollingLongContentExampleLabel2">
              Scheda informativa dello Studio
            </CModalTitle>
            <div className="h-24"></div>
            <h2>Versione 1 del 5 marzo 2024</h2>
            <div className="h-24"></div>

            <p>
              Il Dipartimento di Epidemiologia del Servizio Sanitario Regionale
              del Lazio (DEP) sta conducendo per conto di Regione Lazio lo
              studio “Coorte di nati nel Sin Valle del Sacco per la valutazione
              prospettica dello stato di salute dei bambini in relazione ai
              fattori di rischio ambientali (Indaco- Coorte dei Nati)”
              nell’ambito dell’Accordo di Programma per la realizzazione degli
              interventi di messa in sicurezza e bonifica del Sin Valle del
              Sacco, stipulato dal Ministero dell’Ambiente e dalla Regione Lazio
              con la determinazione n. G09914 del 27/07/2022.
            </p>
            <p className="mb-0">
              Prima di decidere se accettare o rifiutare di partecipare, ti
              preghiamo di leggere attentamente quanto segue. Se avessi bisogno
              di ulteriori precisazioni, puoi contattare la responsabile
              scientifica dello studio: Dott.ssa Daniela Porta
              <br />
              Dipartimento Epidemiologia del S.S.R. - ASL Roma1
              <br />
              Via Cristoforo Colombo 112, 00147 Roma <br />
              Email:
              <a href="mailto:someone@example.com"> d.porta@deplazio.it</a>
            </p>
            {/* <p>{nl2br(PrivacyAndTermsData?.terms?.description)}</p> */}

            <div className="main-accordation">
              <div className="accordion">
                <div className="accordion-item">
                  <button
                    id="accordion-button-11"
                    onClick={() => {
                      setAccordation({
                        accordionbutton11: !accordation.accordionbutton11,
                      });
                    }}
                    aria-expanded={accordation.accordionbutton11}
                  >
                    <h2 className="accordion-title">Scopo dello studio</h2>
                    <span className="icon" aria-hidden="true" />
                  </button>
                  <div className="accordion-content">
                    <div className="h-24"></div>
                    <p>
                      Questo studio consiste nell’arruolamento delle donne in
                      gravidanza residenti nell’area del Sin Valle del Sacco e
                      nella raccolta di informazioni epidemiologiche, sanitarie
                      e di campioni biologici e di una coorte di nati al fine di
                      valutare prospetticamente lo stato di salute dei bambini
                      in relazione all’esposizione a fattori di rischio
                      ambientali presenti nell’area.
                    </p>
                    <p>
                      I bambini saranno seguiti dalla nascita alla fine del
                      primo anno di vita e potranno essere ricontattati anche in
                      età successive.
                    </p>
                  </div>
                </div>

                <div className="accordion-item">
                  <button
                    id="accordion-button-12"
                    onClick={() => {
                      setAccordation({
                        accordionbutton12: !accordation.accordionbutton12,
                      });
                    }}
                    aria-expanded={accordation.accordionbutton12}
                  >
                    <h2 className="accordion-title">
                      Cosa comporta la partecipazione allo studio
                    </h2>
                    <span className="icon" aria-hidden="true" />
                  </button>

                  <div className="accordion-content">
                    <div className="h-24"></div>
                    <p>
                      La partecipazione è su base volontaria e in ogni momento
                      si può decidere di abbandonare lo studio. Partecipare allo
                      studio implica:
                    </p>
                    <ul>
                      <li>
                        la registrazione su una piattaforma online dello studio,
                        in cui saranno richiesti dati anagrafici e recapiti;{" "}
                      </li>
                      <li>
                        la ricezione di messaggi per posta elettronica e via
                        whatsapp in relazione alle tappe dello studio;
                      </li>
                      <li>
                        la compilazione on-line di alcuni questionari nel corso
                        dei primi due anni di partecipazione allo studio: uno
                        relativo al periodo pre-gravidanza che verrà compilato
                        al momento dell’adesione allo studio; uno relativo al
                        periodo della gravidanza che verrà compilato
                        possibilmente nel corso del 9 mese di gravidanza; uno
                        relativo alla nascita del bambino, che verrà compilato
                        possibilmente entro il 3 mese di vita del bambino; uno
                        relativo al primo anno di vita del bambino che verrà
                        compilato entro il 2° anno di vita.
                      </li>
                    </ul>
                    <p>
                      I questionari relativi alla mamma contengono informazioni
                      quali lo stile di vita, la dieta, le caratteristiche
                      anagrafiche, le esposizioni ambientali ed occupazionali,
                      la passata storia riproduttiva, la salute prima e durante
                      la gravidanza, l’assunzione di farmaci, il parto e
                      l’allattamento. Nel primo questionario saranno inoltre
                      richieste alcune informazioni sul padre del bambino (età,
                      cittadinanza, titolo di studio, occupazione, salute, peso
                      e altezza). I questionari relativi al bambino conterranno
                      informazioni sulla dieta, le vaccinazioni, la salute, lo
                      stile di vita, l’uso di farmaci, l’ambiente domestico, le
                      misure antropometriche, la crescita e lo sviluppo nel
                      primo anno di vita;
                    </p>
                    <ul>
                      <li>
                        l’interrogazione delle banche dati del Servizio
                        Sanitario Nazionale per ottenere informazioni circa
                        eventi sanitari importanti, quali il ricorso al pronto
                        soccorso, l’ospedalizzazione, o altri eventi avversi
                        della mamma e del bambino;
                      </li>
                      <li>
                        la possibilità, di essere periodicamente contattati nel
                        corso degli anni futuri, dal personale facente parte
                        dello studio, per rispondere di nuovo ad alcune domande
                        sullo stato di salute del bambino, sul suo sviluppo e
                        sull’esposizione a possibili fattori di rischio;
                      </li>
                      <li>
                        {" "}
                        la richiesta per raccogliere campioni biologici (saliva,
                        capelli e unghie della mamma e del bambino), presso lo
                        studio del proprio Pediatra di Libera Scelta, o in
                        alternativa auto-prelevati a casa tramite un kit e
                        inviati per posta in buste pre-affrancate al
                        Dipartimento Epidemiologia del S.S.R. - ASL Roma1
                        Regione Lazio. La raccolta dei campioni biologici
                        avverrà previa firma di un consenso informato specifico,
                        richiesto al momento della raccolta stessa.
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="accordion-item">
                  <button
                    id="accordion-button-13"
                    onClick={() => {
                      setAccordation({
                        accordionbutton13: !accordation.accordionbutton13,
                      });
                    }}
                    aria-expanded={accordation.accordionbutton13}
                  >
                    <h2 className="accordion-title">
                      Benefici e rischi derivanti dalla partecipazione
                    </h2>
                    <span className="icon" aria-hidden="true" />
                  </button>

                  <div className="accordion-content">
                    <div className="h-24"></div>
                    <p>
                      Partecipare a questo studio non comporta rischi, se non il
                      dedicare venti minuti circa alla compilazione di ciascun
                      questionario. Non vi sono benefici diretti per la salute
                      delle partecipanti e dei loro figli, però avrete la
                      possibilità di ricevere informazioni sulle buone pratiche
                      per favorire il benessere e la salute dei vostri figli,
                      anche tramite la rete dei pediatri di base del territorio.
                    </p>
                    <p>
                      Partecipando al progetto avrete inoltre la possibilità di
                      contribuire con un po’ del vostro tempo al progresso delle
                      conoscenze mediche. Tale progresso si potrà in futuro
                      tradurre in un miglioramento della salute delle mamme in
                      gravidanza e dei loro bambini.
                    </p>
                  </div>
                </div>

                <div className="accordion-item">
                  <button
                    id="accordion-button-14"
                    onClick={() => {
                      setAccordation({
                        accordionbutton14: !accordation.accordionbutton14,
                      });
                    }}
                    aria-expanded={accordation.accordionbutton14}
                  >
                    <h2 className="accordion-title">
                      Approvazione da parte del comitato etico
                    </h2>
                    <span className="icon" aria-hidden="true" />
                  </button>

                  <div className="accordion-content">
                    <div className="h-24"></div>
                    <p>
                      Il protocollo di questo studio è stato redatto in
                      conformità alle Norme di Buona Pratica Clinica della
                      Unione Europea e alla revisione corrente della
                      Dichiarazione di Helsinki ed è stato approvato dal
                      Comitato Etico dell’Ospedale Pediatrico Bambino Gesù
                      (prot. 174 del 5/4/2024), che opera al fine di assicurare
                      la salvaguardia dei diritti, dell’integrità e del
                      benessere dei soggetti coinvolti nello studio.
                    </p>
                  </div>
                </div>

                <div className="accordion-item">
                  <button
                    id="accordion-button-15"
                    onClick={() => {
                      setAccordation({
                        accordionbutton15: !accordation.accordionbutton15,
                      });
                    }}
                    aria-expanded={accordation.accordionbutton15}
                  >
                    <h2 className="accordion-title">
                      Consenso informato per la partecipazione allo studio
                    </h2>
                    <span className="icon" aria-hidden="true" />
                  </button>

                  <div className="accordion-content">
                    <div className="h-24"></div>
                    <ul>
                      <li>
                        Ho letto attentamente e compreso quanto esposto nella
                        Scheda Informativa dello studio (versione 1 del 5 marzo
                        2024) e ho compreso le modalità di conduzione dello
                        studio e le sue finalità;
                      </li>
                      <li>
                        Ho compreso che la partecipazione è volontaria; che
                        potrò ritirarmi dallo studio in qualsiasi momento e
                        senza fornire giustificazione, nella certezza che né il
                        rifiuto alla mia partecipazione né il ritiro della mia
                        adesione comporteranno discriminazioni; che le
                        informazioni raccolte saranno strettamente riservate e i
                        dati saranno utilizzati per le finalità indicate dello
                        studio; che in caso revocassi il consenso ho il diritto
                        di richiedere che tutti i dati e/o i campioni biologici
                        precedentemente raccolti siano eliminati, salvo che i
                        dati e i campioni biologici, in origine o a seguito del
                        trattamento, non consentano più di identificarmi (non
                        saranno raccolti ulteriori nuovi dati che ti riguardano
                        fermo restando l'utilizzazione di quelli eventualmente
                        già raccolti per determinare, senza alterarli, i
                        risultati dello studio
                      </li>
                      <li>
                        Ho compreso che per ogni problema o per eventuali
                        ulteriori informazioni potrò rivolgermi alla
                        responsabile scientifica dello studio, la Dott.ssa
                        Daniela Porta, Dipartimento Epidemiologia del S.S.R. -
                        ASL Roma1 Regione Lazio, Via Cristoforo Colombo 112,
                        00147 Roma, email: d.porta@deplazio.it;
                      </li>
                      <li>
                        Chiedo di ricevere copia della Scheda informativa e
                        copia di questo modulo di Consenso Informato
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="h-24"></div>
            <h2>Dichiaro di</h2>
            <div className="h-24"></div>
            <div className="option-box login_check_box bg-none box-shadow-none p-0">
              <div className="checkbox">
                <label className="lns-checkbox">
                  <input
                    type="radio"
                    name="terms"
                    onClick={() => {
                      setVisible(false);
                      setLastClickedCheckbox({
                        ...lastClickedCheckbox,
                        terms: "terms1",
                      });
                    }}
                    checked={lastClickedCheckbox?.terms === "terms1"}
                  />
                  <span className="form__radio-label">
                    Voler partecipare volontariamente in prima persona, insieme
                    a mio figlio/a, allo studio designato, avendo ottenuto il
                    consenso del padre del bambino/a.
                  </span>
                  <div className="form__radio-button"></div>
                </label>
              </div>
              <div className="checkbox">
                <label className="lns-checkbox">
                  <input
                    type="checkbox"
                    name="terms"
                    onClick={() => {
                      setVisible(false);
                      setLastClickedCheckbox({
                        ...lastClickedCheckbox,
                        terms: "terms2",
                      });
                    }}
                    checked={lastClickedCheckbox?.terms === "terms2"}
                  />
                  <span className="form__radio-label">
                    Voler partecipare volontariamente in prima persona, insieme
                    a mio figlio/a, allo studio designato, in qualità di unico
                    genitore.
                  </span>
                  <div className="form__radio-button"></div>
                </label>
              </div>
            </div>
          </div>
        </CModalBody>
      </CModal>
    </>
  );
};

export default withTranslation()(Privacy);
