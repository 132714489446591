import {
  GET_NOTIFICATION,
  GET_NOTIFICATION_SUCCESS,
  GET_NOTIFICATION_FAILED,
  UPDATE_NOTIFICATION,
  UPDATE_NOTIFICATION_SUCCESS,
  UPDATE_NOTIFICATION_FAILED,
} from "../actionType";

const initialState = {
  notification: {
    data: null,
    loading: false,
  },
  error: null,
};

function NotificationReducer(state = initialState, action) {
  switch (action.type) {
    case GET_NOTIFICATION:
      return {
        ...state,
        notification: {
          loading: true,
        },
      };
    case GET_NOTIFICATION_SUCCESS:
      return {
        ...state,
        notification: {
          data: action.payload.data,
          loading: false,
        },
      };
    case GET_NOTIFICATION_FAILED:
      return {
        ...state,
        notification: {
          data: null,
          loading: false,
        },
        error: "Something went wrong",
      };

    case UPDATE_NOTIFICATION:
      return {
        ...state,
        notification: {
          loading: true,
        },
      };
    case UPDATE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        notification: {
          data: action.payload.data,
          loading: false,
        },
      };
    case UPDATE_NOTIFICATION_FAILED:
      return {
        ...state,
        notification: {
          data: null,
          loading: false,
        },
        error: "Something went wrong",
      };
    default:
      return state;
  }
}

export default NotificationReducer;
