import React from 'react'
import { CButton, CContainer } from '@coreui/react'
import { useNavigate } from 'react-router-dom'

const Page404 = () => {
  const navigate = useNavigate()
  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <div className="page404">
          <div>
            <h1 className="float-start display-3 me-4">404</h1>
            <h4>Oops! You{"'"}re lost.</h4>
            <p className="text-medium-emphasis float-start">
              The page you are looking for was not found.
            </p>
          </div>
          <div>
            <CButton onClick={() => navigate('/')}>Back To Home</CButton>
          </div>
        </div>
      </CContainer>
    </div>
  )
}

export default Page404;
