import React, { useEffect, useState } from "react";
import FooterImg from "../../assets/images/footer.png";
import FooterMobileImg from "../../assets/images/mobile-bg.png";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { withTranslation } from "react-i18next";

const Footer = ({ t }) => {
  const pathData = useSelector((state) => state?.NavigationReducer?.path);
  const [state, setState] = useState({});

  useEffect(() => {
    setState(pathData);
  }, [pathData]);

  const navigate = useNavigate();

  return (
    <div className="footer-section">
      <div className="btn-section mobile text-center mt-50 btn-desktop">
        <button
          className="solid-btn"
          style={{
            display: `${Object.keys(state).length === 0 ? "none" : ""}`,
          }}
          onClick={() => navigate(`${state.path}`)}
        >
          {state?.title}
        </button>
      </div>
      <img src={FooterImg} alt="footer" className="desktop" />
      <img src={FooterMobileImg} alt="footer" className="mobile" />
    </div>
  );
};

export default withTranslation()(Footer);
