import React from "react";
import { withTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const Title = ({ t }) => {
  const navigate = useNavigate();

  const handleProcedi = () => {
    navigate("/auth/register");
  };
  return (
    <div className="option-detail login_section py-100">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-12">
            <div className="right-side text-center m_title_section cosa-section">
              <div className="meditation-yaga-section documenti-section">
                <h2>Cosa ti serve?</h2>
                <p>
                  {t(
                    "Per una rapida registrazione, assicurati di avere a portata di mano:"
                  )}
                </p>
                <ul className="documenti-ul">
                  <li>{t("Codice fiscale")}</li>
                </ul>
              </div>
              <div className="btn-section text-center">
                <button href="#" className="solid-btn" onClick={handleProcedi}>
                  {t("Procedi")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(Title);
