import React, { useEffect } from "react";
import FAQLOGO from "../../../assets/images/mailBaloonMessage.svg";
import { useNavigate } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { getTabName } from "../../../redux/navigatePath/navigateAction";

const FAQ = ({ t }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    const items = document.querySelectorAll(".accordion button");
    console.log("🚀 ~ useEffect ~ items:", items);
    function toggleAccordion() {
      const itemToggle = this.getAttribute("aria-expanded");
      if (items && items.length > 0) {
        for (let i = 0; i < items.length; i++) {
          items[i].setAttribute("aria-expanded", "false");
        }
      }
      if (itemToggle === "false") {
        this.setAttribute("aria-expanded", "true");
      }
    }
    items.forEach((item) => item.addEventListener("click", toggleAccordion));
  }, []);

  const handleNavigateToContact = () => {
    navigate("/info/contact");
    dispatch(getTabName({ tabName: "Contatti", tabAction: "/info/contact" }));
  };
  return (
    <div className="option-detail faq-section py-30">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-5">
            <div className="left-side box-shadow-none bg-none justify-content-center">
              <div className="faq-img">
                <img src={FAQLOGO} alt="faq" className="img-fluid" />
              </div>
            </div>
          </div>
          <div className="col-lg-7">
            <div className="right-side main-faq-detail">
              <div className="meditation-yaga-section faq-content text-center">
                <h2>{t("Hai domande?")}</h2>
                <h3>Trova risposte rapide alle domande più frequenti.</h3>
              </div>
              <div className="main-accordation">
                <div className="accordion">
                  <div className="accordion-item">
                    <button id="accordion-button-1" aria-expanded="false">
                      <span className="accordion-title">
                        Perché lo studio si svolge nella Valle del fiume Sacco?
                      </span>
                      <span className="icon" aria-hidden="true" />
                    </button>
                    <div className="accordion-content">
                      <p>
                        Il territorio della Valle del fiume Sacco è
                        caratterizzato dalla presenza di diversi inquinanti che
                        derivano dai numerosi insediamenti industriali attivi e
                        dall’elevato inquinamento atmosferico dovuto al traffico
                        autoveicolare e all’uso massivo di biomasse per il
                        riscaldamento. Inoltre, la conformazione geografica
                        della Valle del Sacco ostacola la dispersione degli
                        inquinanti. Raccogliendo dati anagrafici,
                        socio-demografici,stili di vita, salute ed esposizioni
                        ad inquinanti ambientali lo studio aiuterà a comprendere
                        meglio gli effetti degli inquinanti sulla salute
                        migliorando la vita dei bambini e delle loro famiglie
                        che vivono nel SIN Valle del Sacco.
                      </p>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <button id="accordion-button-1" aria-expanded="false">
                      <span className="accordion-title">
                        Cos'è uno studio di Coorte?
                      </span>
                      <span className="icon" aria-hidden="true" />
                    </button>
                    <div className="accordion-content">
                      <p>
                        In epidemiologia ambientale, uno studio di coorte aiuta
                        a capire come l'ambiente influisce sulla salute umana.
                        Una "coorte" è un gruppo di persone con caratteristiche
                        comuni (es. nascita in un certo periodo o residenza in
                        una specifica area) che vengono “seguite” nel tempo.
                      </p>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <button id="accordion-button-1" aria-expanded="false">
                      <span className="accordion-title">
                        Cosa è lo studio Coorte dei Nati?
                      </span>
                      <span className="icon" aria-hidden="true" />
                    </button>
                    <div className="accordion-content">
                      <p>
                        Uno studio di coorte di nuovi nati analizza gli effetti
                        delle esposizioni ambientali dalla gravidanza fino alla
                        crescita del bambino. Per capire i fattori che
                        influenzano la salute neonatale e infantile (es. basso
                        peso, asma, allergie, crescita, obesità), è necessario
                        infatti seguire le coppie mamma-bambino i bambini nel
                        tempo. Questi studi durano molti anni e raccolgono in
                        diversi momenti tramite dei questionari dati su
                        anagrafica, stato socio-economico, stile di vita,
                        alimentazione, salute ed esposizione a inquinanti.
                        Inizialmente, si studiano problemi comuni come infezioni
                        respiratorie e, successivamente, aspetti come lo
                        sviluppo cognitivo.
                      </p>
                      <p>
                        Gli studi di coorte di nuovi nati includono spesso la
                        raccolta di materiali biologici (es. unghie, capelli)
                        per misurare l'esposizione a contaminanti tramite
                        biomarcatori.
                      </p>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <button id="accordion-button-1" aria-expanded="false">
                      <span className="accordion-title">
                        Quali sono i benefici di partecipare al progetto?
                      </span>
                      <span className="icon" aria-hidden="true" />
                    </button>
                    <div className="accordion-content">
                      <p>
                        Lo studio Indaco - Coorte dei Nati seguirà i bambini
                        durante la crescita, raccogliendo dati tramite
                        questionari online e sistemi informativi sanitari, per
                        valutare l'associazione tra esposizioni ambientali e
                        salute. Partecipare significa ridurre l'incertezza e
                        orientare le politiche sanitarie con interventi efficaci
                        per un futuro più sereno nel SIN Valle del Sacco. Le
                        future mamme aderenti al progetto potranno scaricare un
                        diario del primo anno di vita con consigli utili su
                        alimentazione, allattamento, vaccinazioni e sonno per il
                        benessere dei loro bambini.
                      </p>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <button id="accordion-button-1" aria-expanded="false">
                      <span className="accordion-title">
                        Quali sono gli enti e le istituzioni responsabili di
                        questo progetto?
                      </span>
                      <span className="icon" aria-hidden="true" />
                    </button>
                    <div className="accordion-content">
                      <p>
                        Lo studio “Coorte di nati nel Sin Valle del Sacco per la
                        valutazione prospettica dello stato di salute dei
                        bambini in relazione ai fattori di rischio ambientali
                        (Indaco- Coorte dei Nati)” è condotto dal Dipartimento
                        di Epidemiologia del Servizio Sanitario Regionale del
                        Lazio (DEP) per conto di Regione Lazio nell’ambito
                        dell’Accordo di Programma per la realizzazione degli
                        interventi di messa in sicurezza e bonifica del Sin
                        Valle del Sacco, stipulato dal Ministero dell’Ambiente e
                        dalla Regione Lazio con la determinazione n. G09914 del
                        27/07/2022.
                      </p>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <button id="accordion-button-1" aria-expanded="false">
                      <span className="accordion-title">
                        Quanti studi di Coorte ci sono in Europa?
                      </span>
                      <span className="icon" aria-hidden="true" />
                    </button>
                    <div className="accordion-content">
                      <p>
                        Indaco- Coorte dei Nati si inserisce in un contesto
                        europeo ricco di studi di coorte di nuovi nati. A
                        cominciare dagli anni '90, in Inghilterra e in altri
                        paesi europei, sono stati avviati molti studi di questo
                        tipo. Alcuni coinvolgono decine di migliaia di nuovi
                        nati, altri sono di dimensione più ridotta, ma hanno
                        informazioni molto ricche sull'ambiente, lo stile di
                        vita, la crescita e lo stato di salute dei bambini. Il
                        progetto Lifecycle, finanziato dal programma di ricerca
                        e innovazione Horizon 2020 dell'Unione Europea, ha
                        contribuito alla costituzione della Eu Child Network,
                        un'infrastruttura di ricerca a livello europeo che
                        permette di “unire le forze” mettendo in comune i dati
                        di 40 coorti per un totale di circa 250 mila coppie
                        mamme/bambini.
                      </p>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <button id="accordion-button-1" aria-expanded="false">
                      <span className="accordion-title">
                        Perché chiedete i miei dati personali?
                      </span>
                      <span className="icon" aria-hidden="true" />
                    </button>
                    <div className="accordion-content">
                      <p>
                        Richiediamo il codice fiscale e il numero del documento
                        d'identità al momento della registrazione per garantire
                        l'unicità e l'autenticità di ogni contributo, a garanzia
                        della qualità dei dati raccolti.
                      </p>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <button id="accordion-button-1" aria-expanded="false">
                      <span className="accordion-title">
                        Ho dimenticato la password, cosa faccio?
                      </span>
                      <span className="icon" aria-hidden="true" />
                    </button>
                    <div className="accordion-content">
                      <p>
                        Puoi reimpostare la tua password dalla schermata di
                        accesso. Per ogni dubbio o richiesta di informazioni,
                        puoi sempre contattarci ai recapiti telefonici che trovi
                        in fondo a questa pagina.
                      </p>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <button id="accordion-button-1" aria-expanded="false">
                      <span className="accordion-title">
                        Perché sono importanti i primi 1000 giorni di vita del
                        bambino?
                      </span>
                      <span className="icon" aria-hidden="true" />
                    </button>
                    <div className="accordion-content">
                      <p>
                        I primi 1000 giorni, dalla fase fetale fino ai due anni,
                        sono cruciali per lo sviluppo e altamente suscettibili
                        all'influenza ambientale. Durante questo periodo, organi
                        come i polmoni e il sistema nervoso centrale sono ancora
                        in maturazione. Inoltre, i bambini sono più esposti agli
                        inquinanti perché passano molto tempo all'aperto.
                      </p>
                      <p>
                        Studi recenti hanno valutato gli effetti
                        dell'esposizione agli inquinanti ambientali, come
                        inquinamento atmosferico, agenti fisici e chimici, sui
                        bambini dal concepimento fino all'età adulta. Infatti
                        questi inquinanti, combinati con fattori genetici,
                        possono avere effetti anche a lungo termine.
                      </p>
                      <p>
                        È fondamentale studiare la salute e gli stili di vita
                        delle nuove generazioni esposte a tali pressioni
                        ambientali per sviluppare strumenti efficaci che
                        riducano l'esposizione delle donne in gravidanza e dei
                        bambini agli inquinanti, migliorando così la salute del
                        bambino, dei genitori e delle comunità in contesti
                        ambientali critici.
                      </p>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <button id="accordion-button-1" aria-expanded="false">
                      <span className="accordion-title">
                        Come e perché raccogliete campioni biologici?
                      </span>
                      <span className="icon" aria-hidden="true" />
                    </button>
                    <div className="accordion-content">
                      <p>
                        Intorno all'anno di vita del bambino, raccoglieremo
                        campioni biologici di mamma e bambino (saliva, capelli,
                        unghie) presso il Pediatra di Libera Scelta o tramite un
                        kit per l'auto-prelievo. La raccolta avverrà con un
                        consenso informato che verrà richiesto al momento della
                        raccolta. L'obiettivo è studiare la suscettibilità
                        individuale alle malattie comuni durante la gravidanza
                        (es. diabete gestazionale, ipertensione) e i problemi
                        frequenti alla nascita, in infanzia, in adolescenza (es.
                        basso peso, asma, obesità infantile) e nell'adulto. I
                        dati genetici saranno trattati esclusivamente per
                        finalità di ricerca scientifica in campo medico,
                        biomedico ed epidemiologico, .
                      </p>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <button id="accordion-button-1" aria-expanded="false">
                      <span className="accordion-title">
                        Avete un protocollo etico che garantisce lo studio?
                      </span>
                      <span className="icon" aria-hidden="true" />
                    </button>
                    <div className="accordion-content">
                      <p>
                        Il protocollo di questo studio è stato redatto in
                        conformità alle Norme di Buona Pratica Clinica della
                        Unione Europea e alla revisione corrente della
                        Dichiarazione di Helsinki ed è stato approvato dal
                        Comitato Etico dell’Ospedale Pediatrico Bambino Gesù
                        (prot. 174 del 5/4/2024), che opera al fine di
                        assicurare la salvaguardia dei diritti, dell’integrità e
                        del benessere dei soggetti coinvolti nello studio.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="other-question">
                <h1>{t("Hai altre domande?")}</h1>

                <div className="entra_btn mt-0">
                  <button
                    className="solid-btn bg-color"
                    onClick={handleNavigateToContact}
                  >
                    {t("Contattaci")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default withTranslation()(FAQ);
