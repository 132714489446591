import {
  CLEAR_NAVIGATE_PATH,
  CLEAR_TAB_NAME,
  GET_NAVIGATE_PATH,
  GET_TAB_NAME,
} from "../actionType";

const initialState = {
  path: {},
  loading: false,
  error: null,
  tabName: localStorage.getItem("tab") || "",
  tabAction: localStorage.getItem("tabPath") || "",
};

function NavigationReducer(state = initialState, action) {
  switch (action.type) {
    case GET_NAVIGATE_PATH:
      return {
        ...state,
        path: action.payload,
        loading: false,
      };
    case CLEAR_NAVIGATE_PATH:
      return {
        ...state,
        path: {},
        loading: false,
      };
    case GET_TAB_NAME:
      return {
        ...state,
        tabName: action.payload.tabName,
        tabAction: action.payload.tabAction,
        loading: false,
      };
    case CLEAR_TAB_NAME:
      return {
        ...state,
        tabName: "",
        tabAction: "",
        loading: false,
      };
    default:
      return state;
  }
}

export default NavigationReducer;
