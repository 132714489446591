import {
  GET_NOTIFICATION,
  GET_NOTIFICATION_FAILED,
  GET_NOTIFICATION_SUCCESS,
  UPDATE_NOTIFICATION,
  UPDATE_NOTIFICATION_FAILED,
  UPDATE_NOTIFICATION_SUCCESS,
} from "../actionType";

export const getNotification = (data) => {
  return {
    type: GET_NOTIFICATION,
    payload: data,
  };
};

export const getNotificationSuccess = (data) => {
  return {
    type: GET_NOTIFICATION_SUCCESS,
    payload: data,
  };
};

export const getNotificationFailed = (data) => {
  return {
    type: GET_NOTIFICATION_FAILED,
    payload: data,
  };
};

export const updateNotification = (data) => {
  return {
    type: UPDATE_NOTIFICATION,
    payload: data,
  };
};

export const updateNotificationSuccess = (data) => {
  return {
    type: UPDATE_NOTIFICATION_SUCCESS,
    payload: data,
  };
};

export const updateNotificationFailed = (data) => {
  return {
    type: UPDATE_NOTIFICATION_FAILED,
    payload: data,
  };
};
