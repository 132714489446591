import {
  CHANGE_USER_LANG,
  SIGN_IN,
  SIGN_UP,
  SIGN_IN_FAILED,
  SIGN_IN_SUCCESS,
  SIGN_UP_SUCCESS,
  SIGN_UP_FAILED,
  VERIFY_ACC,
  VERIFY_ACC_SUCCESS,
  VERIFY_ACC_FAILED,
  OTP_VERIFY,
  OTP_VERIFY_SUCCESS,
  OTP_VERIFY_FAILED,
  REVERIFY_ACC,
  REVERIFY_ACC_SUCCESS,
  REVERIFY_ACC_FAILED,
  MOTHERID_UPLOAD,
  MOTHERID_UPLOAD_SUCCESS,
  MOTHERID_UPLOAD_FAILED,
  DADID_UPLOAD,
  DADID_UPLOAD_SUCCESS,
  DADID_UPLOAD_FAILED,
  VERIFY_RESET_EMAIL,
  VERIFY_RESET_EMAIL_SUCCESS,
  VERIFY_RESET_EMAIL_FAILED,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILED,
  DIRECT_ACCOUNT_VERIFICATION_IN,
  DIRECT_ACCOUNT_VERIFICATION_IN_SUCCESS,
  DIRECT_ACCOUNT_VERIFICATION_IN_FAILED,
  SEND_PDF_EMAIL_SUCCESS,
  SEND_PDF_EMAIL_FAILED,
  SEND_PDF_EMAIL,
} from "../actionType";

export const login = (data) => {
  return {
    type: SIGN_IN,
    payload: data,
  };
};

export const loginSuccess = (data) => {
  return {
    type: SIGN_IN_SUCCESS,
    payload: data,
  };
};

export const loginFailed = (data) => {
  return {
    type: SIGN_IN_FAILED,
    payload: data,
  };
};

export const register = (data) => {
  return {
    type: SIGN_UP,
    payload: data,
  };
};

export const registerSuccess = (data) => {
  return {
    type: SIGN_UP_SUCCESS,
    payload: data,
  };
};

export const registerFailed = (data) => {
  return {
    type: SIGN_UP_FAILED,
    payload: data,
  };
};

export const directVerification = (data) => {
  return {
    type: DIRECT_ACCOUNT_VERIFICATION_IN,
    payload: data,
  };
};

export const directVerificationSuccess = (data) => {
  return {
    type: DIRECT_ACCOUNT_VERIFICATION_IN_SUCCESS,
    payload: data,
  };
};

export const directVerificationFailed = (data) => {
  return {
    type: DIRECT_ACCOUNT_VERIFICATION_IN_FAILED,
    payload: data,
  };
};

export const verifyAcc = (data) => {
  return {
    type: VERIFY_ACC,
    payload: data,
  };
};

export const verifyAccSuccess = (data) => {
  return {
    type: VERIFY_ACC_SUCCESS,
    payload: data,
  };
};

export const verifyAccFailed = (data) => {
  return {
    type: VERIFY_ACC_FAILED,
    payload: data,
  };
};

export const reVerifyAcc = (data) => {
  return {
    type: REVERIFY_ACC,
    payload: data,
  };
};

export const reVerifyAccSuccess = (data) => {
  return {
    type: REVERIFY_ACC_SUCCESS,
    payload: data,
  };
};

export const reVerifyAccFailed = (data) => {
  return {
    type: REVERIFY_ACC_FAILED,
    payload: data,
  };
};

export const otpVerify = (data) => {
  return {
    type: OTP_VERIFY,
    payload: data,
  };
};

export const otpVerifySuccess = (data) => {
  return {
    type: OTP_VERIFY_SUCCESS,
    payload: data,
  };
};

export const otpVerifyFailed = (data) => {
  return {
    type: OTP_VERIFY_FAILED,
    payload: data,
  };
};

export const uploadMotherId = (data) => {
  return {
    type: MOTHERID_UPLOAD,
    payload: data,
  };
};

export const uploadMotherIdSuccess = (data) => {
  return {
    type: MOTHERID_UPLOAD_SUCCESS,
    payload: data,
  };
};

export const uploadMotherIdFailed = (data) => {
  return {
    type: MOTHERID_UPLOAD_FAILED,
    payload: data,
  };
};

export const uploadDadId = (data) => {
  return {
    type: DADID_UPLOAD,
    payload: data,
  };
};

export const uploadDadIdSuccess = (data) => {
  return {
    type: DADID_UPLOAD_SUCCESS,
    payload: data,
  };
};

export const uploadDadIdFailed = (data) => {
  return {
    type: DADID_UPLOAD_FAILED,
    payload: data,
  };
};

export const changeLang = (data) => {
  return {
    type: CHANGE_USER_LANG,
    payload: data,
  };
};
export const verifyResetEmail = (data) => {
  return {
    type: VERIFY_RESET_EMAIL,
    payload: data,
  };
};

export const verifyResetEmailSuccess = (data) => {
  return {
    type: VERIFY_RESET_EMAIL_SUCCESS,
    payload: data,
  };
};

export const verifyResetEmailFailed = (data) => {
  return {
    type: VERIFY_RESET_EMAIL_FAILED,
    payload: data,
  };
};
export const ResetPassword = (data) => {
  return {
    type: RESET_PASSWORD,
    payload: data,
  };
};

export const ResetPasswordlSuccess = (data) => {
  return {
    type: RESET_PASSWORD_SUCCESS,
    payload: data,
  };
};

export const ResetPasswordFailed = (data) => {
  return {
    type: RESET_PASSWORD_FAILED,
    payload: data,
  };
};
export const sendPDFEmail = (data) => {
  return {
    type: SEND_PDF_EMAIL,
    payload: data,
  };
};

export const sendPDFEmailSuccess = (data) => {
  return {
    type: SEND_PDF_EMAIL_SUCCESS,
    payload: data,
  };
};

export const sendPDFEmailFailed = (data) => {
  return {
    type: SEND_PDF_EMAIL_FAILED,
    payload: data,
  };
};
