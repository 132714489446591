import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserById } from "../../../redux/user/userAction";
import { Link } from "react-router-dom";
import { getTabName } from "../../../redux/navigatePath/navigateAction";
import { withTranslation } from "react-i18next";
import moment from "moment";

const UserDetails = ({ t }) => {
  const [userDetails, setUserDetails] = useState({});
  const userData = useSelector((state) => state?.UserReducer?.userDetails);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getTabName({ tabName: "I tuoi dati", tabAction: "/user-details" })
    );
  }, [dispatch]);

  const userId = localStorage.getItem("userId");

  useEffect(() => {
    dispatch(getUserById(userId));
  }, [dispatch, userId]);

  useEffect(() => {
    setUserDetails(userData);
  }, [userData]);

  const handleTabName = (tabValue) => {
    localStorage.setItem("tab", tabValue);
    localStorage.setItem("tabPath", "/user-details");
    dispatch(getTabName({ tabName: tabValue, tabAction: "/user-details" }));
  };
  return (
    <div className="option-detail">
      <div className="container">
        <div className="row justify-content-center">
          {/* <div className="col-lg-4 desktop">
            <div className="left-side">
              <img src="images/01.png" alt={""} className="img-fluid" />
              <div className="one">
                <h2>Anagrafica</h2>
                <h3 className="mobile">
                  Ti preghiamo di controllare
                  <br />e confermare i tuoi recapiti:
                </h3>
              </div>
            </div>
            <div className="btn-section text-center mt-50 mb-50 desktop">
              <button href="#" className="solid-btn">
                Salva ed esci
              </button>
            </div>
          </div> */}
          <div className="col-lg-8">
            <div className="right-side address-detail address-label">
              <div className="label-box">
                <div className="option-box box-shadow-none bg-none p-0">
                  <h3>{t("Dati personali")}</h3>
                  <Link
                    to={"/user-details/personal-data"}
                    onClick={() =>
                      handleTabName("I tuoi dati > Mod. Dati Personali")
                    }
                  >
                    {t("Modifica")}
                  </Link>
                </div>
                <div className="address-detail-inner">
                  <form method="POST">
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                        <div className="form-group">
                          <label className="profile_details_text">
                            {t("Nome")}
                          </label>
                          <p>{userDetails?.firstName || ""}</p>
                          {/* <input type="text" name="first_name" className="form-control" value="" required> */}
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                        <div className="form-group">
                          <label className="profile_details_text">
                            {t("Cogome")}
                          </label>
                          <p>{userDetails?.surName || ""}</p>
                          {/* <input type="text" name="last_name" className="form-control" value="" required> */}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className="form-group">
                          <label className="profile_details_text">
                            {t("Data di nascita")}
                          </label>
                          <p>
                            {userDetails?.birthDate === undefined ||
                            userDetails?.birthDate === null ||
                            Object.keys(userDetails?.birthDate).length === 0
                              ? moment(
                                  userDetails?.address?.m_data_nasc,
                                  "DD/MM/YYYY"
                                ).format("DD/MM/YYYY")
                              : moment(
                                  userDetails?.birthDate,
                                  "DD/MM/YYYY"
                                ).format("DD/MM/YYYY")}
                          </p>
                          {/* <input type="email" name="email" className="form-control" value="" required> */}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                        <div className="form-group">
                          <label className="profile_details_text">
                            {t("Luogo di nascita")}
                          </label>
                          <p>
                            {userDetails?.birthPlace ||
                              userData?.address?.m_comune ||
                              ""}
                          </p>
                          {/* <input type="text" name="first_name" className="form-control" value="" required> */}
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                        <div className="form-group">
                          <label className="profile_details_text">
                            {t("Paese")}
                          </label>
                          <p>
                            {userDetails?.village ||
                              userData?.address?.m_naz ||
                              ""}
                          </p>
                          {/* <input type="text" name="last_name" className="form-control" value="" required> */}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className="form-group">
                          <label className="profile_details_text">
                            {t("Cod. Fiscale")}
                          </label>
                          <p>{userDetails?.codeTax || ""}</p>
                          {/* <input type="email" name="email" className="form-control" value="" required> */}
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="label-box">
                <div className="option-box box-shadow-none bg-none p-0">
                  <h3>{t("Contatti")}</h3>
                  <Link
                    to={"/user-details/contact"}
                    className="edit"
                    onClick={() => handleTabName("I tuoi dati > Mod. Contatti")}
                  >
                    {t("Modifica")}
                  </Link>
                </div>
                <div className="address-detail-inner">
                  <form method="POST">
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className="form-group">
                          <label className="profile_details_text">
                            {t("Tel. Cellulare")}
                          </label>
                          <p>{userDetails?.mobileNumber || ""}</p>
                          {/* <input type="Number" name="number" className="form-control" value="" required> */}
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className="form-group">
                          <label className="profile_details_text">
                            {t("Altro recapito telefonico")}
                          </label>
                          <p>{userDetails?.address?.tel_altro || ""}</p>
                          {/* <input type="text" name="last_name" className="form-control" value="" required> */}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className="form-group">
                          <label className="profile_details_text">E-mail</label>
                          <p>{userDetails?.email || ""}</p>
                          {/* <input type="email" name="email" className="form-control" value="" required> */}
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="label-box">
                <div className="option-box box-shadow-none bg-none p-0">
                  <h3>{t("Residenza")}</h3>
                  <Link
                    to={"/user-details/residenacy"}
                    className="edit"
                    onClick={() =>
                      handleTabName("I tuoi dati > Mod. Residenza")
                    }
                  >
                    {t("Modifica")}
                  </Link>
                </div>
                <div className="address-detail-inner">
                  <form method="POST">
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-12 col-6">
                        <div className="form-group">
                          <label className="profile_details_text">
                            {t("Via/Piazza")}
                          </label>
                          <p>
                            {userDetails?.piazzaWay ||
                              userData?.address?.indir_resid ||
                              ""}
                          </p>
                          {/* <input type="Number" name="number" className="form-control" value="" required> */}
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12 col-6">
                        <div className="form-group">
                          <label className="profile_details_text">
                            {t("Civico")}
                          </label>
                          <p>
                            {userDetails?.civic ||
                              userData?.address?.civico_resid ||
                              ""}
                          </p>
                          {/* <input type="text" name="last_name" className="form-control" value="" required> */}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                        <div className="form-group">
                          <label className="profile_details_text">CAP</label>
                          <p>
                            {userDetails?.CAP ||
                              userData?.address?.cap_resid ||
                              ""}
                          </p>
                          {/* <input type="text" name="first_name" className="form-control" value="" required> */}
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                        <div className="form-group">
                          <label className="profile_details_text">
                            {t("Comune di")}
                          </label>
                          <p>
                            {userDetails?.city ||
                              userData?.address?.comune_resid ||
                              ""}
                          </p>
                          {/* <input type="text" name="last_name" className="form-control" value="" required> */}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className="form-group">
                          <label className="profile_details_text">
                            {t("Provincia")}
                          </label>
                          <p>
                            {userDetails?.province ||
                              userData?.address?.prov_resid ||
                              ""}
                          </p>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>

              {/* <div className="btn-section text-center mt-3 mb-50 desktop">
                <button href="#" className="solid-btn">
                  Salva ed esci
                </button>
              </div>  */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(UserDetails);
