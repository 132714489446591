import React, { useEffect } from "react";
import pregnant from "../../../assets/images/pregnant.svg";
import newwelcome from "../../../assets/images/Benvenuta.svg";
import workbicycle from "../../../assets/images/Little.svg";
import workOnBicycle from "../../../assets/images/work-on-bicycle.svg";

import { useDispatch } from "react-redux";
import {
  clearNavigatePath,
  clearTabName,
  getNavigatePath,
} from "../../../redux/navigatePath/navigateAction";
import { withTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const LandingPage = ({ t }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch();
  useEffect(() => {
    let isLogedIn = localStorage.getItem("isLogedIn");
    if (isLogedIn !== undefined && isLogedIn === "true") {
      dispatch(
        getNavigatePath({
          title: t("I tuoi questionari"),
          path: `/questions-list`,
        }),
        clearTabName()
      );
    } else {
      dispatch(
        getNavigatePath({
          title: t("Accedi"),
          path: `/auth/signIn`,
        }),
        clearTabName()
      );
    }
  }, [dispatch, t]);
  useEffect(() => () => dispatch(clearNavigatePath()), [dispatch]);

  const hanldeAccedi = () =>{
    navigate('/auth/signIn')
  }
  return (
    <>
      {/*body section*/}
      <div className="option-detail slider-section desktop">
        <div className="container">
          {/* <div className="owl-carousel owl-theme">
            <div className="item">
              <div className="card-block">
                <div className="img">
                  <img src={slider} alt="slider-01" />
                </div>
                <div className="content">
                  <h2>Topic 01</h2>
                  <p>
                    Lorem ipsum sit dolor sit amet. Lorem ipsum dolor sit amet.
                    Lorem dom ipsum cum dolor sit amet. Lorem ipsum dolor sit
                    amet. Lorem ipsum sit dolor sit amet. Lorem ipsum dolor sit
                    amet. Lorem dom ipsum cum dolor sit amet.{" "}
                    <b>Lorem ipsum dolor</b>
                    sit amet. Lorem ipsum sit dolor sit amet. Lorem ipsum dolor
                    sit amet. <b>Lorem</b> dom ipsum cum dolor sit amet. Lorem
                    ipsum dolor sit amet.
                  </p>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="card-block">
                <div className="img">
                  <img src={slider} alt="slider-01" />
                </div>
                <div className="content">
                  <h2></h2>
                  <p>
                    Lorem ipsum sit dolor sit amet. Lorem ipsum dolor sit amet.
                    Lorem dom ipsum cum dolor sit amet. Lorem ipsum dolor sit
                    amet. Lorem ipsum sit dolor sit amet. Lorem ipsum dolor sit
                    amet. Lorem dom ipsum cum dolor sit amet. Lorem ipsum dolor
                    sit amet. Lorem ipsum sit dolor sit amet. Lorem ipsum dolor
                    sit amet. Lorem dom ipsum cum dolor sit amet. Lorem ipsum
                    dolor sit amet.
                  </p>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="card-block">
                <div className="img">
                  <img src={slider} alt="slider-01" />
                </div>
                <div className="content">
                  <h2>Un futuro agli altri
</h2>
                  <p>
                    Lorem ipsum sit dolor sit amet. Lorem ipsum dolor sit amet.
                    Lorem dom ipsum cum dolor sit amet. Lorem ipsum dolor sit
                    amet. Lorem ipsum sit dolor sit amet. Lorem ipsum dolor sit
                    amet. Lorem dom ipsum cum dolor sit amet. Lorem ipsum dolor
                    sit amet. Lorem ipsum sit dolor sit amet. Lorem ipsum dolor
                    sit amet. Lorem dom ipsum cum dolor sit amet. Lorem ipsum
                    dolor sit amet.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="btn-section text-center mt-50">
            <button className="solid-btn bg-color">
              Prossima sezione{" "}
              <span>
                <i className="ri-arrow-right-s-line" />
              </span>
            </button>
          </div> */}
          <div
            id="carouselExampleIndicators"
            className="carousel slide"
            data-bs-ride="carousel"
          >
            <div className="carousel-indicators">
              <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to={0}
                className="active"
                aria-current="true"
                aria-label="Slide 1"
              />
              <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to={1}
                aria-label="Slide 2"
              />
              <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to={2}
                aria-label="Slide 3"
              />
              <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to={3}
                aria-label="Slide 4"
              />
            </div>
            <div className="carousel-inner">
              <div className="carousel-item active">
                <div className="item">
                  <div className="card-block">
                    <div className="img">
                      <img src={newwelcome} alt="slider-01" />
                    </div>
                    <div className="content">
                      <h2>Crescere insieme</h2>
                      <p>
                        Benvenuta nella piattaforma di registrazione del
                        progetto Indaco-Coorte dei Nati. È un progetto di
                        ricerca che coinvolge le donne in gravidanza (tranne le
                        gravidanze gemellari), maggiorenni e residenti in uno
                        dei Comuni della Valle del Sacco: Anagni, Arce, Artena,
                        Castro dei Volsci, Ceccano, Ceprano, Colleferro,
                        Falvaterra, Ferentino, Frosinone, Gavignano, Morolo,
                        Paliano, Pastena, Patrica, Pofi, Segni, Sgurgola,
                        Supino.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="carousel-item">
                <div className="item">
                  <div className="card-block">
                    <div className="img">
                      <img src={pregnant} alt="slider-02" />
                    </div>
                    <div className="content">
                      <h2> Cura di te e dei tuoi dati </h2>
                      <p>
                      Ti inviteremo a compilare questionari online in momenti
                      importanti, dalla gravidanza al primo anno di vita del tuo
                      bambino. Avrai inoltre l'opportunità di essere contattata
                      anche in fasi successive durante la crescita e lo sviluppo
                      del tuo piccolo. Il tuo contributo è fondamentale per noi!
                      Dedicando solo pochi minuti, aiuterai la ricerca
                      scientifica a migliorare la salute delle future mamme e
                      dei loro bambini.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="carousel-item">
                <div className="item">
                  <div className="card-block">
                    <div className="img">
                      <img src={workOnBicycle} alt="slider-02" />
                    </div>
                    <div className="content">
                      <h2> Sentiti libera</h2>
                      <p>
                        In qualsiasi momento potrai interrompere e riprendere il
                        tuo questionario o abbandonare lo studio. Sappi però che
                        noi crediamo nel tuo contributo, fino alla fine!
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="carousel-item">
                <div className="item">
                  <div className="card-block">
                    <div className="img">
                      <img src={workbicycle} alt="slider-01" />
                    </div>
                    <div className="content">
                      <h2>Un futuro agli altri</h2>
                      <p>
                        Se sei qui, già lo sai: ogni tua azione, ogni
                        questionario che completi, conta. Insieme, possiamo
                        costruire un futuro luminoso per le nuove generazioni.
                        Iniziamo?
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <button
              className="carousel-control-prev"
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide="prev"
            >
              <span className="carousel-control-prev-icon" aria-hidden="true" />
              <span className="visually-hidden">Previous</span>
            </button>
            <button
              className="carousel-control-next"
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide="next"
            >
              <span className="carousel-control-next-icon" aria-hidden="true" />
              <span className="visually-hidden">Next</span>
            </button>
          </div>
          <div className="footer-img-section">
            {/* <img alt="image" src="" className="backgroundimage" /> */}
            <div className="btn-section text-center mt-50" style={{marginBottom:"140px"}}>
              <button className="solid-btn bg-color" onClick={hanldeAccedi}>
                Accedi ai questionari{" "}
                <span>
                  <i className="ri-arrow-right-s-line" />
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>

      {/*mobile section option*/}
      <div className="option-detail slider-section mobile-section mobile">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="card-block">
                <div className="img">
                  <img src={newwelcome} alt="newwelcome" />
                </div>
                <div className="content">
                  <h2>Crescere insieme</h2>
                  <p>
                    Benvenuta nella piattaforma di registrazione del progetto
                    Indaco-Coorte dei Nati. È un progetto di ricerca che
                    coinvolge le donne in gravidanza (tranne le gravidanze
                    gemellari), maggiorenni e residenti in uno dei Comuni della
                    Valle del Sacco: Anagni, Arce, Artena, Castro dei Volsci,
                    Ceccano, Ceprano, Colleferro, Falvaterra, Ferentino,
                    Frosinone, Gavignano, Morolo, Paliano, Pastena, Patrica,
                    Pofi, Segni, Sgurgola, Supino.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="item">
                <div className="card-block">
                  <div className="img">
                    <img src={pregnant} alt="pregnant" />
                  </div>
                  <div className="content">
                    <h2>Cura di te e dei tuoi dati</h2>
                    <p>
                      Ti inviteremo a compilare questionari online in momenti
                      importanti, dalla gravidanza al primo anno di vita del tuo
                      bambino. Avrai inoltre l'opportunità di essere contattata
                      anche in fasi successive durante la crescita e lo sviluppo
                      del tuo piccolo. Il tuo contributo è fondamentale per noi!
                      Dedicando solo pochi minuti, aiuterai la ricerca
                      scientifica a migliorare la salute delle future mamme e
                      dei loro bambini.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="item">
                <div className="card-block">
                  <div className="img">
                    <img src={workOnBicycle} alt="work-on-bicycle" />
                  </div>
                  <div className="content">
                    <h2>Sentiti libera</h2>
                    <p>
                      In qualsiasi momento potrai interrompere e riprendere il
                      tuo questionario o abbandonare lo studio. Sappi però che
                      noi crediamo nel tuo contributo, fino alla fine!
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="item">
                <div className="card-block">
                  <div className="img">
                    <img src={workbicycle} alt="work-on-bicycle" />
                  </div>
                  <div className="content">
                    <h2>Un futuro agli altri</h2>
                    <p>
                      Ti inviteremo a compilare questionari online in momenti
                      importanti, dalla gravidanza al primo anno di vita del tuo
                      bambino. Avrai inoltre l'opportunità di essere contattata
                      anche in fasi successive durante la crescita e lo sviluppo
                      del tuo piccolo. Il tuo contributo è fondamentale per noi!
                      Dedicando solo pochi minuti, aiuterai la ricerca
                      scientifica a migliorare la salute delle future mamme e
                      dei loro bambini.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withTranslation()(LandingPage);
