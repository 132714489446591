import React, { useEffect, useState } from "react";
import mail from "../../../assets/images/Mail.png";
import { withTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { directVerification } from "../../../redux/auth/authAction";

const AbbiamoQuasi = ({ t }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userId } = useParams();
  const [count, setCount] = useState(0);
  const handleProcedi = () => {
    navigate("/UploadMotherId");
  };

  useEffect(() => {
    if (userId && count === 0) {
      dispatch(
        directVerification({
          userId,
        })
      );
      setCount(1);
    }
  }, [userId, dispatch, count]);

  return (
    <div className="option-detail">
      <div className="container">
        <div className="row align-items-center column-r Ottimo_section abbiamo_section">
          <div className="col-lg-5">
            <div className="left-side box-shadow-none bg-none justify-content-center p-0">
              <div className="meditation-yaga-img">
                <img src={mail} alt="Mail" className="img-fluid" />
              </div>
            </div>
          </div>
          <div className="col-lg-7">
            <div className="right-side text-center ">
              <div className="meditation-yaga-section">
                <h2>{t("Abbiamo quasi finito!")}</h2>
                <p>
                  {t(
                    "Nelle prossime schermate dovrai caricare la foto fronte e registrazione del tuo documento di identita."
                  )}
                </p>
                <div className="btn-section text-center mt-2">
                  <button className="solid-btn" onClick={handleProcedi}>
                    {t("Procedi")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(AbbiamoQuasi);
