import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { register, sendPDFEmail } from "../../../redux/auth/authAction";
import { drawDOM, exportPDF } from "@progress/kendo-drawing";
import indacopdflogo from "../../../assets/images/indacopdf-logo.png";
import footerpdf from "../../../assets/images/footerpdf.png";
import depppdf from "../../../assets/images/deppdf.jpg";
import "./Pdf.css";

const Register = ({ t }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const teams = localStorage.getItem("teams");
  const privacy = localStorage.getItem("privacy");
  const [userDetails, setUserDetails] = useState({});
  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState({
    password: false,
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    const trimmedValue =
      value?.length > 0 ? value.trimStart().replace(/  +/g, " ") : value;
    let formattedValue = trimmedValue;

    if (name === "firstName" || name === "surName") {
      formattedValue =
        trimmedValue.charAt(0).toUpperCase() + trimmedValue.slice(1);
    }

    // Convert to uppercase for codeTax
    if (name === "codeTax") {
      formattedValue = formattedValue.toUpperCase();
    }

    // Update user details and reset errors for the field
    setUserDetails((prev) => ({
      ...prev,
      [name]: formattedValue,
    }));

    // setUserDetails((prev) => ({
    //   ...prev,
    //   [name]: name === "codeTax" ? trimmedValue.toUpperCase() : trimmedValue,
    // }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: undefined,
    }));
  };

  /***Handles the validation of the form ***/
  const validateForm = () => {
    const errors = {};
    [
      "firstName",
      "surName",
      "codeTax",
      "email",
      "password",
      "mobileNumber",
      "confirmpassword",
    ].forEach((field) => {
      if (!userDetails[field]) {
        errors[field] = t("*Campo obbligatorio");
      } else if (field === "email" && !/\S+@\S+\.\S+/.test(userDetails.email)) {
        errors.email = t("Indirizzo email non valido");
      } else if (field === "codeTax" && userDetails?.codeTax?.length !== 16) {
        errors.codeTax = t("Deve contenere 16 caratteri");
      } else if (field === "mobileNumber" && userDetails?.mobileNumber === "") {
        errors.codeTax = t("Numero di telefono");
      } else if (
        field === "confirmpassword" &&
        userDetails.password !== userDetails.confirmpassword
      ) {
        errors.confirmpassword = t("*Le password non corrispondono");
      }
    });
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const convertHtmlToBase64 = async (element) => {
    const group = await drawDOM(element, { paperSize: "A4" });
    const dataUri = await exportPDF(group);
    const base64PDF = dataUri.split(";base64,")[1];
    return base64PDF;
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    const element = document.querySelector(".k-grid");
    const secondElement = document.querySelector(".k-secondgrid");

    // Convert the HTML content to base64 PDF
    const base64PDF = await convertHtmlToBase64(element);
    const base64PrivacyPDF = await convertHtmlToBase64(secondElement);
    const userDetailUpdate = {
      privacy: privacy,
      teamsandcondition: teams,
      ...userDetails,
    };

    if (validateForm()) {
      await new Promise((resolve, reject) => {
        dispatch(
          register({
            userDetailUpdate,
            callBack: () => {
              sendPdfDetails(base64PrivacyPDF, base64PDF);
              navigate("/auth/ottimio");
              resolve(); // Resolve the promise when callback is called
            },
          })
        );
      });
    }
  };

  const sendPdfDetails = async (base64PrivacyPDF, base64PDF) => {
    const payload = {
      email: userDetails?.email,
      privacy: base64PrivacyPDF,
      terms: base64PDF,
    };

    try {
      const secondApiResponse = await dispatch(sendPDFEmail(payload));

      if (secondApiResponse.success) {
        console.log("Second API call successful");
      } else {
        console.error("Second API call failed");
      }
    } catch (error) {
      console.error("Error during second API call:", error);
    }
  };

  const togglePasswordVisibility = (field) => {
    setShowPassword((prevShowPassword) => ({
      ...prevShowPassword,
      [field]: !prevShowPassword[field],
    }));
  };

  return (
    <div className="register-detail">
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div className="col-md-6 col-lg-6">
            <div className="text-center register_title_section">
              <div className="meditation-yaga-section">
                <h2>Registrati</h2>
              </div>
            </div>
            <form method="POST">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 col-12 register-sections desktop-details register-scroll">
                  <div
                    className={`form-group register-field ${
                      errors?.firstName ? "error-content" : ""
                    }`}
                  >
                    {errors?.firstName && (
                      <div className="error">{t(errors?.firstName)}</div>
                    )}
                    <label>{t("Nome")}</label>
                    <input
                      type="text"
                      name="firstName"
                      className="form-control"
                      value={userDetails?.firstName || ""}
                      onChange={handleChange}
                    />
                  </div>
                  <div
                    className={`form-group register-field ${
                      errors?.surName ? "error-content" : ""
                    }`}
                  >
                    {errors?.surName && (
                      <div className="error">{t(errors?.surName)}</div>
                    )}
                    <label>{t("Cognome")}</label>
                    <input
                      type="text"
                      name="surName"
                      className="form-control"
                      value={userDetails?.surName || ""}
                      onChange={handleChange}
                    />
                  </div>
                  <div
                    className={`form-group register-field ${
                      errors?.codeTax ? "error-content" : ""
                    }`}
                  >
                    {errors?.codeTax && (
                      <div className="error">{t(errors?.codeTax)}</div>
                    )}
                    <label>{t("Codice Fiscale")}</label>
                    <input
                      type="text"
                      name="codeTax"
                      maxLength={16}
                      className="form-control"
                      value={userDetails?.codeTax || ""}
                      onChange={handleChange}
                    />
                  </div>
                  <div
                    className={`form-group register-field ${
                      errors?.email ? "error-content" : ""
                    }`}
                  >
                    {errors?.email && (
                      <div className="error">{t(errors?.email)}</div>
                    )}
                    <label>{t("Email")}</label>
                    <input
                      type="email"
                      name="email"
                      className="form-control"
                      value={userDetails?.email || ""}
                      onChange={handleChange}
                    />
                  </div>
                  <div
                    className={`form-group register-field ${
                      errors?.mobileNumber ? "error-content" : ""
                    }`}
                  >
                    {errors?.mobileNumber && (
                      <div className="error">{t(errors?.mobileNumber)}</div>
                    )}
                    <label>{t("Numero di telefono")}</label>
                    <input
                      type="number"
                      name="mobileNumber"
                      className="form-control no-spinners"
                      value={userDetails?.mobileNumber || ""}
                      onChange={handleChange}
                    />
                  </div>
                  <div
                    className={`form-group register-field ${
                      errors?.firstName ? "error-content" : ""
                    }`}
                    style={{ position: "relative" }}
                  >
                    {errors?.password && (
                      <div className="error">{t(errors?.password)}</div>
                    )}
                    <label>{t("Imposta password")}</label>
                    <div style={{ position: "relative" }}>
                      <input
                        type={showPassword?.password ? "text" : "password"}
                        name="password"
                        className="form-control"
                        value={userDetails?.password || ""}
                        onChange={handleChange}
                        style={{ paddingRight: "40px" }}
                      />
                      <i
                        className={
                          !showPassword?.password
                            ? "ri-eye-off-line"
                            : "ri-eye-line"
                        }
                        onClick={() => togglePasswordVisibility("password")}
                        style={{
                          position: "absolute",
                          right: "10px",
                          top: "50%",
                          transform: "translateY(-50%)",
                          cursor: "pointer",
                          fontSize: "20px",
                        }}
                      />
                    </div>
                  </div>
                  <div
                    className={`form-group register-field ${
                      errors?.firstName ? "error-content" : ""
                    }`}
                    style={{ position: "relative" }}
                  >
                    {errors?.confirmpassword && (
                      <div className="error">{t(errors?.confirmpassword)}</div>
                    )}
                    <label>{t("Ripeti password")}</label>
                    <div style={{ position: "relative" }}>
                      <input
                        type={showPassword?.password ? "text" : "password"}
                        name="confirmpassword"
                        className="form-control"
                        value={userDetails?.confirmpassword || ""}
                        onChange={handleChange}
                        style={{ paddingRight: "40px" }}
                      />
                      <i
                        className={
                          !showPassword?.password
                            ? "ri-eye-off-line"
                            : "ri-eye-line"
                        }
                        onClick={() => togglePasswordVisibility("password")}
                        style={{
                          position: "absolute",
                          right: "10px",
                          top: "50%",
                          transform: "translateY(-50%)",
                          cursor: "pointer",
                          fontSize: "20px",
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="btn-section text-center">
                  <button
                    href="#"
                    className="solid-btn"
                    onClick={handleRegister}
                    style={{ marginBottom: "100px" }}
                  >
                    {t("Registrati")}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div style={{ position: "absolute", left: "-9999px" }}>
          <div className="k-grid pdf pdfContain">
            <div className="main_section">
              <img src={depppdf} className="dep" alt="dep" />
              <img
                src={indacopdflogo}
                className="indaco text-center"
                alt="indaco"
              />
              <h1 className="text-center blue">
                Scheda informativa e manifestazione del consenso al trattamento
                dei dati personali*
              </h1>
              <h6 className="text-center blue">Versione 1 del 5 marzo 2024</h6>
              <p className="small text-center">
                * (Ai sensi delle linee guida per i trattamenti di dati
                personali nell`ambito delle sperimentazioni cliniche di
                medicinali - adottate dal Garante per la protezione dei dati
                personali con Deliberazione n.52 del 24 luglio 2008 G.U. n.190
                del 14 agosto 2008, in conformità con il Testo unico D.L. 30
                giugno 2003 n. 196 Codice in materia di protezionedei dati
                personali e in conformita con il Regolamento Generale sulla
                Protezione dei Dati, UE 2016/679)
              </p>
              <div className="h-30"></div>
              <p>
                Ai sensi del Regolamento UE 2016/679{" "}
                <i>General Data Protection Regulation</i> (GDPR) relativo alla
                protezione delle persone fisiche con riguardo al trattamento dei
                dati personali, noncha alla libera circolazione di tali dati, ti
                informiamo che tutte le informazioni personali raccolte saranno
                trattate nel rispetto delle disposizioni del Regolamento UE
                2016/679,anche detto GDPR, e della normativa italiana
                riguardanti la tutela dei dati personali (d.lgs. 196/2003
                ss.mm.ii.). Ti informano che i tuoi dati saranno trattati
                secondo le modalità di seguito riportate.
              </p>
              <div className="h-30"></div>
              <h2>FINALITA DEL TRATTAMENTO DEI DATI</h2>
              <p>
                La Regione Lazio in qualita di titolare del trattamento, in
                accordo alle responsabilita previste dalle norme di buona
                pratica clinica (D.L. 211/2003), trattera attraverso il
                responsabile del trattamento individuato nel Dipartimento di
                Epidemiologia del Sistema Sanitario della Regione Lazio i tuoi
                dati personali (nome, cognome, luogo e data di nascita, codice
                fiscale, indirizzo di domicilio, indirizzo di residenza, email,
                recapiti telefonici), i tuoi dati sulle abitudini di vita e
                sullo stato socioeconomico, sulle esposizioni ambientali e la
                salute soltanto nella misura in cui siano indispensabili in
                relazione all`obiettivo dello studio, ai sensi del Provvedimento
                del Garante per la protezione dei dati personali n. 146 del 5
                giugno 2019, recante le prescrizioni relative al trattamento di
                categorie particolari di dati, ai sensi dell`art. 21, comma 1
                del D. Lgs. 10 agosto 2018, n. 101, ai sensi del GDPR e del D.
                Lgs. 101/2018 di adeguamento della normativa nazionale alle
                disposizioni del GDPR.
                <br />
                Il trattamento dei dati personali relativi a nome, cognome,
                luogo e data di nascita, codice fiscale, indirizzo di domicilio,
                indirizzo di residenza, email, recapiti telefonici, e
                indispensabile allo svolgimento dello studio: il rifiuto di
                conferirli non ti consentira di parteciparvi.
              </p>
              <div className="h-30"></div>
              <h2>BASE GIURIDICA DEL TRATTAMENTO DEI DATI PERSONALI</h2>
              <p>
                La partecipazione al presente studio avviene su base volontaria,
                libera e consapevole mediante la sottoscrizione del consenso al
                trattamento dei dati personali, ai sensi degli artt. 6, par. 1,
                lett. a) e 9, par. 2, lett. a) GDPR. La tua partecipazione allo
                studio e importante perche offre un aiuto per l`avanzamento
                della ricerca scientifica e, aumentando il bagaglio delle
                conoscenze disponibili, per il progresso della societa. Il tuo
                consenso esplicito puo essere liberamente revocato in ogni
                momento e con la facilita con cui lo ha conferito, ai sensi
                dell`art. 17, par.1, lett. b) GDPR, senza compromettere lo
                studio e/o la validita dei suoi risultati; l`eventuale revoca
                avra valore solo per il futuro, restando valido il trattamento
                eseguito fino a quel momento.
              </p>
              <div className="h-30"></div>
              <h2>NATURA DEI DATI TRATTATI</h2>
              <p>
                Verranno trattati alcuni tuoi dati personali (quali luogo e data
                di nascita, codice fiscale, indirizzo di domicilio, indirizzo di
                residenza, email, recapiti telefonici), e dati relativi alla tua
                salute e a quella di tuo figlio; in base alle finalita della
                ricerca e alle caratteristiche dello studio, saranno raccolti
                dati relativi alla storia medica, alla situazione socioeconomica
                e agli stili di vita tua e di tuo figlio.
              </p>
              <div className="h-30"></div>
              <h2>MODALITA DEL TRATTAMENTO</h2>
              <p>
                Tutte le informazioni raccolte nell`ambito dello studio saranno
                trattate nel rispetto delle disposizioni del GDPR e della
                normativa italiana riguardante la gestione e la tutela dei dati
                personali.
              </p>
              <p>
                I tuoi dati personali saranno trattati con la massima
                riservatezza, nel rispetto delle norme di sicurezza per gli
                archivi informatici e cartacei dalla normativa, e verranno
                utilizzati solo da personale autorizzato esclusivamente per
                scopi di ricerca scientifica.
              </p>
              <p>
                Ti informiamo, inoltre, che il trattamento dei dati avviene
                mediante strumenti idonei a garantire la sicurezza e la
                riservatezza e puo essere effettuato anche attraverso strumenti
                automatizzati atti a memorizzare e gestire i dati stessi, con
                tecniche di cifratura o l`utilizzo di codici identificativi tali
                da consentire l`identificazione solo in caso di necessitaa.
              </p>
              <p>
                I dati sono pseudonimizzati in quanto, dopo la raccolta, sono
                conservati separatamente da quelli identificativi (nome, cognome
                ecc.), essendo assegnato un codice ai partecipanti che non ne
                permette la diretta identificazione. I dati sono crittografati
                nel rispetto delle misure di sicurezza per la tutela della
                privacy e della confidenzialita dei dati, previste dalla
                normativa sopra indicata.
              </p>
              <p>
                Gli autorizzati al trattamento (medici, statistici e altre
                figure professionali operanti per il Responsabile) hanno accesso
                ai dati esclusivamente in forma pseudonimizzata ogni volta che
                si renda necessaria l`identificazione univoca, quindi privati
                del codice identificativo, in tutte le altre istanze, sempre
                unicamente per gli scopi della ricerca. L`accesso ai soli dati
                personali avviene per esigenze di follow-up.
              </p>
              <p>
                I dati dello studio Indaco - Coorte dei Nati sono ospitati su un
                server dedicato di proprietà del Dipartimento di Epidemiologia
                del SSR del Lazio, parte integrante dell`infrastruttura
                informatica in uso e fisicamente presente all`interno dei locali
                adibiti a sede dello stesso. Ciò garantirà la sicurezza del dato
                e ne consentirà la gestione e l`analisi. La gestione del
                database e le analisi statistiche saranno effettuati dal
                Dipartimento di Epidemiologia del SSR del Lazio. L`accesso alle
                virtual machine e l`amministrazione software delle macchine è
                consentito esclusivamente al personale competente appartenente
                al Dipartimento di Epidemiologia del Sistema Sanitario della
                Regione Lazio. Le informazioni raccolte sono strettamente
                confidenziali e saranno usate in modo aggregato e anonimo per la
                preparazione di rapporti scientifici, nei quali i partecipanti
                allo studio non saranno in alcun modo identificabili. I
                risultati potranno essere divulgati in forma strettamente
                anonima e aggregata, su riviste scientifiche nazionali e
                internazionali e sul sito Internet www.progettoindaco.it
              </p>
              <div className="h-30"></div>
              <h2>
                CATEGORIE DI SOGGETTI CUI I DATI POSSONO ESSERE COMUNICATI
              </h2>
              <p>
                I dati saranno trattati all`interno dell`ente Responsabile da
                soggetti autorizzati al trattamento sotto la responsabilità
                dello stesso ente per le finalità sopra riportate nel rispetto
                delle regole deontologiche e del codice etico. Ti informiamo,
                inoltre, che i dati raccolti saranno utilizzati in forma
                pseudonimizzata per le analisi statistiche ed epidemiologiche e
                potranno essere comunicati a soggetti partner della ricerca.
                Tutte le operazioni di trattamento, effettuate solo da personale
                debitamente istruito ed autorizzato dal Responsabile, secondo le
                indicazioni del Titolare, avverranno nel rispetto del segreto
                professionale, del segreto d`ufficio e dei principi di
                correttezza, liceità e trasparenza, secondo quanto disposto
                dalle normative vigenti.
              </p>
              <div className="h-30"></div>
              <h2>DIFFUSIONE E TRASFERIMENTO DEI DATI</h2>
              <p>
                Il Dipartimento di Epidemiologia, nell`ambito di progetti
                collaborativi con finalità coerenti con quelle del studio Indaco
                - Coorte dei nati e a seguito di approvazione da parte del
                Comitato Etico competente e di sottoscrizione di un accordo,
                potrà trasferire dati socio-demografici, epidemiologici, clinici
                e molecolari, o eventuali campioni biologici, in forma anonima
                (con utilizzo di fake ID) a enti e istituti di ricerca,
                partnership pubbliche o private nel campo della ricerca
                scientifica, organismi pubblici, fondazioni scientifiche,
                associazioni senza scopo lucro aventi finalità di ricerca
                ubicati in Paesi dell`Unione Europea.
              </p>
              <p>
                Per quanto concerne l`eventuale trasferimento dei dati verso
                Paesi Terzi, il Titolare rende noto che il trasferimento avverrà
                nel rispetto degli artt. 44 e ss. del GDPR (Trasferimento sulla
                base di una decisione di adeguatezza, trasferimento soggetto a
                garanzie adeguate) con esclusione dell`art. 49 a oggetto
                &ldquo;Deroghe in specifiche situazioni&rdquo;. Maggiori
                informazioni sono disponibili presso il Titolare e Responsabile,
                scrivendo all`indirizzo sopra indicato.
              </p>
              <p>
                I partner dello studio si impegnano formalmente a non trattare i
                dati per fini diversi da quelli indicati nello studio condiviso,
                a non comunicarli o trasferirli ulteriormente a terzi, a non
                utilizzarli per fini di lucro o commerciali. Sarà creato un
                nuovo codice identificativo specifico per ciascun nuovo studio.
                La connessione tra il nuovo codice e il codice dello studio
                Indaco - Coorte dei nati sarà mantenuta presso il Dipartimento
                di Epidemiologia del Sistema Sanitario della Regione Lazio e non
                sarà accessibile all`esterno.
              </p>
              <div className="h-30"></div>
              <h2>PERIODO DI CONSERVAZIONE DEI DATI PERSONALI</h2>
              <p>
                I dati saranno conservati fino al compimento del 18° anno di
                vita dei bambini arruolati nella coorte. Oltre questo termine, i
                dati verranno resi anonimi in maniera irreversibile e archiviati
                o se necessario verrà chiesto un ulteriore consenso per il
                trattamento dei dati.
              </p>
              <p>
                I dati personali raccolti durante i singoli studi, dietro
                specifica procedura di consenso informato, sono conservati fino
                all`eventuale richiesta di cancellazione da parte dell`iscritto
                (art.17 paragrafo 1 del GDPR).
              </p>
              <div className="h-30"></div>
              <h2>
                DIRITTI DELL`INTERESSATO E MODALITÀ DI ESERCIZIO DEI DIRITTI
              </h2>
              <p>
                In relazione al trattamento dei tuoi dati personali, il Capo III
                del GDPR Le riconosce specifici diritti e, in particolare, il
                diritto, se applicabile, di:
              </p>
              <ul className="rules">
                <li>
                  <b>A.</b> accedere ai tuoi dati personali e alle informazioni
                  relative agli stessi;
                </li>
                <li>
                  <b>B.</b> chiedere l`aggiornamento, la rettifica dei dati
                  inesatti e l`integrazione di quelli incompleti;
                </li>
                <li>
                  <b>C.</b> chiedere la cancellazione dei tuoi dati personali al
                  verificarsi di una delle condizioni indicate all`art. 17,
                  paragrafo 1 del GDPR e nel rispetto delle eccezioni previste
                  al paragrafo 3 del medesimo articolo e purché non sussistano
                  obblighi di conservazione del dato previsti dalla legge;
                </li>
                <li>
                  <b>D.</b> chiedere la limitazione del trattamento dei tuoi
                  dati al ricorrere di una delle ipotesi previste dall`art. 18
                  del GDPR;
                </li>
                <li>
                  <b>E.</b> chiederne la trasmissione dei tuoi dati ad altro
                  Titolare in formato leggibile con le più comuni applicazioni
                  informatiche (cd. “diritto alla portabilità”), ove
                  tecnicamente possibile;
                </li>
                <li>
                  <b>F.</b> opporsi in qualunque momento al trattamento dei tuoi
                  dati personali e al ricorrere di situazioni particolari che ti
                  riguardano;
                </li>
                <li>
                  <b>G.</b> revocare il consenso in qualsiasi momento,
                  limitatamente alle ipotesi in cui il trattamento sia basato
                  sul tuo consenso per una o più specifiche finalità e riguardi
                  dati personali comuni oppure particolari categorie di dati. Il
                  trattamento basato sul consenso, ed effettuato
                  antecedentemente alla revoca dello stesso, conserva, comunque,
                  la sua liceità;
                </li>
                <li>
                  <b>H.</b> presentare reclamo all`Autorità Garante per la
                  Protezione dei Dati Personali (i cui recapiti sono rinvenibili
                  sul sito www.garanteprivacy.it) in caso di illecito
                  trattamento o di ritardo nella risposta del Titolare a una
                  richiesta inerente i tuoi diritti;
                </li>
                <li>
                  <b>I.</b> interrompere in ogni momento e senza fornire alcuna
                  giustificazione la tua partecipazione allo studio: in tal
                  caso, i campioni biologici correlati verranno distrutti. Non
                  saranno raccolti, inoltre, ulteriori dati che ti riguardano,
                  ferma restando l`utilizzazione di quelli eventualmente già
                  raccolti per determinare, senza alterarli, i risultati della
                  ricerca.
                  <br />
                  La richiesta per l`esercizio dei diritti di cui sopra deve
                  essere presentata rivolgendosi direttamente al promotore dello
                  studio:
                  <br />
                  Daniela Porta <br />
                  Dipartimento di Epidemiologia del Sistema Sanitario della
                  Regione Lazio- ASL Roma 1
                  <br />
                  Via Cristoforo Colombo, 112
                  <br />
                  Telefono: 06.99722188
                  <br />
                  Email: d.porta@deplazio.it
                </li>
              </ul>
              <div className="h-30"></div>
              <h2>PROFILAZIONE</h2>
              <p>
                Il Titolare non utilizza processi automatizzati finalizzati alla
                profilazione.
              </p>
              <div className="h-30"></div>
              <h2>NOMINATIVI E CONTATTI</h2>
              <h5>Responsabile scientifico dello studio</h5>
              <p>
                Paola Michelozzi
                <br />
                Dipartimento di Epidemiologia del Servizio Sanitario della
                Regione Lazio- ASL Roma 1
                <br />
                Via Cristoforo Colombo, 112
                <br />
                Tel:06.99722153
                <br />
                Email: p.michelozzi@deplazio.it
              </p>
              <div className="h-30"></div>
              <h5>Titolare del trattamento/protezione dei dati</h5>
              <p>
                Regione Lazio
                <br />
                Via Cristoforo Colombo 212, 00147 Roma
                <br />
                Email: dpo@regione.lazio.it
              </p>
              <div className="h-30"></div>
              <h5>Responsabile del trattamento/protezione dei dati</h5>
              <p>
                Dipartimento di Epidemiologia del Servizio Sanitario della
                Regione Lazio- ASL Roma 1
                <br />
                Via Cristoforo Colombo 112, 00147 Roma
                <br />
                Email: dpo@aslroma1.it
              </p>
              <div className="h-30"></div>
              <h1 className="text-center blue">Consenso</h1>
              <h6 className="text-center">
                Io, {userDetails?.firstName} {userDetails?.surName}
              </h6>
              <ul className="consenso">
                <li>
                  Ho letto e compreso per intero il documento informativo
                  riguardante la raccolta, la conservazione e l`utilizzo, anche
                  futuro, dei dati personali del partecipante allo studio
                </li>
                <li>
                  Ho letto e compreso le finalità e le modalità del trattamento
                  dei dati personali e di aver avuto sufficiente tempo per
                  decidere, liberamente e volontariamente, se prestare o meno il
                  consenso.
                </li>
              </ul>
              <div className="h-30"></div>
              <h2 className="text-center">Dichiaro di</h2>
              <div className="pdfCheckbox">
                <div id="checkboxes">
                  <div className="checkbox c-checkbox">
                    <label>
                      <input
                        type="checkbox"
                        checked={privacy === "privacy1" ? true : false}
                      />
                      <span className="fa fa-check"></span>
                      autorizzare il trattamento dei miei dati personali e di
                      quelli di mio figlio per le finalità dello studio in
                      oggetto ai sensi del Regolamento EU 679/2016 regolamento
                      Europeo e della normativa italiana in materia della
                      Protezione dei dati personali (d.lgs. 101/2018), avendo
                      ottenuto il consenso del padre del bambino/a.
                    </label>
                  </div>
                  <div className="checkbox c-checkbox">
                    <label>
                      <input
                        type="checkbox"
                        checked={privacy === "privacy2" ? true : false}
                      />
                      <span className="fa fa-check"></span>
                      autorizzare il trattamento dei miei dati personali e di
                      quelli di mio figlio per le finalità dello studio in
                      oggetto ai sensi del Regolamento EU 679/2016 regolamento
                      Europeo e della normativa italiana in materia della
                      Protezione dei dati personali (d.lgs. 101/2018), in
                      qualità di unico genitore
                    </label>
                  </div>
                </div>
              </div>
              <div className="footer_s">
                <img src={footerpdf} alt="footer" />
              </div>
            </div>
          </div>
        </div>

        <div style={{ position: "absolute", left: "-9999px" }}>
          <div className="k-secondgrid pdf pdfContain">
            <div className="main_section">
              <img src={depppdf} className="dep" alt="dep" />
              <img
                src={indacopdflogo}
                className="indaco text-center"
                alt="indaco"
              />
              <h1 className="text-center blue">
                Scheda informativa dello Studio
              </h1>
              <h6 className="text-center blue">Versione 1 del 5 marzo 2024</h6>
              <div className="h-35"></div>
              <p>
                Il Dipartimento di Epidemiologia del Servizio Sanitario
                Regionale del Lazio (DEP) sta conducendo per conto di Regione
                Lazio lo studio{" "}
                <b>
                  “Coorte di nati nel Sin Valle del Sacco per la valutazione
                  prospettica dello stato di salute dei bambini in relazione ai
                  fattori di rischio ambientali (Indaco- Coorte dei Nati)”
                </b>
                nell’ambito dell’Accordo di Programma per la realizzazione degli
                interventi di messa in sicurezza e bonifica del Sin Valle del
                Sacco, stipulato dal Ministero dell’Ambiente e dalla Regione
                Lazio con la determinazione n. G09914 del 27/07/2022. Prima di
                decidere se accettare o rifiutare di partecipare, ti preghiamo
                di leggere attentamente quanto segue. Se avessi bisogno di
                ulteriori precisazioni, puoi contattare la responsabile
                scientifica dello studio:
                <br />
                Dott.ssa Daniela Porta
                <br />
                Dipartimento Epidemiologia del S.S.R. - ASL Roma1
                <br />
                Via Cristoforo Colombo 112, 00147 Roma
                <br />
                Email d.porta@deplazio.it
              </p>

              <div className="h-30"></div>
              <h2>SCOPO DELLO STUDIO</h2>
              <p>
                Questo studio consiste nell’arruolamento delle donne in
                gravidanza residenti nell’area del Sin Valle del Sacco e nella
                raccolta di informazioni epidemiologiche, sanitarie e di
                campioni biologici e di una coorte di nati al fine di valutare
                prospetticamente lo stato di salute dei bambini in relazione
                all’esposizione a fattori di rischio ambientali presenti
                nell’area.
                <br />I bambini saranno seguiti dalla nascita alla fine del
                primo anno di vita e potranno essere ricontattati anche in età
                successive.
              </p>

              <div className="h-30"></div>
              <h2>COSA COMPORTA LA PARTECIPAZIONE ALLO STUDIO</h2>
              <p>
                La partecipazione è su base volontaria e in ogni momento si può
                decidere di abbandonare lo studio.
              </p>
              <p>Partecipare allo studio implica:</p>
              <ul className="rules">
                <li>
                  <b>1.</b> la registrazione su una piattaforma online dello
                  studio, in cui saranno richiesti dati anagrafici e recapiti;
                </li>
                <li>
                  <b>2.</b> la ricezione di messaggi per posta elettronica e via
                  whatsapp in relazione alle tappe dello studio;
                </li>
                <li>
                  <b>3.</b> la compilazione on-line di alcuni questionari nel
                  corso dei primi due anni di partecipazione allo studio: uno
                  relativo al periodo pre-gravidanza che verrà compilato al
                  momento dell’adesione allo studio; uno relativo al periodo
                  della gravidanza che verrà compilato possibilmente nel corso
                  del 9 mese di gravidanza; uno relativo alla nascita del
                  bambino, che verrà compilato possibilmente entro il 3 mese di
                  vita del bambino; uno relativo al primo anno di vita del
                  bambino che verrà compilato entro il 2° anno di vita.
                  <br />I questionari relativi alla mamma contengono
                  informazioni quali lo stile di vita, la dieta, le
                  caratteristiche anagrafiche, le esposizioni ambientali ed
                  occupazionali, la passata storia riproduttiva, la salute prima
                  e durante la gravidanza, l’assunzione di farmaci, il parto e
                  l’allattamento. Nel primo questionario saranno inoltre
                  richieste alcune informazioni sul padre del bambino (età,
                  cittadinanza, titolo di studio, occupazione, salute, peso e
                  altezza). I questionari relativi al bambino conterranno
                  informazioni sulla dieta, le vaccinazioni, la salute, lo stile
                  di vita, l’uso di farmaci, l’ambiente domestico, le misure
                  antropometriche, la crescita e lo sviluppo nel primo anno di
                  vita;
                </li>
                <li>
                  <b>4.</b> l’interrogazione delle banche dati del Servizio
                  Sanitario Nazionale per ottenere informazioni circa eventi
                  sanitari importanti, quali il ricorso al pronto soccorso,
                  l’ospedalizzazione, o altri eventi avversi della mamma e del
                  bambino;
                </li>
                <li>
                  <b>5.</b> la possibilità, di essere periodicamente contattati
                  nel corso degli anni futuri, dal personale facente parte dello
                  studio, per rispondere di nuovo ad alcune domande sullo stato
                  di salute del bambino, sul suo sviluppo e sull’esposizione a
                  possibili fattori di rischio;
                </li>
                <li>
                  <b>6.</b> la richiesta per raccogliere campioni biologici
                  (saliva, capelli e unghie della mamma e del bambino), presso
                  lo studio del proprio Pediatra di Libera Scelta, o in
                  alternativa auto-prelevati a casa tramite un kit e inviati per
                  posta in buste pre-affrancate al Dipartimento Epidemiologia
                  del S.S.R. - ASL Roma1 Regione Lazio. La raccolta dei campioni
                  biologici avverrà previa firma di un consenso informato
                  specifico, richiesto al momento della raccolta stessa.
                </li>
              </ul>
              <div className="h-30"></div>
              <h2>BENEFICI E RISCHI DERIVANTI DALLA PARTECIPAZIONE</h2>
              <p>
                Partecipare a questo studio non comporta rischi, se non il
                dedicare venti minuti circa alla compilazione di ciascun
                questionario. Non vi sono benefici diretti per la salute delle
                partecipanti e dei loro figli, però avrete la possibilità di
                ricevere informazioni sulle buone pratiche per favorire il
                benessere e la salute dei vostri figli, anche tramite la rete
                dei pediatri di base del territorio. Partecipando al progetto
                avrete inoltre la possibilità di contribuire con un po’ del
                vostro tempo al progresso delle conoscenze mediche. Tale
                progresso si potrà in futuro tradurre in un miglioramento della
                salute delle mamme in gravidanza e dei loro bambini.
              </p>
              <div className="h-30"></div>
              <h2>APPROVAZIONE DA PARTE DEL COMITATO ETICO</h2>
              <p>
                Il protocollo di questo studio è stato redatto in conformità
                alle Norme di Buona Pratica Clinica della Unione Europea e alla
                revisione corrente della Dichiarazione di Helsinki ed è stato
                approvato dal Comitato Etico dell’Ospedale Pediatrico Bambino
                Gesù (prot. 174 del 5/4/2024), che opera al fine di assicurare
                la salvaguardia dei diritti, dell’integrità e del benessere dei
                soggetti coinvolti nello studio.
              </p>
              <div className="h-30"></div>
              <h1 className="text-center blue">
                Consenso informato per la partecipazione allo studio
              </h1>
              <h6 className="text-center">
                Io, {userDetails?.firstName} {userDetails?.surName},
              </h6>
              <ul className="consenso">
                <li>
                  Ho letto attentamente e compreso quanto esposto nella Scheda
                  Informativa dello studio (versione 1 del 5 marzo 2024) e ho
                  compreso le modalità di conduzione dello studio e le sue
                  finalità;
                </li>
                <li>
                  Ho compreso che la partecipazione è volontaria; che potrò
                  ritirarmi dallo studio in qualsiasi momento e senza fornire
                  giustificazione, nella certezza che né il rifiuto alla mia
                  partecipazione né il ritiro della mia adesione comporteranno
                  discriminazioni; che le informazioni raccolte saranno
                  strettamente riservate e i dati saranno utilizzati per le
                  finalità indicate dello studio; che in caso revocassi il
                  consenso ho il diritto di richiedere che tutti i dati e/o i
                  campioni biologici precedentemente raccolti siano eliminati,
                  salvo che i dati e i campioni biologici, in origine o a
                  seguito del trattamento, non consentano più di identificarmi
                  (non saranno raccolti ulteriori nuovi dati che ti riguardano
                  fermo restando l&apos;utilizzazione di quelli eventualmente
                  già raccolti per determinare, senza alterarli, i risultati
                  dello studio)
                </li>
                <li>
                  Ho compreso che per ogni problema o per eventuali ulteriori
                  informazioni potrò rivolgermi alla responsabile scientifica
                  dello studio, la Dott.ssa Daniela Porta, Dipartimento
                  Epidemiologia del S.S.R. - ASL Roma1 Regione Lazio, Via
                  Cristoforo Colombo 112, 00147 Roma, email:
                  d.porta@deplazio.it;
                </li>
                <li>
                  Chiedo di ricevere copia della Scheda informativa e copia di
                  questo modulo di Consenso Informato
                </li>
              </ul>
              <div className="h-30"></div>
              <h2 className="text-center">Dichiaro di</h2>
              <div className="pdfCheckbox">
                <div id="checkboxes">
                  <div className="checkbox c-checkbox">
                    <label>
                      <input
                        type="checkbox"
                        checked={teams === "terms1" ? true : false}
                      />
                      <span className="fa fa-check"></span>
                      Voler partecipare volontariamente in prima persona,
                      insieme a mio figlio/a, allo studio designato, avendo
                      ottenuto il consenso del padre del bambino/a.
                    </label>
                  </div>

                  <div className="checkbox c-checkbox">
                    <label>
                      <input
                        type="checkbox"
                        checked={teams === "terms2" ? true : false}
                      />
                      <span className="fa fa-check"></span>
                      Voler partecipare volontariamente in prima persona,
                      insieme a mio figlio/a, allo studio designato, in qualità
                      di unico genitore
                    </label>
                  </div>
                </div>
              </div>
              <div className="footer_s">
                <img src={footerpdf} alt="footer" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(Register);
