import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { verifyResetEmail } from "../../../redux/auth/authAction";

const ForgetPassword = ({ t }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [state, setState] = useState({
    email: "",
  });

  const [errorInput, setErrorInput] = useState({
    email: "",
  });

  const handleChange = (e) => {
    setState((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
    setErrorInput((prevErrors) => ({
      ...prevErrors,
      [e.target.name]: "",
    }));
  };

  const handleNavigate = () => {
    navigate("/info/contact");
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (state.email === "") {
      setErrorInput((prevErrors) => ({
        ...prevErrors,
        email: state.email === "" ? t("*Campo obbligatorio") : "",
      }));
    } else {
      dispatch(verifyResetEmail(state));
    }
  };

  return (
    <div className="register-detail signin-section">
      <div className="container">
        <div className="row align-items-center justify-content-center">
          {/* <div className="col-lg-12">
           
          </div> */}
          <div className="col-md-6 col-lg-6">
            <div className="text-center register_title_section">
              
              <div className="meditation-yaga-section">
                <h2>{t("Recupero password")}</h2>
              </div>
              <p style={{ marginTop: "1rem" }}>
                Inserisci la mail con cui ti sei registrata e segui le
                istruzioni per reimpostare la tua password.
              </p>
            </div>
            <form method="POST">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 col-12 register-sections">
                  <div
                    className={`form-group register-field login-field ${
                      errorInput.email ? "error-content" : ""
                    }`}
                  >
                    <div className="error">{t(errorInput?.email)}</div>
                    <label>{t("Email")}</label>
                    <input
                      type="email"
                      name="email"
                      className="form-control"
                      value={state?.email || ""}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="btn-section text-center">
                  <button className="solid-btn" onClick={handleSubmit}>
                    {t("Invia email")}
                  </button>
                </div>
                <div className="link text-center">
                  <p
                    className="link"
                    onClick={handleNavigate}
                    dangerouslySetInnerHTML={{
                      __html: t(
                        "Non ricordi la mail? <br/>Contatta un operatore"
                      ),
                    }}
                  ></p>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(ForgetPassword);
