import {
  EMPTY_QUESTIONS_FORM_DATA,
  GET_FORM,
  GET_FORM_DETAILS_BY_ID,
  GET_FORM_DETAILS_BY_ID_FAILED,
  GET_FORM_DETAILS_BY_ID_SUCCESS,
  GET_FORM_FAILED,
  GET_FORM_SUCCESS,
  GET_QUESTIONARIES_LIST,
  GET_QUESTIONARIES_LIST_FAILED,
  GET_QUESTIONARIES_LIST_SUCCESS,
  GET_SECTIONS_BY_ID,
  GET_SECTIONS_BY_ID_FAILED,
  GET_SECTIONS_BY_ID_SUCCESS,
  GET_SUBMIT_FORM,
  GET_SUBMIT_FORM_FAILED,
  GET_SUBMIT_FORM_SUCCESS,
  SUBMIT_FORM,
  SUBMIT_FORM_FAILED,
  SUBMIT_FORM_SUCCESS,
  UPDATE_SUBMIT_FORM,
  UPDATE_SUBMIT_FORM_FAILED,
  UPDATE_SUBMIT_FORM_SUCCESS,
} from '../actionType'

export const getForm = () => {
  return {
    type: GET_FORM,
  }
}

export const getFormSuccess = (data) => {
  return {
    type: GET_FORM_SUCCESS,
    payload: data,
  }
}

export const getFormFailed = (data) => {
  return {
    type: GET_FORM_FAILED,
    payload: data,
  }
}

export const submitForm = (data) => {
  return {
    type: SUBMIT_FORM,
    payload: data
  }
}

export const submitFormSuccess = (data) => {
  return {
    type: SUBMIT_FORM_SUCCESS,
    payload: data,
  }
}

export const submitFormFailed = (data) => {
  return {
    type: SUBMIT_FORM_FAILED,
    payload: data,
  }
}
export const updateSubmitForm = (data) => {
  return {
    type: UPDATE_SUBMIT_FORM,
    payload: data
  }
}

export const updateSubmitFormSuccess = (data) => {
  return {
    type: UPDATE_SUBMIT_FORM_SUCCESS,
    payload: data,
  }
}

export const updateSubmitFormFailed = (data) => {
  return {
    type: UPDATE_SUBMIT_FORM_FAILED,
    payload: data,
  }
}



export const getsubmitForm = (data) => {
  return {
    type: GET_SUBMIT_FORM,
    payload: data
  }
}

export const getsubmitFormSuccess = (data) => {
  return {
    type: GET_SUBMIT_FORM_SUCCESS,
    payload: data,
  }
}

export const getsubmitFormFailed = (data) => {
  return {
    type: GET_SUBMIT_FORM_FAILED,
    payload: data,
  }
}

export const getQuestionariesList = (data) => {
  return {
    type: GET_QUESTIONARIES_LIST,
    payload: data
  }
}

export const getQuestionariesListSuccess = (data) => {
  return {
    type: GET_QUESTIONARIES_LIST_SUCCESS,
    payload: data,
  }
}

export const getQuestionariesListFailed = (data) => {
  return {
    type: GET_QUESTIONARIES_LIST_FAILED,
    payload: data,
  }
}

export const getQuestionsFormList = (data) => {
  return {
    type: GET_SECTIONS_BY_ID,
    payload: data
  }
}

export const getQuestionsFormListSuccess = (data) => {
  return {
    type: GET_SECTIONS_BY_ID_SUCCESS,
    payload: data,
  }
}

export const getQuestionsFormListFailed = (data) => {
  return {
    type: GET_SECTIONS_BY_ID_FAILED,
    payload: data,
  }
}

export const emptyQuestionsFormDataList = (data) => {
  return {
    type: EMPTY_QUESTIONS_FORM_DATA,
  }
}

export const getQuestionsFormDetails = (data) => {
  return {
    type: GET_FORM_DETAILS_BY_ID,
    payload: data
  }
}

export const getQuestionsFormDetailsSuccess = (data) => {
  return {
    type: GET_FORM_DETAILS_BY_ID_SUCCESS,
    payload: data,
  }
}

export const getQuestionsFormDetailsFailed = (data) => {
  return {
    type: GET_FORM_DETAILS_BY_ID_FAILED,
    payload: data,
  }
}
