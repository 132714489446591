import React, { useState } from "react";
import idUpload from "../../../assets/images/Lady.png";
import { withTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { uploadMotherId } from "../../../redux/auth/authAction";
import { useNavigate } from "react-router-dom";

const UploadMotherId = ({ t }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userId = localStorage.getItem("userId");

  const [image, setImage] = useState(null);
  const [isImage, setIsImage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedOption, setSelectedOption] = useState("");

  const handleFileChange = (event) => {
    const { name, files } = event.target;
    const file = files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        if (name === "imageforFront") {
          setImage((prevState) => ({ ...prevState, front: reader.result }));
          setIsImage((prevState) => ({
            ...prevState,
            frontValue: file,
            front: file.type.startsWith("image/"),
          }));
        } else if (name === "imageforBack") {
          setImage((prevState) => ({ ...prevState, back: reader.result }));
          setIsImage((prevState) => ({
            ...prevState,
            backValue: file,
            back: file.type.startsWith("image/"),
          }));
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSelectChange = (event) => {
    setSelectedOption(event?.target?.value);
    setErrorMessage("");
  };

  const handleProcedi = (e) => {
    e.preventDefault();

    if (!selectedOption) {
      setErrorMessage("Please select an option."); // Set error message
      return;
    }

    const formData = new FormData();
    formData.append("userId", userId);
    formData.append("selectIdFormom", selectedOption);
    formData.append("imageforForntmom", isImage?.frontValue);
    formData.append("imageforBackmom", isImage?.backValue);

    // Dispatch your action with the FormData object
    dispatch(
      uploadMotherId({
        userDetails: formData,
        callBack: () => {
          navigate("/registration-completed");
        },
      })
    );
  };

  return (
    <div className="option-detail login_section py-100 upload-motherId-section">
      <div className="container">
        <div className="row align-items-center id-upoload">
          <div className="col-lg-5">
            <div className="left-side box-shadow-none bg-none justify-content-center">
              <div className="meditation-yaga-img">
                <img src={idUpload} alt="idupload" className="img-fluid" />
              </div>
            </div>
          </div>
          <div className="col-lg-7">
            <div className="right-side text-center">
              <div className="meditation-yaga-section">
                <h2>{t("Documento di Identita")}</h2>
                <p>
                  {t(
                    "Scegli quale documento caricare e fai l’upload sia del fronte che del retro del documento. Questo verrà poi controllato dagli Admin in seguito."
                  )}
                  <br />
                  {t(
                    "AGGIUNGERE SPECIFICHE DEI DOC CHE POSSONO ESSERE CARICATI (PESO / FORMATI)"
                  )}
                </p>
              </div>
            </div>
            <div className="right-side address-detail pt-4">
              <div className="input-box pt-0">
                <div className="address-detail-inner">
                  <form action method="POST">
                    <div
                      className={`form-group mt-0 ${
                        errorMessage ? "error-content" : ""
                      }`}
                    >
                      {errorMessage && (
                        <div className="error">{errorMessage}</div>
                      )}
                      <label className="profile_details_text">
                        {t("Quale documento stai caricando?")}
                      </label>
                      <select
                        name="list"
                        id="pet-select"
                        value={selectedOption}
                        onChange={handleSelectChange}
                      >
                        <option value="">Seleziona un'opzione</option>
                        <option value="Identity ID">{t("Identity ID")}</option>
                        <option value="Passport">{t("Passport")}</option>
                        <option value="Driving License">
                          {t("Driving License")}
                        </option>
                      </select>
                    </div>
                  </form>
                </div>
                <div className="image-upload-wrap ">
                  {!image?.front && (
                    <div className="form-group mt-0">
                      <label className="profile_details_text">
                        {t("Carica il fronte")}
                      </label>
                      <div className="drag-text">
                        <input
                          className="file-upload-input"
                          type="file"
                          accept="image/*"
                          name="imageforFront"
                          id="file-upload-front"
                          onChange={handleFileChange}
                        />
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={20}
                          height={22}
                          viewBox="0 0 20 22"
                          fill="none"
                        >
                          <path
                            d="M2 14.2044V17.8925C2 18.4514 2.21071 18.9875 2.58579 19.3827C2.96086 19.778 3.46957 20 4 20H16C16.5304 20 17.0391 19.778 17.4142 19.3827C17.7893 18.9875 18 18.4514 18 17.8925V14.2044M10.0007 13.9425L10.0007 2M10.0007 2L5.42931 6.56318M10.0007 2L14.5722 6.56318"
                            stroke="#435E9B"
                            strokeWidth={4}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    </div>
                  )}
                  {image?.front && (
                    <div className="form-group mt-0 id-upoload-two">
                      <div className="drag-text">
                        {isImage?.front && image?.front ? (
                          <div>
                            <h6>
                              {t("Fronte del documento caricato")}{" "}
                              <b>{t("correttamente")}</b>
                            </h6>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={40}
                              height={40}
                              viewBox="0 0 40 40"
                              fill="none"
                            >
                              <rect
                                width={40}
                                height={40}
                                rx={4}
                                fill="#B4E4BA"
                              />
                              <path
                                d="M28 14L16.0675 26L12 21.9095"
                                stroke="#35A347"
                                strokeWidth={4}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </div>
                        ) : (
                          <div>
                            <h6>{t("Caricamento fallito")}</h6>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={40}
                              height={40}
                              viewBox="0 0 40 40"
                              fill="none"
                            >
                              <rect
                                width={40}
                                height={40}
                                rx={4}
                                fill="#FEB8C2"
                              />
                              <path
                                d="M28 12L12 28M28 28L12 12"
                                stroke="#ED0000"
                                strokeWidth={4}
                                strokeLinecap="round"
                              />
                            </svg>
                          </div>
                        )}
                        <label className="file-upload-labels">
                          <span className="file-upload-text">
                            {t("Carica di nuovo")}
                          </span>
                          <input
                            type="file"
                            accept="image/*"
                            name="imageforFront"
                            className="file-upload-input"
                            id="file-upload-front"
                            onChange={handleFileChange}
                            placeholder="front"
                          />
                        </label>
                      </div>
                    </div>
                  )}
                </div>

                <div className="image-upload-wrap">
                  {!image?.back && (
                    <div className="form-group mt-0">
                      <label className="profile_details_text">
                        {t("Carica il retro")}
                      </label>
                      <div className="drag-text">
                        <input
                          className="file-upload-input"
                          type="file"
                          accept="image/*"
                          name="imageforBack"
                          id="file-upload-back"
                          onChange={handleFileChange}
                        />
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={20}
                          height={22}
                          viewBox="0 0 20 22"
                          fill="none"
                        >
                          <path
                            d="M2 14.2044V17.8925C2 18.4514 2.21071 18.9875 2.58579 19.3827C2.96086 19.778 3.46957 20 4 20H16C16.5304 20 17.0391 19.778 17.4142 19.3827C17.7893 18.9875 18 18.4514 18 17.8925V14.2044M10.0007 13.9425L10.0007 2M10.0007 2L5.42931 6.56318M10.0007 2L14.5722 6.56318"
                            stroke="#435E9B"
                            strokeWidth={4}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    </div>
                  )}
                  {image?.back && (
                    <div className="form-group mt-0 id-upoload-two">
                      <div className="drag-text">
                        {isImage?.back && image?.back ? (
                          <div>
                            <h6>
                              {t("Fronte del documento caricato")}{" "}
                              <b>{t("correttamente")}</b>
                            </h6>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={40}
                              height={40}
                              viewBox="0 0 40 40"
                              fill="none"
                            >
                              <rect
                                width={40}
                                height={40}
                                rx={4}
                                fill="#B4E4BA"
                              />
                              <path
                                d="M28 14L16.0675 26L12 21.9095"
                                stroke="#35A347"
                                strokeWidth={4}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </div>
                        ) : (
                          <div>
                            <h6>{t("Caricamento fallito")}</h6>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={40}
                              height={40}
                              viewBox="0 0 40 40"
                              fill="none"
                            >
                              <rect
                                width={40}
                                height={40}
                                rx={4}
                                fill="#FEB8C2"
                              />
                              <path
                                d="M28 12L12 28M28 28L12 12"
                                stroke="#ED0000"
                                strokeWidth={4}
                                strokeLinecap="round"
                              />
                            </svg>
                          </div>
                        )}

                        <label className="file-upload-labels">
                          <span className="file-upload-text">
                            {t("Carica di nuovo")}
                          </span>
                          <input
                            id="file-upload-back"
                            type="file"
                            accept="image/*"
                            name="imageforBack"
                            className="file-upload-input"
                            onChange={handleFileChange}
                            placeholder="back"
                          />
                        </label>
                      </div>
                    </div>
                  )}
                </div>
                {isImage?.front === true && isImage?.back === true && (
                  <div className="btn-section text-center">
                    <button className="solid-btn" onClick={handleProcedi}>
                      {t("Procedi")}
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(UploadMotherId);
  