import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      Ciao: "Hi",
      Hai: "You have",
      questionario: "questionnaire",
      da: "from",
      "Hai {{count}} questionario da compilare":
        "You have {{count}} questionnaire to fill out",
      compilare: "to fill out",
      Compila: "Fill in",
      "Il progetto": "The project",
      "I tuoi questionari": "your questionnaires",
      "I tuoi dati": "your data",
      Contatti: "Contacts",
      Salve: "Hello (formal)",
      Sì: "Yes",
      No: "No",
      Grazie: "Thank you",
      "Per favore": "Please",
      "Grazie mille": "Thank you so much",
      Prego: "You’re welcome",
      Buongiorno: "Good day/morning",
      Buonasera: "Good evening",
      Buonanotte: "Goodnight (used when you’re actually going to bed)",
      "Salute!": "Cheers!",
      Bella: "Beautiful",
      Buona: "Good",
      Bene: " Well",
      Amica: "Friend",
      "Il conto": "The check/bill",
      "Via/Strada": "Street",
      Vino: "Wine",
      Birra: "Beer",
      Aperitivo: "Pre-dinner drinks & snacks (Italian version of happy hour)",
      Stazione: "Train station",
      Treno: "Train",
      Binario: "Train platform",
      Autobus: "Bus",
      Fermata: "Stop (bus/train)",
      Bagagli: "Luggage",
      Biglietto: "Ticket",
      Spiaggia: "Beach",
      Ristorante: "Restaurant",
      Bagno: "Bathroom",
      Albergo: "Hotel",
      "Come ti chiami?": "What’s your name?",
      "Mi chiamo": "My name is",
      "Va bene": "OK",
      Permesso: "Excuse me (when passing through a crowd or on transportation)",
      Scusa: "Excuse me",
      "Mi dispiace": "I’m sorry",
      "Come stai?": "How are you?",
      "Come va?": "How’s it going?",
      "Tutto bene": "All is well",
      "Non lo so": "I don’t know",
      "Dov’è il bagno?": "Where is the bathroom?",
      "Parli Inglese?": "Do you speak English?",
      "Non parlo Italiano": "I don’t speak Italian",
      "Quanto costa": "How much does it cost?",
      "Sono di": "I am from",
      "Mi piace": "I like",
      "Non mi piace": "I don’t like",
      Andiamo: "Let’s go",
      uno: "one",
      due: "two",
      tre: "three",
      quattro: "four",
      cinque: "five",
      rosso: "red",
      blu: "blue",
      verde: "green",
      giallo: "yellow",
      lunedì: "monday",
      martedì: "tuesday",
      mercoledì: "wednesday",
      giovedì: "thursday",
      venerdì: "friday",
      sabato: "saturday",
      domenica: "sunday",
      cane: "dog",
      gatto: "cat",
      uccello: "bird",
      pesce: "fish",
      cavallo: "horse",
      mela: "apple",
      banana: "banana",
      arancia: "orange",
      uva: "grape",
      fragola: "strawberry",
      sole: "sun",
      pioggia: "rain",
      neve: "snow",
      vento: "wind",
      nuvola: "cloud",
      pane: "bread",
      formaggio: "cheese",
      pasta: "pasta",
      pizza: "pizza",
      insalata: "salad",
      città: "city",
      spiaggia: "beach",
      montagna: "mountain",
      parco: "park",
      ristorante: "restaurant",
      "Parzialmente compilato": "Partially filled",
      "Da compilare": "To be filled",
      Completato: "Completed",
      "Titolo del modulo": "Form Title",
      "Nome della sezione": "Section Name",
      Invia: "Submit",
      Prossima: "Next",
      "Complimenti!": "Compliments!",
      "Hai completato il questionario.":
        "You have completed the questionnaire.",
      "Torna alla home": "Back To Home",
      "Torna alla lista": "Back To List",
      Login: "Login",
      "Prima di accedere è necessario leggere attentamente eaccettare i “Termini e condizioni” e la “informativa privacy”.":
        "Before accessing it is necessary to carefully read and accept the 'Terms and conditions' and the 'privacy policy'.",
      "Informativa privacy": "Privacy Policy",
      "Termini e condizioni": "Terms and Conditions",
      "Hai già compilato tutti i questionari.":
        "You have already filled out all the questionnaires.",
      Disconnettersi: "Log out",
      "e trattamento dei dati": "and data processing",
      "Log in / Sign in": "Accedi / Registrazione",
      Accedi: "Login",
      Registrati: "Register",
      "Il modulo è già compilato.": "Form is already filled.",
      "Per favore riempi il": "Please fill the",
      "Questo modulo è bloccato": "This form is locked",
      "Attendere prego": "Please Wait",
      "Accesso effettuato con successo.": "Logged In Successfully.",
      "Dati personali": "Personal data",
      Modifica: "Edit",
      Nome: "Name",
      Cogome: "SurName",
      "Data di nascita": "Date Of Birth",
      "Luogo di nascita": "Date Of Place",
      Paese: "Village",
      "Cod. Fiscale": "Code. Tax",
      "Tel. Cellulare": "Mobile phone",
      "Altro recapito telefonico": "Other telephone number",
      Residenza: "Residence",
      "Via(Piazza)": "Piazza way",
      Civico: "Civic",
      Comune: "Common",
      Provincia: "Province",
      Città: "City",
      "Comune di": "Municipality of",
      "Hai altre domande?": "Do you have other questions?",
      Contattaci: "Contact us",
      "Chiamaci al": "Call us at",
      "Scrivici una mail a": "Write us an email at",
      "Oppure scrivici su whatsapp": "Or write to us on whatsapp",
      "Il questionario è stato salvato correttamente.":
        "The questionnaire was saved successfully.",
      "Torna a compilarlo quando vuoi.":
        "Come back to fill it out whenever you want.",
      "Salva ed esci": "Save and Exit",
      "Questo modulo è scaduto": "This form is expire",
      Bloccato: "Blocked",
      "Un questionario sta per scadere": "A questionnaire is about to expire",
      "Corri a compilarlo": "Hurry and fill it out",
      Procedi: "Proceed",
      "Account confermato": "Account confirmed",
      "Numero di telefono": "Telephone number",
      "Inserendo il tuo numero di cellulare riceverai un SMS con uncodice a 6 cifre per verificare il tuo account.":
        "By entering your mobile number you will receive an SMS with a 6-digit code to verify your account.",
      "Stai iniziando il processo di registrazione. Assicurati di avere a portata i tuoi dati.":
        "You are starting the registration process. Make sure you have your data handy.",
      Cognome: "Surname",
      "Codice Fiscale": "Tax ID code",
      Email: "Email",
      "Numero di cellulare": "Cellphone number",
      "Documento identità (Carta di Identità, Patente di Guida, Passaporto)":
        "Identity document (Identity Card, Driving License, Passport)",
      "Ottimo!": "Optimal!",
      "La registrazione è quasi completa. Ti abbiamo inviato una mail da cui confermare il tuo account.":
        "Registration is almost complete. We have sent you an email to confirm your account.",
      "Abbiamo quasi finito!": "We're almost done!",
      "Nelle prossime schermate dovrai caricare la foto fronte e registrazione del tuo documento di identita.":
        "In the next screens you will have to upload the front and back photo of your identity document.",
      "Nome è obbligatorio": "Name is required",
      "Cognome è obbligatorio": "Surname is required",
      "Codice Fiscale è obbligatorio": "Tax ID code is required",
      "Indirizzo email non valido": "Invalid email address",
      "Email è obbligatoria": "Email is required",
      "Inserisci il codice a 6 cifre ricevuto via SMS":
        "Enter the 6-digit code received via SMS",
      "Verifica account": "Verify account",
      "Inserisci il tuo numero di telefono": "Enter your phone number",
      "Prima di accedere è necessario leggere attentamente e accettare i “Termini e condizioni” e la “informativa privacy”.":
        "Before accessing it is necessary to carefully read and accept the “Terms and conditions” and “the privacy policy“.",
      "Quale documento stai caricando?": "What document are you uploading?",
      "Carica il retro": "Load the back",
      "Carica il fronte": "Load the front",
      "Carica di nuovo": "Upload again",
      "Caricamento fallito": "Loading failed",
      "Fronte del documento caricato": "Front of document loaded",
      correttamente: "successfully",
      "*Campo obbligatorio": "*Required field",
      "Inserisci di nuovo il numero di telefono":
        "Enter your phone number again",
      "Qualcosa è andato storto. Inserisci di nuovo il codice o inserisci di nuovo il numero di telefono.":
        "Something went wrong. Enter the code again or enter your phone number again.",
      "Scegli quale documento caricare e fai l’upload sia del fronte che del retro del documento. Questo verrà poi controllato dagli Admin in seguito.":
        "Choose which document to upload and upload both the front and back of the document. This will then be checked by the Admins later.",
      "AGGIUNGERE SPECIFICHE DEI DOC CHE POSSONO ESSERE CARICATI (PESO / FORMATI)":
        "ADD SPECIFICATIONS OF THE DOC THAT CAN BE LOADED (WEIGHT / SIZES)",
      "Documento di Identita": "Document of Identity",
      Identità: "Identity ID",
      Passaporto: "Passport",
      "Patente di guida": "Driving License",
      "Parola d'ordine": "Password",
      "Conferma password": "Confirm Password",
      "*Le password non corrispondono": "*Passwords do not match",
      "La registrazione è completa!": "Registration is complete!",
      "Inserisci i documenti del padre": "Enter the father's documents",
      "Documento di identità del papà": "Document of the father's identity",
      "Non hai un account? Registrati.": "Don't have an account? Sign in.",
      "Adesso puoi accedere ai tuoi questionari. Se vuoi, puoi anche caricare i documenti del padre del bambino.":
        "You can now access your questionnaires. If you want, you can also upload the child's father's documents.",
      "Carica i documenti": "Upload documents",
      "Procedi ai questionari": "Proceed to the questionnaires",
      "Deve contenere 16 caratteri": "Must be 16 characters",
      "Imposta password": "Set Password",
      "Ripeti password": "Retype password",
      "Questionario in caricamento": "Questionnaire loading",
      "Password dimenticata": "Forget Password",
    },
  },
  it: {
    translation: {
      Hi: "Ciao",
      "You have": "Hai",
      questionnaire: "questionario",
      from: "da",
      "You have {{count}} questionnaire to fill out":
        "Hai {{count}} questionario da compilare",
      "to fill out": "compilare",
      "Fill in": "Compila",
      project: "progetto",
      "your questionnaires": "tuoi questionari",
      "your data": "tuoi dati",
      "Contact us": "Contattaci",
      "Hello (formal)": "Salve",
      Yes: "Sì",
      No: "No",
      "Thank you": "Grazie",
      Please: "Per favore",
      "Thank you so much": "Grazie mille",
      "You’re welcome": "Prego",
      "Good day/morning": "Buongiorno",
      "Good evening": "Buonasera",
      "Goodnight (used when you’re actually going to bed)": "Buonanotte",
      "Cheers!": "Salute!",
      Beautiful: "Bella",
      Good: "Buona",
      Well: "Bene",
      Friend: "Amica",
      "The check/bill": "Il conto",
      Street: "Via/Strada",
      Wine: "Vino",
      Beer: "Birra",
      "Pre-dinner drinks & snacks (Italian version of happy hour)": "Aperitivo",
      "Train station": "Stazione",
      Train: "Treno",
      "Train platform": "Binario",
      Bus: "Autobus",
      "Stop (bus/train)": "Fermata",
      Luggage: "Bagagli",
      Ticket: "Biglietto",
      Beach: "Spiaggia",
      Restaurant: "Ristorante",
      Bathroom: "Bagno",
      Hotel: "Albergo",
      "What’s your name?": "Come ti chiami?",
      "My name is": "Mi chiamo",
      OK: "Va bene",
      "Excuse me (when passing through a crowd or on transportation)":
        "Permesso",
      "Excuse me": "Scusa",
      "I’m sorry": "Mi dispiace",
      "How are you?": "Come stai?",
      "How’s it going?": "Come va?",
      "All is well": "Tutto bene",
      "I don’t know": "Non lo so",
      "Where is the bathroom?": "Dov’è il bagno?",
      "Do you speak English?": "Parli Inglese?",
      "I don’t speak Italian": "Non parlo Italiano",
      "How much does it cost?": "Quanto costa?",
      "I am from": "Sono di",
      "I like": "Mi piace",
      "I don’t like": "Non mi piace",
      "Let’s go": "Andiamo",
      one: "uno",
      two: "due",
      three: "tre",
      four: "quattro",
      five: "cinque",
      red: "rosso",
      blue: "blu",
      green: "verde",
      yellow: "giallo",
      monday: "lunedì",
      tuesday: "martedì",
      wednesday: "mercoledì",
      thursday: "giovedì",
      friday: "venerdì",
      saturday: "sabato",
      sunday: "domenica",
      dog: "cane",
      cat: "gatto",
      bird: "uccello",
      fish: "pesce",
      horse: "cavallo",
      apple: "mela",
      banana: "banana",
      orange: "arancia",
      grape: "uva",
      strawberry: "fragola",
      sun: "sole",
      rain: "pioggia",
      snow: "neve",
      wind: "vento",
      cloud: "nuvola",
      bread: "pane",
      cheese: "formaggio",
      pasta: "pasta",
      pizza: "pizza",
      salad: "insalata",
      city: "città",
      beach: "spiaggia",
      mountain: "montagna",
      park: "parco",
      restaurant: "ristorante",
      "Partially filled": "Parzialmente compilato",
      "To be filled": "Da compilare",
      Completed: "Completato",
      "Form Title": "Titolo del modulo",
      "Section Name": "Nome della sezione",
      Submit: "Invia",
      Next: "Prossima",
      "Compliments!": "Complimenti!",
      "You have completed the questionnaire.":
        "Hai completato il questionario.",
      "Back To Home": "Torna alla home",
      "Back To List": "Torna alla lista",
      "Before accessing it is necessary to carefully read and accept the 'Terms and conditions' and the 'privacy policy'.":
        "Prima di accedere è necessario leggere attentamente eaccettare i “Termini e condizioni” e la “informativa privacy”.",
      "Privacy Policy": "Informativa privacy",
      "Terms and Conditions": "Termini e condizioni",
      "You have already filled out all the questionnaires.":
        "Hai già compilato tutti i questionari.",
      "Log out": "Log Out",
      "and data processing": "e trattamento dei dati",
      Login: "Accedi",
      Register: "Registrati",
      "Form is already filled.": "Il modulo è già compilato.",
      "Please fill the": "Per favore riempi il",
      "This form is locked": "Questo modulo è bloccato",
      "Please Wait": "Attendere prego",
      "Logged In Successfully.": "Accesso effettuato con successo.",
      "Your Data": "tuoi dati",
      "Personal data": "Dati personali",
      Edit: "Modifica",
      Name: "Nome",
      SurName: "Cogome",
      "Date Of Birth": "Data di nascita",
      "Date Of Place": "Luogo di nascita",
      Village: "Paese",
      "Code. Tax": "Cod. Fiscale",
      "Mobile phone": "Tel. Cellulare",
      "Other telephone number": "Altro recapito telefonico",
      Residence: "Residenza",
      "Piazza way": "Via(Piazza)",
      Civic: "Civico",
      Common: "Comune",
      Province: "Provincia",
      City: "Città",
      "Municipality of": "Comune di",
      "Do you have other questions?": "Hai altre domande?",
      "Call us at": "Chiamaci al",
      "Write us an email at": "Scrivici una mail a",
      "Or write to us on whatsapp": "Oppure scrivici su whatsapp",
      "The questionnaire was saved successfully.":
        "Il questionario è stato salvato correttamente.",
      "Come back to fill it out whenever you want.":
        "Torna a compilarlo quando vuoi.",
      "Save and Exit": "Salva ed esci",
      "This form is expire": "Questo modulo è scaduto",
      "A questionnaire is about to expire": "Un questionario sta per scadere",
      Blocked: "Bloccato",
      "Hurry and fill it out": "Corri a compilarlo",
      Proceed: "Procedi",
      "Account confirmed": "Account confermato",
      "Telephone number": "Numero di telefono",
      "By entering your mobile number you will receive an SMS with a 6-digit code to verify your account.":
        "Inserendo il tuo numero di cellulare riceverai un SMS con uncodice a 6 cifre per verificare il tuo account.",
      "You are starting the registration process. Make sure you have your data handy.":
        "Stai iniziando il processo di registrazione. Assicurati di avere a portata i tuoi dati.",
      Surname: "Cognome",
      "Tax ID code": "Codice Fiscale",
      Email: "Email",
      "Cellphone number": "Numero di cellulare",
      "Identity document (Identity Card, Driving License, Passport)":
        "Documento identità (Carta di Identità, Patente di Guida, Passaporto)",
      "Optimal!": "Ottimo!",
      "Registration is almost complete. We have sent you an email to confirm your account.":
        "La registrazione è quasi completa. Ti abbiamo inviato una mail da cui confermare il tuo account.",
      "We're almost done!": "Abbiamo quasi finito!",
      "In the next screens you will have to upload the front and back photo of your identity document.":
        "Nelle prossime schermate dovrai caricare la foto fronte e registrazione del tuo documento di identita.",
      "Name is required": "Nome è obbligatorio",
      "Surname is required": "Cognome è obbligatorio",
      "Tax ID code is required": "Codice Fiscale è obbligatorio",
      "Invalid email address": "Indirizzo email non valido",
      "Email is required": "Email è obbligatoria",
      "Enter the 6-digit code received via SMS":
        "Inserisci il codice a 6 cifre ricevuto via SMS",
      "Verify account": "Verifica account",
      "Enter your phone number": "Inserisci il tuo numero di telefono",
      "Before accessing it is necessary to carefully read and accept the “Terms and conditions” and “the privacy policy“.":
        "Prima di accedere è necessario leggere attentamente e accettare i “Termini e condizioni” e la “informativa privacy”.",
      "What document are you uploading?": "Quale documento stai caricando?",
      "Load the back": "Carica il retro",
      "Load the front": "Carica il fronte",
      "Upload again": "Carica di nuovo",
      "Loading failed": "Caricamento fallito",
      "Front of document loaded successfully":
        "Fronte del documento caricato correttamente",
      "*Required field": "*Campo obbligatorio",
      "Enter your phone number again":
        "Inserisci di nuovo il numero di telefono",
      "Something went wrong. Enter the code again or enter your phone number again.":
        "Qualcosa è andato storto. Inserisci di nuovo il codice o inserisci di nuovo il numero di telefono.",
      "Choose which document to upload and upload both the front and back of the document. This will then be checked by the Admins later.":
        "Scegli quale documento caricare e fai l’upload sia del fronte che del retro del documento. Questo verrà poi controllato dagli Admin in seguito.",
      "ADD SPECIFICATIONS OF THE DOC THAT CAN BE LOADED (WEIGHT / SIZES)":
        "AGGIUNGERE SPECIFICHE DEI DOC CHE POSSONO ESSERE CARICATI (PESO / FORMATI)",
      "Document of Identity": "Documento di Identita",
      "Identity ID": "Identità",
      Passport: "Passaporto",
      "Driving License": "Patente di guida",
      Password: "Parola d'ordine",
      "Confirm Password": "Conferma password",
      "*Passwords do not match": "*Le password non corrispondono",
      "Registration is complete!": "La registrazione è completa!",
      "Enter the father's documents": "Inserisci i documenti del padre",
      "Document of the father's identity": "Documento di identità del papà",
      "Accedi / Registrazione": "Log in / Sign in",
      "Don't have an account? Sign in.": "Non hai un account? Registrati.",
      "You can now access your questionnaires. If you want, you can also upload the child's father's documents.":
        "Adesso puoi accedere ai tuoi questionari. Se vuoi, puoi anche caricare i documenti del padre del bambino.",
      "Upload documents": "Carica i documenti",
      "Proceed to the questionnaires": "Procedi ai questionari",
      "Must be 16 characters": "Deve contenere 16 caratteri",
      "Questionnaire loading": "Questionario in caricamento",
      "Forget Password": "Password dimenticata",
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "it",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
