import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { ResetPassword } from "../../../redux/auth/authAction";
import { useNavigate, useParams } from "react-router-dom";
import idupload from "../../../assets/images/idupload.svg";

const SignIn = ({ t }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let { id } = useParams();

  const [state, setState] = useState({
    password: "",
    confirmPassword: "",
  });

  const [errorInput, setErrorInput] = useState({
    password: "",
    confirmPassword: "",
  });

  const [showPassword, setShowPassword] = useState({
    password: false,
    confirmPassword: false,
  });

  const handleChange = (e) => {
    setState((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
    setErrorInput((prevErrors) => ({
      ...prevErrors,
      [e.target.name]: "",
    }));
  };

  const togglePasswordVisibility = (field) => {
    setShowPassword((prevShowPassword) => ({
      ...prevShowPassword,
      [field]: !prevShowPassword[field],
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const payload = {
      userId: id,
      password: state?.password,
    };
    // Check if passwords match
    if (state.password !== state.confirmPassword) {
      setErrorInput((prevErrors) => ({
        ...prevErrors,
        confirmPassword: t("Le password non corrispondono"),
      }));
    } else if (state.password === "" || state.confirmPassword === "") {
      // Check if any field is empty
      setErrorInput((prevErrors) => ({
        ...prevErrors,
        password: state.password === "" ? t("*Campo obbligatorio") : "",
        confirmPassword:
          state.confirmPassword === "" ? t("*Campo obbligatorio") : "",
      }));
    } else {
      // Reset errors
      setErrorInput({
        password: "",
        confirmPassword: "",
      });
      dispatch(
        ResetPassword({
          payload,
          callBack: () => {
            navigate("/auth/signIn");
            // console.log("hello callback")
          },
          onError: (error) => {
            console.error("Password reset failed", error);
          },
        })
      );
    }
  };

  return (
    <div className="register-detail signin-section">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-12">
            <div className="text-center register_title_section">
              <div className="img">
                <img
                  style={{ width: "170px", height: "170px" }}
                  src={idupload}
                  alt="work-on-bicycle"
                />
              </div>
              <div className="meditation-yaga-section">
                <h2>{t("Imposta una nuova password")}</h2>
              </div>
            </div>
          </div>
          <div className="col-lg-12">
            <form method="POST">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 col-12 register-sections">
                  <div
                    className={`form-group register-field login-field password-input-container ${
                      errorInput.password ? "error-content" : ""
                    }`}
                  >
                    <div className="error">{t(errorInput?.password)}</div>
                    <label>{t("Inserisci password")}</label>
                    <input
                      type={showPassword?.password ? "text" : "password"}
                      name="password"
                      className="form-control"
                      value={state?.password || ""}
                      onChange={handleChange}
                    />

                    <i
                      className={
                        !showPassword?.password
                          ? "ri-eye-off-line"
                          : "ri-eye-line"
                      }
                      onClick={() => togglePasswordVisibility("password")}
                      style={{
                        position: "absolute",
                        right: "30px",
                        top: "66%",
                        transform: "translateY(-50%)",
                        cursor: "pointer",
                        fontSize: "20px",
                      }}
                    />
                  </div>

                  <div
                    className={`form-group register-field login-field password-input-container ${
                      errorInput.confirmPassword ? "error-content" : ""
                    }`}
                  >
                    <div className="error">
                      {t(errorInput?.confirmPassword)}
                    </div>
                    <label>Ripeti password</label>
                    <input
                      type={showPassword?.confirmPassword ? "text" : "password"}
                      name="confirmPassword"
                      className="form-control"
                      value={state?.confirmPassword || ""}
                      onChange={handleChange}
                    />

                    <i
                      className={
                        !showPassword?.confirmPassword
                          ? "ri-eye-off-line"
                          : "ri-eye-line"
                      }
                      onClick={() =>
                        togglePasswordVisibility("confirmPassword")
                      }
                      style={{
                        position: "absolute",
                        right: "30px",
                        top: "66%",
                        transform: "translateY(-50%)",
                        cursor: "pointer",
                        fontSize: "20px",
                      }}
                    />
                  </div>
                </div>

                <div className="btn-section text-center">
                  <button className="solid-btn" onClick={handleSubmit}>
                    {t("Conferma")}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(SignIn);
