import { call, put, takeLatest } from 'redux-saga/effects'
import {
  GET_PRIVACY_AND_TERMS_LIST,
} from '../actionType'
import {
  getPrivacyAndTermsFailed,
  getPrivacyAndTermsSuccess,
} from './privacyAndTermsAction'
import api from '../../utils/api'
import { endPoints } from '../../utils/ennpoints'

function* getPrivacyAndTerms() {
  try {
    const response = yield call(api.get, endPoints.GET_PRIVACY_AND_TERMS_LIST)
    if (response) {
      yield put(getPrivacyAndTermsSuccess(response?.data))
    }
  } catch (error) {
    if (error) {
      yield put(getPrivacyAndTermsFailed())
    }
  }
}

export function* privacyAndTermsSaga() {
  yield takeLatest(GET_PRIVACY_AND_TERMS_LIST, getPrivacyAndTerms)
}
