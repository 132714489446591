import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getQuestionariesList } from "../../../redux/questionaries/questionariesAction";
import { useNavigate } from "react-router-dom";
import { withTranslation } from "react-i18next";
import moment from "moment";
import CommonToaster from "../../../utils/Toaster";
import toast from "react-hot-toast";
import { getTabName } from "../../../redux/navigatePath/navigateAction";

const QuestionsList = ({ t }) => {
  // let pregenancyMonth = 0;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const questionariesList = useSelector(
    (state) => state?.QuestionariesReducer?.questionariesList
  );

  useEffect(() => {
    dispatch(getQuestionariesList({ pageNo: 0, limit: 10 }));
    dispatch(
      getTabName({
        tabName: "I tuoi questionari",
        tabAction: "/questions-list",
      })
    );
    localStorage.setItem("index", 0);
  }, [dispatch]);
  const getFirstFillUp = questionariesList?.data?.filter((item) => {
    return (
      item?.masteranswers?.status === "Partially Completed" &&
      item?.sortTitle === "QB1"
    );
  });
  return (
    <>
      {/* <!--body section--> */}
      <div className="question_summary py-50">
        <div className="container">
          <div className="main_question_box">
            <div className="row">
              {questionariesList?.data
                ?.sort((a, b) => a?.timeline - b?.timeline)
                .map((questions, index, array) =>
                  questions.sectionNo !== 0 ? (
                    <>
                      {(() => {
                        const currentDate =
                          // "17/09/2024";
                          moment().format("DD/MM/YYYY");
                        const date1 = moment(currentDate, "DD/MM/YYYY");
                        const currentFormDate = moment(
                          questions?.userData?.createdAt
                        )
                          .add(210, "days")
                          .format("DD/MM/YYYY");
                        const date2 = moment(currentFormDate, "DD/MM/YYYY");
                        const getTheBaseLineOneExp = date1.isSameOrAfter(date2);
                        const parsedDateDPP = moment(
                          questions?.childData?.data_presunt,
                          "DD/MM/YYYY"
                        );
                        const DPP = moment(parsedDateDPP, "DD/MM/YYYY");
                        const currentFormDate30Day = moment()
                          .add(30, "days")
                          .format("DD/MM/YYYY");
                        const getDate1 = moment(
                          currentFormDate30Day,
                          "DD/MM/YYYY"
                        );
                        const getTheBaseLineTwoUnLock =
                          getDate1.isSameOrBefore(DPP);

                        const parsedDate = moment(
                          questions?.childData?.data_presunt,
                          "DD/MM/YYYY"
                        );
                        const newDate = parsedDate.add(210, "days");
                        const newDateString = newDate.format("DD/MM/YYYY");
                        const GetDate2 = moment(newDateString, "DD/MM/YYYY");
                        const getTheBaseLineTwoExp =
                          date1.isSameOrAfter(GetDate2);

                        return (
                          <div
                            className="col-xl-4 col-md-6"
                            style={
                              (questions?.sortTitle === "QB2" &&
                                getTheBaseLineTwoUnLock &&
                                getTheBaseLineTwoExp === false &&
                                questions?.masteranswers === null) ||
                              (questions?.childData?.data_presunt ===
                                undefined &&
                                questions?.sortTitle === "QB2") ||
                              (getFirstFillUp.length !== 0 &&
                                questions?.sortTitle === "QB2")
                                ? // ? {}
                                  { display: "none" }
                                : {}
                            }
                            key={questions?._id}
                          >
                            <div
                              className="box_question"
                              onClick={() => {
                                if (
                                  (questions?.sortTitle === "QB2" &&
                                    getTheBaseLineTwoUnLock &&
                                    getTheBaseLineTwoExp === false &&
                                    questions?.masteranswers === null) ||
                                  (questions?.childData?.data_presunt ===
                                    undefined &&
                                    questions?.sortTitle === "QB2")
                                ) {
                                  const toastId = toast.loading(
                                    t("Attendere prego")
                                  );
                                  CommonToaster({
                                    toastId,
                                    toastType: "error",
                                    message: t("Bloccato."),
                                  });
                                }
                                if (
                                  (questions?.sortTitle === "QB1" &&
                                    getTheBaseLineOneExp) ||
                                  (questions?.sortTitle === "QB2" &&
                                    getTheBaseLineTwoExp)
                                ) {
                                  const toastId = toast.loading(
                                    t("Attendere prego")
                                  );
                                  CommonToaster({
                                    toastId,
                                    toastType: "error",
                                    message: t("Scaduto"),
                                  });
                                }
                                if (
                                  questions?.masteranswers?.status ===
                                    "Completed" &&
                                  ((questions?.sortTitle === "QB1" &&
                                    getTheBaseLineOneExp === false) ||
                                    (questions?.sortTitle === "QB2" &&
                                      getTheBaseLineTwoUnLock === false &&
                                      getTheBaseLineOneExp === false))
                                ) {
                                  const toastId = toast.loading(
                                    t("Attendere prego")
                                  );
                                  CommonToaster({
                                    toastId,
                                    toastType: "success",
                                    message: t("Completato"),
                                  });
                                }
                                if (
                                  questions?.masteranswers?.status ===
                                    "Partially Completed" &&
                                  ((questions?.sortTitle === "QB1" &&
                                    getTheBaseLineOneExp === false) ||
                                    (questions?.sortTitle === "QB2" &&
                                      getTheBaseLineTwoUnLock === false &&
                                      getTheBaseLineOneExp === false))
                                ) {
                                  navigate(`/form-intro/${questions?._id}`, {
                                    state: {
                                      id: questions?._id,
                                    },
                                  });
                                }
                                if (
                                  (questions?.masteranswers === null &&
                                    questions?.sortTitle === "QB1" &&
                                    getTheBaseLineOneExp === false) ||
                                  (questions?.sortTitle === "QB2" &&
                                    getTheBaseLineTwoUnLock === false &&
                                    questions?.masteranswers === null &&
                                    getTheBaseLineTwoExp === false)
                                ) {
                                  navigate(`/form-intro/${questions?._id}`, {
                                    state: {
                                      id: questions?._id,
                                    },
                                  });
                                }
                              }}
                            >
                              <div className="box_one">
                                <div
                                  style={
                                    (questions?.sortTitle === "QB2" &&
                                      getTheBaseLineTwoUnLock &&
                                      getTheBaseLineTwoExp === false &&
                                      questions?.masteranswers === null) ||
                                    (questions?.sortTitle === "QB1" &&
                                      getTheBaseLineOneExp) ||
                                    (questions?.sortTitle === "QB2" &&
                                      getTheBaseLineTwoExp)
                                      ? { opacity: 0.4 }
                                      : { opacity: 1 }
                                  }
                                  className={`circle-img
                                    
                                    ${
                                      (questions?.sortTitle === "QB2" &&
                                        getTheBaseLineTwoUnLock &&
                                        getTheBaseLineTwoExp === false &&
                                        questions?.masteranswers === null) ||
                                      (questions?.childData?.data_presunt ===
                                        undefined &&
                                        questions?.sortTitle === "QB2")
                                        ? "gray"
                                        : (questions?.masteranswers === null &&
                                            questions?.sortTitle === "QB1" &&
                                            getTheBaseLineOneExp === false) ||
                                          (questions?.sortTitle === "QB2" &&
                                            getTheBaseLineTwoUnLock === false &&
                                            questions?.masteranswers === null &&
                                            getTheBaseLineTwoExp === false)
                                        ? "blue"
                                        : (questions?.sortTitle === "QB1" &&
                                            getTheBaseLineOneExp) ||
                                          (questions?.sortTitle === "QB2" &&
                                            getTheBaseLineTwoExp)
                                        ? "danger"
                                        : questions?.masteranswers?.status ===
                                            "Completed" &&
                                          ((questions?.sortTitle === "QB1" &&
                                            getTheBaseLineOneExp === false) ||
                                            (questions?.sortTitle === "QB2" &&
                                              getTheBaseLineTwoUnLock ===
                                                false &&
                                              getTheBaseLineOneExp === false))
                                        ? null
                                        : questions?.masteranswers?.status ===
                                            "Partially Completed" &&
                                          ((questions?.sortTitle === "QB1" &&
                                            getTheBaseLineOneExp === false) ||
                                            (questions?.sortTitle === "QB2" &&
                                              getTheBaseLineTwoUnLock ===
                                                false &&
                                              getTheBaseLineOneExp === false))
                                        ? "yellow"
                                        : null
                                    }`}
                                >
                                  <img
                                    src={questions?.image}
                                    alt="sectionImg"
                                  />
                                </div>
                              </div>
                              <div
                                className="question_detail"
                                style={
                                  (questions?.sortTitle === "QB2" &&
                                    getTheBaseLineTwoUnLock &&
                                    getTheBaseLineTwoExp === false &&
                                    questions?.masteranswers === null) ||
                                  (questions?.sortTitle === "QB1" &&
                                    getTheBaseLineOneExp) ||
                                  (questions?.sortTitle === "QB2" &&
                                    getTheBaseLineTwoExp)
                                    ? { opacity: 0.4 }
                                    : { opacity: 1 }
                                }
                              >
                                <h3>{questions?.title}</h3>
                                {((questions?.sortTitle === "QB2" &&
                                  getTheBaseLineTwoUnLock &&
                                  getTheBaseLineTwoExp === false &&
                                  questions?.masteranswers === null) ||
                                  (questions?.childData?.data_presunt ===
                                    undefined &&
                                    questions?.sortTitle === "QB2")) && (
                                  <h4 className={"blockColour"}>
                                    {t("Bloccato")}
                                  </h4>
                                )}
                                {((questions?.masteranswers === null &&
                                  questions?.sortTitle === "QB1" &&
                                  getTheBaseLineOneExp === false) ||
                                  (questions?.sortTitle === "QB2" &&
                                    getTheBaseLineTwoUnLock === false &&
                                    questions?.masteranswers === null &&
                                    getTheBaseLineTwoExp === false)) && (
                                  <h4 className={"blue"}>
                                    {t("Da compilare")}
                                  </h4>
                                )}
                                {((questions?.sortTitle === "QB1" &&
                                  getTheBaseLineOneExp) ||
                                  (questions?.sortTitle === "QB2" &&
                                    getTheBaseLineTwoExp)) && (
                                  <h4 className={"expiredColour"}>
                                    {t("Questo modulo è scaduto")}
                                  </h4>
                                )}
                                {questions?.masteranswers?.status ===
                                  "Completed" &&
                                  ((questions?.sortTitle === "QB1" &&
                                    getTheBaseLineOneExp === false) ||
                                    (questions?.sortTitle === "QB2" &&
                                      getTheBaseLineTwoUnLock === false &&
                                      getTheBaseLineOneExp === false)) && (
                                    <h4 className={"successHead"}>
                                      {t("Completato")}
                                    </h4>
                                  )}
                                {questions?.masteranswers?.status ===
                                  "Partially Completed" &&
                                  ((questions?.sortTitle === "QB1" &&
                                    getTheBaseLineOneExp === false) ||
                                    (questions?.sortTitle === "QB2" &&
                                      getTheBaseLineTwoUnLock === false &&
                                      getTheBaseLineOneExp === false)) && (
                                    <h4 className={"yellow"}>
                                      {t("Parzialmente compilato")}
                                    </h4>
                                  )}
                              </div>
                              <div className="question_action">
                                <button>
                                  {/** lock */}
                                  {((questions?.sortTitle === "QB2" &&
                                    getTheBaseLineTwoUnLock &&
                                    getTheBaseLineTwoExp === false &&
                                    questions?.masteranswers === null) ||
                                    (questions?.childData?.data_presunt ===
                                      undefined &&
                                      questions?.sortTitle === "QB2")) && (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="40"
                                      height="40"
                                      viewBox="0 0 40 40"
                                      fill="none"
                                    >
                                      {/** white */}
                                      <rect
                                        width="40"
                                        height="40"
                                        rx="4"
                                        fill="white"
                                      />
                                      <path
                                        d="M14 16.1111V15.0952C14 11.7187 16.6762 9 20 9C23.3238 9 26 11.7187 26 15.0952V16.1111M14 16.1111C12.9 16.1111 12 17.0254 12 18.1429V28.3016C12 29.419 12.9 30.3333 14 30.3333H26C27.1 30.3333 28 29.419 28 28.3016V18.1429C28 17.0254 27.1 16.1111 26 16.1111M14 16.1111H26M20 24.3333V21.6667"
                                        stroke="#545454"
                                        strokeWidth="4"
                                        strokeLinecap="round"
                                      />
                                    </svg>
                                  )}
                                  {/** expried */}
                                  {((questions?.sortTitle === "QB1" &&
                                    getTheBaseLineOneExp) ||
                                    (questions?.sortTitle === "QB2" &&
                                      getTheBaseLineTwoExp)) && (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="40"
                                      height="40"
                                      viewBox="0 0 40 40"
                                      fill="none"
                                    >
                                      {/** Red */}
                                      <rect
                                        width="40"
                                        height="40"
                                        rx="4"
                                        fill="#FEB8C2"
                                      />
                                      <path
                                        d="M28 12L12 28M28 28L12 12"
                                        stroke="#ED0000"
                                        strokeWidth="4"
                                        strokeLinecap="round"
                                      />
                                    </svg>
                                  )}

                                  {/** blue */}
                                  {((questions?.masteranswers === null &&
                                    questions?.sortTitle === "QB1" &&
                                    getTheBaseLineOneExp === false) ||
                                    (questions?.sortTitle === "QB2" &&
                                      getTheBaseLineTwoUnLock === false &&
                                      questions?.masteranswers === null &&
                                      getTheBaseLineTwoExp === false)) && (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="40"
                                      height="40"
                                      viewBox="0 0 40 40"
                                      fill="none"
                                    >
                                      {/** blue */}
                                      <rect
                                        width="40"
                                        height="40"
                                        rx="4"
                                        fill="#EDF2F6"
                                      />
                                      <path
                                        d="M16 12L24 20L16 28"
                                        stroke="#435E9B"
                                        strokeWidth="4"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </svg>
                                  )}

                                  {/**green */}
                                  {questions?.masteranswers?.status ===
                                    "Completed" &&
                                    ((questions?.sortTitle === "QB1" &&
                                      getTheBaseLineOneExp === false) ||
                                      (questions?.sortTitle === "QB2" &&
                                        getTheBaseLineTwoUnLock === false &&
                                        getTheBaseLineOneExp === false)) && (
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="40"
                                        height="40"
                                        viewBox="0 0 40 40"
                                        fill="none"
                                      >
                                        {/** Green */}
                                        <rect
                                          width="40"
                                          height="40"
                                          rx="4"
                                          fill="#B4E4BA"
                                        />
                                        <path
                                          d="M28 14L16.0675 26L12 21.9095"
                                          stroke="#35A347"
                                          strokeWidth="4"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                      </svg>
                                    )}

                                  {/** yellow */}
                                  {questions?.masteranswers?.status ===
                                    "Partially Completed" &&
                                    ((questions?.sortTitle === "QB1" &&
                                      getTheBaseLineOneExp === false) ||
                                      (questions?.sortTitle === "QB2" &&
                                        getTheBaseLineTwoUnLock === false &&
                                        getTheBaseLineOneExp === false)) && (
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="40"
                                        height="40"
                                        viewBox="0 0 40 40"
                                        fill="none"
                                      >
                                        {/** Yellow */}
                                        <rect
                                          width="40"
                                          height="40"
                                          rx="4"
                                          fill="#FFED8E"
                                        />
                                        <path
                                          d="M16 12L24 20L16 28"
                                          stroke="#F6B100"
                                          strokeWidth="4"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                      </svg>
                                    )}
                                </button>
                              </div>
                            </div>
                          </div>
                        );
                      })()}
                    </>
                  ) : null
                )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withTranslation()(QuestionsList);
