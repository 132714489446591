// Auth Actions
export const SIGN_UP = "SIGN_UP";
export const SIGN_IN = "SIGN_IN";
export const SIGN_IN_SUCCESS = "SIGN_IN_SUCCESS";
export const SIGN_IN_FAILED = "SIGN_IN_FAILED";
export const DIRECT_ACCOUNT_VERIFICATION_IN = "DIRECT_ACCOUNT_VERIFICATION_IN";
export const DIRECT_ACCOUNT_VERIFICATION_IN_SUCCESS = "DIRECT_ACCOUNT_VERIFICATION_IN_SUCCESS";
export const DIRECT_ACCOUNT_VERIFICATION_IN_FAILED = "DIRECT_ACCOUNT_VERIFICATION_IN_FAILED";
export const SIGN_UP_SUCCESS = "SIGN_UP_SUCCESS";
export const SIGN_UP_FAILED = "SIGN_UP_FAILED";
export const VERIFY_ACC = "VERIFY_ACC";
export const VERIFY_ACC_SUCCESS = "VERIFY_ACC_SUCCESS";
export const VERIFY_ACC_FAILED = "VERIFY_ACC_FAILED";
export const REVERIFY_ACC = "REVERIFY_ACC";
export const REVERIFY_ACC_SUCCESS = "REVERIFY_ACC_SUCCESS";
export const REVERIFY_ACC_FAILED = "REVERIFY_ACC_FAILED";
export const OTP_VERIFY = "OTP_VERIFY";
export const OTP_VERIFY_SUCCESS = "OTP_VERIFY_SUCCESS";
export const OTP_VERIFY_FAILED = "OTP_VERIFY_FAILED";
export const MOTHERID_UPLOAD = "MOTHERID_UPLOAD";
export const MOTHERID_UPLOAD_SUCCESS = "MOTHERID_UPLOAD_SUCCESS";
export const MOTHERID_UPLOAD_FAILED = "MOTHERID_UPLOAD_FAILED";
export const DADID_UPLOAD = "DADID_UPLOAD";
export const DADID_UPLOAD_SUCCESS = "DADID_UPLOAD_SUCCESS";
export const DADID_UPLOAD_FAILED = "DADID_UPLOAD_FAILED";
export const VERIFY_RESET_EMAIL = "VERIFY_RESET_EMAIL";
export const VERIFY_RESET_EMAIL_SUCCESS = "VERIFY_RESET_EMAIL_SUCCESS";
export const VERIFY_RESET_EMAIL_FAILED = "VERIFY_RESET_EMAIL_FAILED";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILED = "RESET_PASSWORD_FAILED";

export const SEND_PDF_EMAIL = "SEND_PDF_EMAIL";
export const SEND_PDF_EMAIL_SUCCESS = "SEND_PDF_EMAIL_SUCCESS";
export const SEND_PDF_EMAIL_FAILED = "SEND_PDF_EMAIL_FAILED";

// get Single Form
export const GET_FORM = "GET_FORM";
export const GET_FORM_SUCCESS = "GET_FORM_SUCCESS";
export const GET_FORM_FAILED = "GET_FORM_FAILED";

// user by id
export const GET_USER_BY_ID = "GET_USER_BY_ID";
export const GET_USER_BY_ID_SUCCESS = "GET_USER_BY_ID_SUCCESS";
export const GET_USER_BY_ID_FAILED = "GET_USER_BY_ID_FAILED";

// Update User
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";
export const UPDATE_PROFILE_FAILED = "UPDATE_PROFILE_FAILED";

// Submit form
export const SUBMIT_FORM = "SUBMIT_FORM";
export const SUBMIT_FORM_SUCCESS = "SUBMIT_FORM_SUCCESS";
export const SUBMIT_FORM_FAILED = "SUBMIT_FORM_FAILED";

//Update Submit form
export const UPDATE_SUBMIT_FORM = "UPDATE_SUBMIT_FORM";
export const UPDATE_SUBMIT_FORM_SUCCESS = "UPDATE_SUBMIT_FORM_SUCCESS";
export const UPDATE_SUBMIT_FORM_FAILED = "UPDATE_SUBMIT_FORM_FAILED";

// Get Submit Form 
export const GET_SUBMIT_FORM = "GET_SUBMIT_FORM";
export const GET_SUBMIT_FORM_SUCCESS = "GET_SUBMIT_FORM_SUCCESS";
export const GET_SUBMIT_FORM_FAILED = "GET_SUBMIT_FORM_FAILED";

// get questionaries list
export const GET_QUESTIONARIES_LIST = "GET_QUESTIONARIES_LIST";
export const GET_QUESTIONARIES_LIST_SUCCESS = "GET_QUESTIONARIES_LIST_SUCCESS";
export const GET_QUESTIONARIES_LIST_FAILED = "GET_QUESTIONARIES_LIST_FAILED";

// get Form list
export const GET_SECTIONS_BY_ID = "GET_SECTIONS_BY_ID";
export const GET_SECTIONS_BY_ID_SUCCESS = "GET_SECTIONS_BY_ID_SUCCESS";
export const GET_SECTIONS_BY_ID_FAILED = "GET_QUESTIONARIES_LIST_FAILED";

//emptyQuestionsFormDataList
export const EMPTY_QUESTIONS_FORM_DATA = "EMPTY_QUESTIONS_FORM_DATA";

export const CHANGE_USER_LANG = "CHANGE_USER_LANG";

// GET PRIVACY AND TERMS LIST
export const GET_PRIVACY_AND_TERMS_LIST = "GET_PRIVACY_AND_TERMS_LIST";
export const GET_PRIVACY_AND_TERMS_LIST_SUCCESS =
  "GET_PRIVACY_AND_TERMS_LIST_SUCCESS";
export const GET_PRIVACY_AND_TERMS_LIST_FAILED =
  "GET_PRIVACY_AND_TERMS_LIST_FAILED";

export const GET_NAVIGATE_PATH = "GET_NAVIGATE_PATH";
export const CLEAR_NAVIGATE_PATH = "CLEAR_NAVIGATE_PATH";

export const GET_TAB_NAME = "GET_TAB_NAME";
export const CLEAR_TAB_NAME = "CLEAR_TAB_NAME";

export const GET_FORM_DETAILS_BY_ID = "GET_FORM_DETAILS_BY_ID";
export const GET_FORM_DETAILS_BY_ID_SUCCESS = "GET_FORM_DETAILS_BY_ID_SUCCESS";
export const GET_FORM_DETAILS_BY_ID_FAILED = "GET_FORM_DETAILS_BY_ID_FAILED";

export const GET_NOTIFICATION = "GET_NOTIFICATION";
export const GET_NOTIFICATION_SUCCESS = "GET_NOTIFICATION_SUCCESS";
export const GET_NOTIFICATION_FAILED = "GET_NOTIFICATION_FAILED";

export const UPDATE_NOTIFICATION = "UPDATE_NOTIFICATION";
export const UPDATE_NOTIFICATION_SUCCESS = "UPDATE_NOTIFICATION_SUCCESS";
export const UPDATE_NOTIFICATION_FAILED = "UPDATE_NOTIFICATION_FAILED";
