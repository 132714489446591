import { call, put, takeLatest } from "redux-saga/effects";
import { GET_NOTIFICATION, UPDATE_NOTIFICATION } from "../actionType";
import api from "../../utils/api";
import { endPoints } from "../../utils/ennpoints";
import {
  getNotificationFailed,
  getNotificationSuccess,
  updateNotificationFailed,
  updateNotificationSuccess,
} from "./notificationAction";

function* getAllNotification() {
  try {
    const response = yield call(api.get, endPoints.GET_NOTIFICATION);
    if (response) {
      yield put(getNotificationSuccess(response?.data));
    }
  } catch (error) {
    if (error) {
      const unauthorize = error?.response?.status;
      if (unauthorize === 401 || unauthorize === "401") {
        localStorage.removeItem("token");
        localStorage.removeItem("isLogedIn");
        localStorage.removeItem("userId");
      }
      yield put(getNotificationFailed());
    }
  }
}

function* updateNotification() {
  try {
    const response = yield call(api.put, endPoints.UPDATE_NOTIFICATION);
    if (response) {
      yield put(updateNotificationSuccess(response?.data));
    }
  } catch (error) {
    const unauthorize = error?.response?.status;
    if (unauthorize === 401 || unauthorize === "401") {
      localStorage.removeItem("token");
      localStorage.removeItem("isLogedIn");
      localStorage.removeItem("userId");
    }
    if (error) {
      yield put(updateNotificationFailed());
    }
  }
}

export function* notifactionSaga() {
  yield takeLatest(GET_NOTIFICATION, getAllNotification);
  yield takeLatest(UPDATE_NOTIFICATION, updateNotification);
}
