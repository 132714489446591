import React, { useEffect } from 'react'
import completeImg from '../../assets/images/completeSave.svg'
import { withTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { clearNavigatePath, getNavigatePath } from '../../redux/navigatePath/navigateAction';

const Complete = ({t}) => {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getNavigatePath({
                title: t('Torna alla home'),
                path: '/questions-list'
        }))
    }, [dispatch, t])

    useEffect( () => () => dispatch(clearNavigatePath()), [dispatch] );

  return (
    <>
            <div className="option-detail welcome_section from-completed-screen">
                <div className="container">
                    <div className="row align-items-center column-r">
                        <div className="col-lg-5">
                            <div className="left-side box-shadow-none bg-none justify-content-center p-0">
                                <div className="meditation-yaga-img">
                                    <img src={completeImg} alt="welcome" className="img-fluid" />
                                    {/* <div className="text-center fcs-btn mobile">
                                        <button onClick={() => navigate('/questions-list')} className="solid-btn">{t('Torna alla home')}</button >
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <div className="right-side text-center">
                                <div className="meditation-yaga-section">
                                    <h2>{t('Il questionario è stato salvato correttamente.')}</h2>
                                    <h3>{t('Torna a compilarlo quando vuoi.')}
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
  )
}

export default withTranslation()(Complete)