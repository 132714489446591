import {
  EMPTY_QUESTIONS_FORM_DATA,
  GET_FORM,
  GET_FORM_DETAILS_BY_ID,
  GET_FORM_DETAILS_BY_ID_FAILED,
  GET_FORM_DETAILS_BY_ID_SUCCESS,
  GET_FORM_FAILED,
  GET_FORM_SUCCESS,
  GET_QUESTIONARIES_LIST,
  GET_QUESTIONARIES_LIST_FAILED,
  GET_QUESTIONARIES_LIST_SUCCESS,
  GET_SECTIONS_BY_ID,
  GET_SECTIONS_BY_ID_FAILED,
  GET_SECTIONS_BY_ID_SUCCESS,
  GET_SUBMIT_FORM,
  GET_SUBMIT_FORM_FAILED,
  GET_SUBMIT_FORM_SUCCESS,
  SUBMIT_FORM,
  SUBMIT_FORM_FAILED,
  SUBMIT_FORM_SUCCESS,
  UPDATE_SUBMIT_FORM,
  UPDATE_SUBMIT_FORM_FAILED,
  UPDATE_SUBMIT_FORM_SUCCESS,
} from "../actionType";

const initialState = {
  data: null,
  singleForm: {},
  loading: false,
  error: null,
  questionariesList: [],
  questionsFormDataList: [],
  questionsFormDataListpre: [],
  formDetails: null,
};

function QuestionariesReducer(state = initialState, action) {
  switch (action.type) {
    case GET_FORM:
      return {
        ...state,
        loading: true,
      };
    case GET_FORM_SUCCESS:
      return {
        ...state,
        loading: false,
        singleForm: action.payload,
      };
    case GET_FORM_FAILED:
      return {
        ...state,
        loading: false,
        error: "Something went wrong",
      };

    case SUBMIT_FORM:
      return {
        ...state,
        loading: true,
      };

    case SUBMIT_FORM_SUCCESS:
      return {
        ...state,
        loading: false,
        questionsFormDataList: {
          data: state?.questionsFormDataList?.data?.map((ele) => {
            if (ele?._id === action.payload.sectionId) {
              return {
                ...ele,
                isCompleted: true,
              };
            } else {
              return ele;
            }
          }),
        },
      };

    case SUBMIT_FORM_FAILED:
      return {
        ...state,
        loading: false,
        error: "Something went wrong",
      };

    case UPDATE_SUBMIT_FORM:
      return {
        ...state,
        loading: true,
      };

    case UPDATE_SUBMIT_FORM_SUCCESS:
      return {
        ...state,
        loading: false,
        questionsFormDataList: {
          data: state?.questionsFormDataList?.data?.map((ele) => {
            if (ele?._id === action.payload.sectionId) {
              return {
                ...ele,
                isCompleted: true,
              };
            } else {
              return ele;
            }
          }),
        },
      };

    case UPDATE_SUBMIT_FORM_FAILED:
      return {
        ...state,
        loading: false,
        error: "Something went wrong",
      };

    case GET_SUBMIT_FORM:
      return {
        ...state,
        loading: true,
      };
    case GET_SUBMIT_FORM_SUCCESS:
      console.log("QuestionariesReducer ~ action.payload:", action.payload);
      return {
        ...state,
        loading: false,
        questionsFormDataListpre: action.payload,
      };
    case GET_SUBMIT_FORM_FAILED:
      return {
        ...state,
        loading: false,
        error: "Something went wrong",
      };
    case GET_QUESTIONARIES_LIST:
      return {
        ...state,
        loading: true,
      };
    case GET_QUESTIONARIES_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        questionariesList: action.payload,
      };
    case GET_QUESTIONARIES_LIST_FAILED:
      return {
        ...state,
        loading: false,
        error: "Something went wrong",
      };
    case GET_SECTIONS_BY_ID:
      return {
        ...state,
        loading: true,
      };
    case GET_SECTIONS_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        questionsFormDataList: action.payload,
      };
    case GET_SECTIONS_BY_ID_FAILED:
      return {
        ...state,
        loading: false,
        error: "Something went wrong",
      };

    case GET_FORM_DETAILS_BY_ID:
      return {
        ...state,
        loading: true,
      };
    case GET_FORM_DETAILS_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        formDetails: action.payload,
      };
    case GET_FORM_DETAILS_BY_ID_FAILED:
      return {
        ...state,
        loading: false,
        error: "Something went wrong",
      };

    case EMPTY_QUESTIONS_FORM_DATA:
      return {
        ...state,
        questionsFormDataList: [],
      };
    default:
      return state;
  }
}

export default QuestionariesReducer;
