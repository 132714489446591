import React from "react";
import FormRender from "../views/pages/Form/FormRender";
import MainLayout from "../layout/MainLayout";
import AuthLayout from "../layout/AuthLayout";
import Register from "../views/pages/auth/Register";
import HomePage from "../views/pages/Homepage/HomePage";
import QuestionsList from "../views/pages/Questions/QuestionsList";
import Complete from "../views/CompleteScreen/Complete";
import Page404 from "../views/pages/page404/Page404";
import FormIntro from "../views/pages/Form/FormIntro";
import LandingPage from "../views/pages/landingPage/LandingPage";
import UserDetails from "../views/pages/userDetails/UserDetails";
import PersonalData from "../views/pages/userDetails/PersonalData";
import ContactData from "../views/pages/userDetails/ContactData";
import Residenacy from "../views/pages/userDetails/Residenacy";
import Contact from "../views/pages/Contact/Contact";
import FAQ from "../views/pages/FAQ/FAQ";
import { PublicLayout } from "../layout/PublicLayout";
import CompleteSaveForm from "../views/CompleteScreen/CompleteSaveForm";
import Title from "../views/pages/Title/Title";
import Ottimio from "../views/pages/Ottimo/Ottimio";
import AccountConfermato from "../views/pages/AccountConfermato/AccountConfermato";
import VerificationAccount from "../views/pages/VerificationAccount/VerificationAccount";
import AbbiamoQuasi from "../views/pages/AbbiamoQuasi/AbbiamoQuasi";
import Privacy from "../views/pages/auth/Privacy";
import RegistrationComplete from "../views/pages/RegistrationComplete/RegistrationComplete";
import UploadMotherId from "../views/pages/UploadId/UploadMotherId";
import UploadDadId from "../views/pages/UploadId/UploadDadId";
import SignIn from "../views/pages/auth/SignIn";
import ForgetPassword from "../views/pages/ForgetPassword/ForgetPassword";
import ResetPassword from "../views/pages/NewPassword/ResetPassword";
import CompleteSecond from "../views/CompleteScreen/CompleteSecond";

const Routers = [
  {
    path: "/",
    element: <MainLayout />,
    children: [
      {
        path: "/",
        element: <HomePage />,
      },
      {
        path: "/form-intro/:formIntroId",
        element: <FormIntro />,
      },
      {
        path: "/form/:id",
        element: <FormRender />,
      },
      {
        path: "/questions-list",
        element: <QuestionsList />,
      },
      {
        path: "/complete",
        element: <Complete />,
      },
      {
        path: "/completeSecond",
        element: <CompleteSecond />,
      },
      {
        path: "/complete-save-from",
        element: <CompleteSaveForm />,
      },
      {
        path: "/user-details",
        element: <UserDetails />,
      },
      {
        path: "/user-details/personal-data",
        element: <PersonalData />,
      },
      {
        path: "/user-details/contact",
        element: <ContactData />,
      },
      {
        path: "/user-details/residenacy",
        element: <Residenacy />,
      },
      {
        path: "*",
        element: <Page404 />,
      },
      {
        path: "UploadMotherId",
        element: <UploadMotherId />,
      },
      {
        path: "UploadDadId",
        element: <UploadDadId />,
      },
      {
        path: "registration-completed",
        element: <RegistrationComplete />,
      },
    ],
  },
  {
    path: "auth",
    element: <AuthLayout />,
    children: [
      {
        path: "",
        element: <LandingPage />,
      },
      {
        path: "signIn",
        element: <SignIn />,
      },
      {
        path: "register",
        element: <Register />,
      },
      {
        path: "forget-password",
        element: <ForgetPassword />,
      },
      {
        path: "reset-password/:id",
        element: <ResetPassword />,
      },
      {
        path: "title",
        element: <Title />,
      },
      {
        path: "ottimio",
        element: <Ottimio />,
      },
      {
        path: "accountconfermo/:userId",
        element: <AccountConfermato />,
      },
      {
        path: "verifiaction-account",
        element: <VerificationAccount />,
      },
      {
        path: "abbiamoQuasi/:userId",
        element: <AbbiamoQuasi />,
      },
     
      {
        path: "privacy",
        element: <Privacy />,
      },
    ],
  },
  {
    path: "info",
    element: <PublicLayout />,
    children: [
      {
        path: "projects",
        element: <LandingPage />,
      },
      {
        path: "FAQ",
        element: <FAQ />,
      },
      {
        path: "contact",
        element: <Contact />,
      },
      {
        path: "*",
        element: <Page404 />,
      },
    ],
  },
];

export default Routers;
