import React, { Suspense } from "react";
import Header from "../components/header/Header";
import { CSpinner } from "@coreui/react";
import { Outlet } from "react-router-dom";
import Footer from "../components/footer/Footer";

export const PublicLayout = () => {
  return (
    <div>
      <Header />
      <div className="wrapper d-flex flex-column">
        <div className="body flex-grow-1 min-height">
          <Suspense fallback={<CSpinner color="primary" />}>
            <Outlet />
          </Suspense>
        </div>
      </div>
      <Footer />
    </div>
  );
};
