import { call, put, takeLatest } from "redux-saga/effects";
import {
  GET_FORM,
  GET_FORM_DETAILS_BY_ID,
  GET_QUESTIONARIES_LIST,
  GET_SECTIONS_BY_ID,
  GET_SUBMIT_FORM,
  SUBMIT_FORM,
} from "../actionType";
import { endPoints } from "../../utils/ennpoints";
import {
  getFormFailed,
  getFormSuccess,
  getQuestionariesListFailed,
  getQuestionariesListSuccess,
  getQuestionsFormDetailsFailed,
  getQuestionsFormDetailsSuccess,
  getQuestionsFormListFailed,
  getQuestionsFormListSuccess,
  getsubmitFormFailed,
  getsubmitFormSuccess,
  submitFormFailed,
  submitFormSuccess,
  updateSubmitFormFailed,
  updateSubmitFormSuccess,
} from "./questionariesAction";
import api from "../../utils/api";

function* getForm() {
  try {
    const response = yield call(api.get, endPoints.GET_FORM);
    let data = {
      formId: response?.data?.data?._id,
      formData: response?.data?.data?.questions
        ? JSON.parse(response?.data?.data?.questions)
        : {},
      timeline: response?.data?.data?.timeline,
      title: response?.data?.data?.title,
    };
    if (response) {
      yield put(getFormSuccess(data));
    }
  } catch (error) {
    const unauthorize = error?.response?.status;
    if (unauthorize === 401 || unauthorize === "401") {
      localStorage.removeItem("token");
      localStorage.removeItem("isLogedIn");
      localStorage.removeItem("userId");
    }
    if (error) {
      yield put(getFormFailed());
    }
  }
}

function* submitForm({ payload }) {
  try {
    if (payload?.isUpdate === false) {
      const response = yield call(
        api.post,
        endPoints.SUBMIT_FORM,
        payload.submitedData
      );
      if (response) {
        let nextSectionIndex = ++payload.sectionNo;
        localStorage.setItem("index", nextSectionIndex);

        yield put(submitFormSuccess(payload.submitedData));
        yield call(payload.callBack, nextSectionIndex);
      }
    } else {
      const payloadVal = {
        answerId: payload?.submitedData?.sectionId,
        isBlankField: payload?.submitedData?.isBlankField,
        submission: payload?.submitedData?.answer?.submission,
        extraField: payload?.submitedData?.extraField,
        isCompleted: payload?.submitedData?.isCompleted,
        userId: payload?.submitedData?.userId,
        formId: payload?.submitedData?.formId,
      };
      const response = yield call(
        api.post,
        endPoints.UPDATE_SUBMIT_FORM,
        payloadVal
      );
      if (response) {
        let nextSectionIndex = ++payload.sectionNo;
        localStorage.setItem("index", nextSectionIndex);
        yield put(updateSubmitFormSuccess(payload.submitedData));
        yield call(payload.callBack, nextSectionIndex);
      }
    }
  } catch (error) {
    const unauthorize = error?.response?.status;
    if (unauthorize === 401 || unauthorize === "401") {
      localStorage.removeItem("token");
      localStorage.removeItem("isLogedIn");
      localStorage.removeItem("userId");
    }
    if (payload.sectionNo !== undefined) {
      yield put(submitFormFailed(error));
    } else {
      yield put(updateSubmitFormFailed(error));
    }
  }
}

function* getsubmitForm({ payload }) {
  try {
    const response = yield call(
      api.post,
      endPoints.GET_SUBMIT_FORM,
      payload.submitedData
    );
    if (response) {
      yield put(getsubmitFormSuccess(response?.data?.data));
    }
  } catch (error) {
    if (error) {
      const unauthorize = error?.response?.status;
      if (unauthorize === 401 || unauthorize === "401") {
        localStorage.removeItem("token");
        localStorage.removeItem("isLogedIn");
        localStorage.removeItem("userId");
      }
      yield put(getsubmitFormFailed());
    }
  }
}

function* getQuestionariesList({ payload }) {
  try {
    const response = yield call(
      api.get,
      `${endPoints.GET_QUESTIONS_LIST}?pageNo=${payload.pageNo}&limit=${payload.limit}`
    );

    if (response) {
      yield put(getQuestionariesListSuccess(response?.data));
    }
  } catch (error) {
    const unauthorize = error?.response?.status;
    if (unauthorize === 401 || unauthorize === "401") {
      localStorage.removeItem("token");
      localStorage.removeItem("isLogedIn");
      localStorage.removeItem("userId");
    }
    if (error) {
      yield put(getQuestionariesListFailed());
    }
  }
}

function* getQuestionsFormList({ payload }) {
  try {
    const response = yield call(api.get, endPoints.GET_SECTIONS_BY_ID, {
      urlParams: { formId: payload.formId, userId: payload.userId },
    });

    if (response) {
      yield put(getQuestionsFormListSuccess(response?.data));
    }
  } catch (error) {
    const unauthorize = error?.response?.status;
    if (unauthorize === 401 || unauthorize === "401") {
      localStorage.removeItem("token");
      localStorage.removeItem("isLogedIn");
      localStorage.removeItem("userId");
    }
    if (error) {
      yield put(getQuestionsFormListFailed());
    }
  }
}

function* getFormDetails({ payload }) {
  try {
    const response = yield call(api.get, endPoints.GET_FORM_BY_ID, {
      urlParams: { id: payload },
    });
    if (response) {
      yield put(getQuestionsFormDetailsSuccess(response.data.data));
    }
  } catch (error) {
    const unauthorize = error?.response?.status;
    if (unauthorize === 401 || unauthorize === "401") {
      localStorage.removeItem("token");
      localStorage.removeItem("isLogedIn");
      localStorage.removeItem("userId");
    }
    if (error) {
      yield put(getQuestionsFormDetailsFailed());
    }
  }
}

export function* questionariesSaga() {
  yield takeLatest(GET_FORM, getForm);
  yield takeLatest(SUBMIT_FORM, submitForm);
  yield takeLatest(GET_SUBMIT_FORM, getsubmitForm);
  yield takeLatest(GET_QUESTIONARIES_LIST, getQuestionariesList);
  yield takeLatest(GET_SECTIONS_BY_ID, getQuestionsFormList);
  yield takeLatest(GET_FORM_DETAILS_BY_ID, getFormDetails);
}
