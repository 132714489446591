import {
  GET_PRIVACY_AND_TERMS_LIST,
  GET_PRIVACY_AND_TERMS_LIST_FAILED,
  GET_PRIVACY_AND_TERMS_LIST_SUCCESS,
} from '../actionType'

export const getPrivacyAndTerms = (data) => {
  return {
    type: GET_PRIVACY_AND_TERMS_LIST,
    payload: data,
  }
}

export const getPrivacyAndTermsSuccess = (data) => {
  return {
    type: GET_PRIVACY_AND_TERMS_LIST_SUCCESS,
    payload: data,
  }
}

export const getPrivacyAndTermsFailed = (data) => {
  return {
    type: GET_PRIVACY_AND_TERMS_LIST_FAILED,
    payload: data,
  }
}