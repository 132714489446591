import { call, put, takeLatest } from "redux-saga/effects";
import {
  ResetPasswordFailed,
  ResetPasswordlSuccess,
  directVerificationFailed,
  directVerificationSuccess,
  loginFailed,
  loginSuccess,
  otpVerifyFailed,
  otpVerifySuccess,
  reVerifyAccFailed,
  reVerifyAccSuccess,
  registerFailed,
  registerSuccess,
  sendPDFEmailFailed,
  sendPDFEmailSuccess,
  uploadDadIdFailed,
  uploadDadIdSuccess,
  uploadMotherIdFailed,
  uploadMotherIdSuccess,
  verifyAccFailed,
  verifyAccSuccess,
  verifyResetEmailFailed,
  verifyResetEmailSuccess,
} from "./authAction";
import api from "../../utils/api";
import {
  DADID_UPLOAD,
  DIRECT_ACCOUNT_VERIFICATION_IN,
  MOTHERID_UPLOAD,
  OTP_VERIFY,
  RESET_PASSWORD,
  REVERIFY_ACC,
  SEND_PDF_EMAIL,
  SIGN_IN,
  SIGN_UP,
  VERIFY_ACC,
  VERIFY_RESET_EMAIL,
} from "../actionType";
import { endPoints } from "../../utils/ennpoints";
import toast from "react-hot-toast";
import CommonToaster from "../../utils/Toaster";

function* signIn({ payload }) {
  const toastId = toast.loading("Attendere prego");
  try {
    const response = yield call(api.post, endPoints.LOGIN, payload.state);
    if (response) {
      localStorage.setItem("token", response.data.token);
      localStorage.setItem("isLogedIn", true);
      localStorage.setItem("userId", response?.data?.userDetails?._id);
      CommonToaster({
        toastId,
        toastType: "success",
        message: "Accesso effettuato con successo.",
      });
      yield put(loginSuccess(response.data));
      yield call(payload.callBack);
    }
  } catch (error) {
    if (error) {
      yield put(loginFailed());
      CommonToaster({
        toastId,
        toastType: "error",
        message: error.response.data.message || "Something went wrong",
      });
    }
  }
}

function* register({ payload }) {
  const toastId = toast.loading("Attendere prego");
  try {
    const response = yield call(
      api.post,
      endPoints.REGISTER,
      payload.userDetailUpdate
    );
    if (response) {
      localStorage.setItem("email", response.data.email);
      localStorage.setItem(
        "emailValue",
        response?.data?.userDetailUpdate?.email
      );
      CommonToaster({
        toastId,
        toastType: "success",
        message: "Registrazione effettuata con successo",
      });
      
      yield put(registerSuccess(payload?.userDetailUpdate));
      yield call(payload.callBack);
    }
  } catch (error) {
    if (error) {
      yield put(registerFailed());
      CommonToaster({
        toastId,
        toastType: "error",
        message: error.response.data.message || "Something went wrong",
      });
    }
  }
}

function* directVerification({ payload }) {
  const toastId = toast.loading("Attendere prego");
  try {
    const response = yield call(
      api.post,
      endPoints.DIRECT_ACCOUNT_VERIFICATION,
      payload
    );
    if (response) {
      const userId = response?.data?.userDetails?._id;
      const token = response?.data?.token;
      localStorage.setItem("userId", userId);
      localStorage.setItem("token", token);
      localStorage.setItem("isLogedIn", true);
      CommonToaster({
        toastId,
        toastType: "success",
        message: "Con successo! ",
      });
      yield put(directVerificationSuccess(payload?.userDetails));
    }
  } catch (error) {
    toast.dismiss(toastId);
    if (error) {
      yield put(directVerificationFailed());
      yield call(payload.onError, error);
    }
  }
}

function* verifyAcc({ payload }) {
  const toastId = toast.loading("Attendere prego");
  try {
    const response = yield call(
      api.post,
      endPoints.VERIFY_ACC,
      payload.sendData
    );
    if (response) {
      const otpValue = response?.data?.userDetails?.otpverification;
      localStorage.setItem("userId", response?.data?.userDetails?._id);
      localStorage.setItem(
        "phoneNumber",
        response?.data?.userDetails?.mobileNumber
      );
      localStorage.setItem("otpValue", otpValue);
      alert(
        `OTP inviato al tuo indirizzo email. Il tuo codice OTP è: ${otpValue}. Controlla la posta in arrivo presto!`
      );
      CommonToaster({
        toastId,
        toastType: "success",
        message:
          "OTP inviato al tuo indirizzo email. Controlla la posta in arrivo presto!",
      });
      yield put(verifyAccSuccess(payload?.userDetails));
      yield call(payload.callBack);
    }
  } catch (error) {
    if (error) {
      yield put(verifyAccFailed());
      CommonToaster({
        toastId,
        toastType: "error",
        message: error.response.data.message || "Something went wrong",
      });
    }
  }
}

function* reverifyAcc({ payload }) {
  const toastId = toast.loading("Attendere prego");
  try {
    const response = yield call(
      api.post,
      endPoints.REVERIFY_ACC,
      payload.sendData
    );
    if (response) {
      const otpValue = response?.data?.userDetails?.otpverification;
      localStorage.setItem("userId", response?.data?.userDetails?._id);
      localStorage.setItem(
        "phoneNumber",
        response?.data?.userDetails?.mobileNumber
      );
      localStorage.setItem("otpValue", otpValue);
      alert(
        `OTP inviato nuovamente al tuo indirizzo email. Il tuo codice OTP è: ${otpValue}. Controlla presto la tua casella di posta!`
      );
      CommonToaster({
        toastId,
        toastType: "success",
        message: "Numero di telefono aggiornato. Controlla l'e-mail per OTP!",
      });
      yield put(reVerifyAccSuccess(payload?.userDetails));
      yield call(payload.callBack);
    }
  } catch (error) {
    if (error) {
      yield put(reVerifyAccFailed());
      CommonToaster({
        toastId,
        toastType: "error",
        message: error.response.data.message || "Something went wrong",
      });
    }
  }
}

function* verifyotp({ payload }) {
  const toastId = toast.loading("Attendere prego");
  try {
    const response = yield call(
      api.post,
      endPoints.VERIFY_OTP,
      payload.sendData
    );
    if (response) {
      const userId = response?.data?.userDetails?._id;
      const token = response?.data?.token;
      localStorage.setItem("userId", userId);
      localStorage.setItem("token", token);
      localStorage.setItem("isLogedIn", true);
      CommonToaster({
        toastId,
        toastType: "success",
        message: "Con successo! La tua OTP è stata verificata!",
      });
      yield put(otpVerifySuccess(payload?.userDetails));
      yield call(payload.callBack);
    }
  } catch (error) {
    toast.dismiss(toastId);
    if (error) {
      yield put(otpVerifyFailed());
      yield call(payload.onError, error);
    }
  }
}

function* uploadMotherId({ payload }) {
  console.log("🚀 ~ function*uploadMotherID ~ payload:", payload);
  const toastId = toast.loading("Attendere prego");
  try {
    console.log("🚀 ~ function*uploadMotherID ~ toastId:", toastId);
    const response = yield call(
      api.post,
      endPoints.UPLOADMOTHERID,
      payload.userDetails
    );
    console.log("🚀 ~ function*uploadMotherID ~ response:", response);
    if (response) {
      CommonToaster({
        toastId,
        toastType: "success",
        message: "Documento Madre caricato con successo!",
      });
      yield put(uploadMotherIdSuccess(payload?.userDetails));
      yield call(payload.callBack);
    }
  } catch (error) {
    toast.dismiss(toastId);
    if (error) {
      yield put(uploadMotherIdFailed());
      CommonToaster({
        toastId,
        toastType: "error",
        message: error.response.data.message || "Qualcosa è andato storto",
      });
    }
  }
}
function* uploadDadID({ payload }) {
  const toastId = toast.loading("Attendere prego");
  try {
    const response = yield call(
      api.put,
      endPoints.UPLOADDADID,
      payload.userDetails
    );
    if (response) {
      CommonToaster({
        toastId,
        toastType: "success",
        message: "Documento Padre caricato con successo!",
      });
      yield put(uploadDadIdSuccess(payload?.userDetails));
      yield call(payload.callBack);
    }
  } catch (error) {
    toast.dismiss(toastId);
    if (error) {
      yield put(uploadDadIdFailed());
      CommonToaster({
        toastId,
        toastType: "error",
        message: error.response.data.message || "Qualcosa è andato storto",
      });
    }
  }
}
function* verifyResetEmail({ payload }) {
  const toastId = toast.loading("Attendere prego");
  try {
    const response = yield call(api.post, endPoints.VERIFY_RESET_MAIL, payload);
    if (response) {
      CommonToaster({
        toastId,
        toastType: "success",
        message: "Con successo! La tua email è stata inviata!",
      });
      yield put(verifyResetEmailSuccess(response?.data?.message));
    }
  } catch (error) {
    toast.dismiss(toastId);
    if (error) {
      yield put(verifyResetEmailFailed());
      yield call(payload.onError, error);
    }
  }
}
function* ResetPassword({ payload }) {
  const toastId = toast.loading("Attendere prego");
  try {
    const response = yield call(
      api.post,
      endPoints.RESET_PASSWORD,
      payload?.payload
    );
    if (response) {
      CommonToaster({
        toastId,
        toastType: "success",
        message: "Reimpostazione della password riuscita.!",
      });
      yield put(ResetPasswordlSuccess(response?.data));
      yield call(payload.callBack);
    }
  } catch (error) {
    toast.dismiss(toastId);
    if (error) {
      yield put(ResetPasswordFailed());
      if (payload.onError) {
        yield call(payload.onError, error); // Call the error callback if provided
      }
    }
  }
}

function* sendPDFEmail({ payload }) {
  const toastId = toast.loading("Attendere prego");
  try {
    const response = yield call(
      api.post,
      endPoints.SEND_PDF_EMAIL,
      new URLSearchParams(payload), // Convert payload to URLSearchParams
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );
    if (response) {
      toast.update(toastId, {
        type: toast.TYPE.SUCCESS,
        autoClose: 3000,
        closeButton: false,
        closeOnClick: true,
      });
      yield put(sendPDFEmailSuccess(response.data));
      yield call(payload.callBack);
      localStorage.removeItem("privacy");
      localStorage.removeItem("teams");
    }
  } catch (error) {
    toast.dismiss(toastId);
    yield put(sendPDFEmailFailed());
    if (payload.onError) {
      yield call(payload.onError, error); // Call the error callback if provided
    }
  }
}

export function* authSaga() {
  yield takeLatest(SIGN_IN, signIn); // Ensure that SIGN_IN is the correct action type
  yield takeLatest(SIGN_UP, register);
  yield takeLatest(VERIFY_ACC, verifyAcc);
  yield takeLatest(OTP_VERIFY, verifyotp);
  yield takeLatest(REVERIFY_ACC, reverifyAcc);
  yield takeLatest(MOTHERID_UPLOAD, uploadMotherId);
  yield takeLatest(DADID_UPLOAD, uploadDadID);
  yield takeLatest(VERIFY_RESET_EMAIL, verifyResetEmail);
  yield takeLatest(RESET_PASSWORD, ResetPassword);
  yield takeLatest(SEND_PDF_EMAIL, sendPDFEmail);
  yield takeLatest(DIRECT_ACCOUNT_VERIFICATION_IN, directVerification);
}
