import { useRoutes } from "react-router-dom";
import Routers from "./router";
import { useDispatch } from "react-redux";
import { getUserById } from "./redux/user/userAction";
import { Toaster } from "react-hot-toast";

function App() {
  const routes = useRoutes(Routers);
  const dispatch = useDispatch();
  const userId = localStorage.getItem("userId");
  const isLogedIn = localStorage.getItem("isLogedIn");
  if (userId !== null && isLogedIn === "true") {
    dispatch(getUserById(userId));
  }
  return (
    <div className="App">
      <div>
        <Toaster />
      </div>
      {routes}
    </div>
  );
}

export default App;
