import { all } from "redux-saga/effects";
import { authSaga } from "./auth/authSaga";
import { userSaga } from "./user/userSaga";
import { questionariesSaga } from "./questionaries/questionariesSaga";
import { privacyAndTermsSaga } from "./privacyAndTerms/privacyAndTermsSaga";
import { notifactionSaga } from "./notification/notificationSaga";

export default function* rootSaga() {
  yield all([
    authSaga(),
    userSaga(),
    questionariesSaga(),
    notifactionSaga(),
    privacyAndTermsSaga(),
  ]);
}
