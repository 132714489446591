import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withTranslation } from "react-i18next";
import { getUserById, updateProfile } from "../../../redux/user/userAction";
import { useNavigate } from "react-router-dom";

const ContactData = ({ t }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [userDetails, setUserDetails] = useState({});
  const [error, setError] = useState({});
  const userData = useSelector((state) => state?.UserReducer?.userDetails);
  const userId = localStorage.getItem("userId");

  useEffect(() => {
    dispatch(getUserById(userId));
  }, [dispatch, userId]);

  useEffect(() => {
    const userDataForPhone = {
      _id: userData?._id,
      firstName: userData?.firstName,
      surName: userData?.surName,
      codeTax: userData?.codeTax,
      email: userData?.email,
      isDeleted: userData?.isDeleted,
      password: userData?.password,
      status: userData?.status,
      isBlock: userData?.isBlock,
      ritiroDate: userData?.ritiroDate,
      role: userData?.role,
      otherTelephoneNumber: userData?.otherTelephoneNumber,
      verificationUserNumber: userData?.verificationUserNumber,
      mobileNumber: userData?.mobileNumber,
    };
    setUserDetails(userDataForPhone);
  }, [userData]);

  const handleChange = (e) => {
    setUserDetails((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = () => {
    dispatch(
      updateProfile({
        body: userDetails,
        id: userId,
        callBack: () => {
          navigate("/user-details");
        },
      })
    );
  };

  useEffect(() => {
    if (userDetails) {
      const userDetailsCopy = { ...userDetails };
      delete userDetailsCopy.isDeleted;
      delete userDetailsCopy.isBlock;
      delete userDetailsCopy.__v;
      delete userDetailsCopy.otherTelephoneNumber;
      delete userDetailsCopy.verificationUserNumber;
      const newErrors = {};

      for (const key in userDetailsCopy) {
        if (userDetailsCopy.hasOwnProperty(key)) {
          if (!userDetailsCopy[key]) {
            newErrors[key] = `Please enter the valid ${key}`;
          }
        }
      }
      console.log("newErrors");
      setError(newErrors);
    }
  }, [userDetails]);

  return (
    <div className="option-detail contact-section">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="right-side address-detail mt-0">
              <div className="input-box">
                <div className="option-box box-shadow-none bg-none p-0">
                  <h3>{t("Contatti")}</h3>
                 
                </div>
                <div className="address-detail-inner desktop-details">
                  <form action method="POST">
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                        <div className="form-group">
                          <div className="error">
                            {error?.mobileNumber || ""}
                          </div>
                          <label className="profile_details_text">
                            {t("Tel. Cellulare")}
                          </label>
                          <input
                            type="Number"
                            name="mobileNumber"
                            className="form-control"
                            placeholder="+30 000 0000000"
                            value={userDetails?.mobileNumber || ""}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                        <div className="form-group">
                          <div className="error">
                            {error?.otherTelephoneNumber || ""}
                          </div>
                          <label className="profile_details_text">
                            {t("Altro recapito telefonico")}
                          </label>
                          <input
                            type="Number"
                            name="otherTelephoneNumber"
                            className="form-control"
                            placeholder="+30 000 0000000"
                            value={userDetails?.otherTelephoneNumber || ""}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className="form-group">
                          <div className="error">{error?.email || ""}</div>
                          <label className="profile_details_text">E-mail</label>
                          <input
                            type="email"
                            name="email"
                            className="form-control"
                            value={userDetails?.email || ""}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              {Object.keys(error).length > 0 ? (
                <>
                  <div
                    style={{
                      margin: "15px 40px 0px 40px",
                      textAlign: "center",
                    }}
                  >
                    <span style={{ color: "red" }}>
                      Per procedere e necessario compilare tutti i campi
                    </span>
                  </div>
                </>
              ) : (
                <></>
              )}
              <div className="btn-section text-center mt-50 salva-button">
                <button
                  disabled={Object.keys(error).length > 0}
                  onClick={handleSubmit}
                  className={`solid-btn ${
                    Object.keys(error).length > 0 ? "fade-btn" : "bg-color"
                  } `}
                >
                  {t("Salva ed esci")}{" "}
                  <span className="lock-img">
                    {Object.keys(error).length > 0 ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="1em"
                        height="1em"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="currentColor"
                          d="M6 22q-.825 0-1.412-.587T4 20V10q0-.825.588-1.412T6 8h1V6q0-2.075 1.463-3.537T12 1t3.538 1.463T17 6v2h1q.825 0 1.413.588T20 10v10q0 .825-.587 1.413T18 22zm0-2h12V10H6zm6-3q.825 0 1.413-.587T14 15t-.587-1.412T12 13t-1.412.588T10 15t.588 1.413T12 17M9 8h6V6q0-1.25-.875-2.125T12 3t-2.125.875T9 6zM6 20V10z"
                        />
                      </svg>
                    ) : (
                      ""
                    )}
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(ContactData);
