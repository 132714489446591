import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { withTranslation } from "react-i18next";
import moment from "moment";
import gardeningPlant from "../../../assets/images/survey_available_first.png";
import completedFormImg from "../../../assets/images/welcome.png";
import expireSVG from "../../../assets/images/expire.png";
import { getUserById } from "../../../redux/user/userAction";
import { getQuestionariesList } from "../../../redux/questionaries/questionariesAction";
import { getTabName } from "../../../redux/navigatePath/navigateAction";

const HomePage = ({ t }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userDetails: loggedInUser } = useSelector(
    (state) => state.UserReducer
  );
  const questionariesList = useSelector(
    (state) => state.QuestionariesReducer.questionariesList?.data || []
  );

  const getFirstFillUp = questionariesList?.filter((item) => {
    return (
      (item?.masteranswers?.status === "Partially Completed" ||
        item?.masteranswers === null) &&
      item?.sortTitle === "QB1"
    );
  });
  const getSecondFillUp = questionariesList?.filter((item) => {
    return (
      (item?.masteranswers?.status === "Partially Completed" ||
        item?.masteranswers === null) &&
      item?.sortTitle === "QB2"
    );
  });

  const FilterBaseLineOneExpried = questionariesList?.filter((item) => {
    const currentDate =
      // "17/09/2025";
      moment().add(30, "days").format("DD/MM/YYYY");
    const date1 = moment(currentDate, "DD/MM/YYYY");
    const currentFormDate = moment(item?.userData?.createdAt)
      .add(210, "days")
      .format("DD/MM/YYYY");
    const date2 = moment(currentFormDate, "DD/MM/YYYY");
    const parsedDate = moment(item?.childData?.data_presunt, "DD/MM/YYYY");
    const newDate = parsedDate.add(210, "days");
    const newDateString = newDate.format("DD/MM/YYYY");
    const GetDate2 = moment(newDateString, "DD/MM/YYYY");
    const getTheBaseLineOneExp =
      item.sortTitle === "QB1"
        ? date1.isSameOrAfter(date2)
        : date1.isSameOrAfter(GetDate2);

    return getTheBaseLineOneExp;
  });

  useEffect(() => {
    const userId = localStorage.getItem("userId");
    dispatch(getUserById(userId));
    dispatch(getQuestionariesList({ pageNo: 0, limit: 10 }));
    dispatch(getTabName({ tabName: "", tabAction: "" }));
  }, [dispatch]);

  function capitalizeFirstLetter(str) {
    if (!str) return ""; // Check for empty string
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }
  const namedata = capitalizeFirstLetter(loggedInUser?.firstName);
  return (
    <div className="option-detail welcome_section fill-in-home">
      <div className="container">
        <div className="row align-items-center column-r">
          <div className="col-lg-5">
            <div className="left-side box-shadow-none bg-none justify-content-center p-0">
              {FilterBaseLineOneExpried.length !== 0 && (
                <img src={expireSVG} alt="welcome" className="img-fluid" />
              )}
              <div className="meditation-yaga-img">
                {getFirstFillUp.length === 0 &&
                  getSecondFillUp.length === 0 &&
                  FilterBaseLineOneExpried.length === 0 && (
                    <img
                      src={completedFormImg}
                      alt="welcome"
                      className="img-fluid"
                    />
                  )}
                {(getFirstFillUp.length !== 0 ||
                  getSecondFillUp.length !== 0) &&
                  FilterBaseLineOneExpried.length === 0 && (
                    <img
                      src={gardeningPlant}
                      alt="welcome"
                      className="img-fluid"
                    />
                  )}
              </div>
            </div>
          </div>
          <div className="col-lg-7">
            <div className="right-side text-center">
              <div className="meditation-yaga-section">
                <h2>
                  {t("Ciao")}, {namedata}
                </h2>
                {FilterBaseLineOneExpried.length !== 0 && (
                  <h3>{t("Un questionario sta per scadere")}</h3>
                )}
                {getFirstFillUp.length === 0 &&
                  getSecondFillUp.length === 0 &&
                  FilterBaseLineOneExpried.length === 0 && (
                    <h3>{t("Non ci sono questionari da compilare.")}</h3>
                  )}
                {(getFirstFillUp.length !== 0 ||
                  getSecondFillUp.length !== 0) &&
                  FilterBaseLineOneExpried.length === 0 && (
                    <h3>
                      {t("Hai {{count}} questionario che ti aspetta.", {
                        count: 1,
                      })}
                    </h3>
                  )}
                <div className="btn-section text-center mt-50 mb-0">
                  {FilterBaseLineOneExpried.length !== 0 && (
                    <button
                      onClick={() => {
                        navigate("/questions-list");
                        dispatch(
                          getTabName({
                            tabName: "I tuoi questionari",
                            tabAction: "",
                          })
                        );
                      }}
                      className="solid-btn"
                    >
                      {t("Compilalo subito")}
                    </button>
                  )}
                  {getFirstFillUp.length === 0 &&
                    getSecondFillUp.length === 0 &&
                    FilterBaseLineOneExpried.length === 0 && (
                      <button
                        onClick={() => {
                          navigate("/questions-list");
                          dispatch(
                            getTabName({
                              tabName: "I tuoi questionari",
                              tabAction: "",
                            })
                          );
                        }}
                        className="solid-btn"
                      >
                        {t("Vai alla home")}
                      </button>
                    )}
                  {(getFirstFillUp.length !== 0 ||
                    getSecondFillUp.length !== 0) &&
                    FilterBaseLineOneExpried.length === 0 && (
                      <button
                        onClick={() => {
                          navigate("/questions-list");
                          dispatch(
                            getTabName({
                              tabName: "I tuoi questionari",
                              tabAction: "",
                            })
                          );
                        }}
                        className="solid-btn"
                      >
                        {t("Compila")}
                      </button>
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(HomePage);
