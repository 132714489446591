const {
  SIGN_IN,
  SIGN_IN_SUCCESS,
  SIGN_IN_FAILED,
  CHANGE_USER_LANG,
  SIGN_UP,
  SIGN_UP_SUCCESS,
  SIGN_UP_FAILED,
  VERIFY_ACC,
  VERIFY_ACC_SUCCESS,
  VERIFY_ACC_FAILED,
  VERIFY_RESET_EMAIL,
  VERIFY_RESET_EMAIL_SUCCESS,
  VERIFY_RESET_EMAIL_FAILED,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILED,
  DIRECT_ACCOUNT_VERIFICATION_IN,
  DIRECT_ACCOUNT_VERIFICATION_IN_SUCCESS,
  DIRECT_ACCOUNT_VERIFICATION_IN_FAILED,
  SEND_PDF_EMAIL,
  SEND_PDF_EMAIL_FAILED,
  SEND_PDF_EMAIL_SUCCESS,
} = require("../actionType");

const initialState = {
  data: null,
  loading: false,
  lang: localStorage.getItem("lang") || "it",
  error: null,
};

function AuthReducer(state = initialState, action) {
  switch (action.type) {
    case SIGN_IN:
      return {
        ...state,
        loading: true,
        data: action.payload,
      };

    case SIGN_IN_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        lang: "it",
      };
    case SIGN_IN_FAILED:
      return {
        ...state,
        loading: false,
        error: "Something went wrong",
      };
    case SIGN_UP:
      return {
        ...state,
        loading: true,
        data: action.payload,
      };
    case SIGN_UP_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        lang: "it",
      };
    case SIGN_UP_FAILED:
      return {
        ...state,
        loading: false,
        error: "Something went wrong",
      };
    case VERIFY_ACC:
      return {
        ...state,
        loading: true,
        data: action.payload,
      };
    case VERIFY_ACC_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        lang: "it",
      };
    case VERIFY_ACC_FAILED:
      return {
        ...state,
        loading: false,
        error: "Something went wrong",
      };

      case DIRECT_ACCOUNT_VERIFICATION_IN:
        return {
          ...state,
          loading: true,
          data: action.payload,
        };
      case DIRECT_ACCOUNT_VERIFICATION_IN_SUCCESS:
        return {
          ...state,
          loading: false,
          data: action.payload,
          lang: "it",
        };
      case DIRECT_ACCOUNT_VERIFICATION_IN_FAILED:
        return {
          ...state,
          loading: false,
          error: "Something went wrong",
        };
    case VERIFY_RESET_EMAIL:
      return {
        ...state,
        loading: true,
        data: action.payload,
      };
    case VERIFY_RESET_EMAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        lang: "it",
      };
    case VERIFY_RESET_EMAIL_FAILED:
      return {
        ...state,
        loading: false,
        error: "Something went wrong",
      };
    case RESET_PASSWORD:
      return {
        ...state,
        loading: true,
        data: action.payload,
      };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        lang: "it",
      };
    case RESET_PASSWORD_FAILED:
      return {
        ...state,
        loading: false,
        error: "Something went wrong",
      };

      case SEND_PDF_EMAIL:
        return {
          ...state,
          loading: true,
          data: action.payload,
        };
      case SEND_PDF_EMAIL_SUCCESS:
        return {
          ...state,
          loading: false,
          data: action.payload,
          lang: "it",
        };
      case SEND_PDF_EMAIL_FAILED:
        return {
          ...state,
          loading: false,
          error: "Something went wrong",
        };

    case CHANGE_USER_LANG:
      return {
        ...state,
        lang: action.payload,
      };
    default:
      return state;
  }
}

export default AuthReducer;
