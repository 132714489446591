import React, { Suspense } from "react";
import { Navigate, Outlet } from "react-router-dom";
import Footer from "../components/footer/Footer";
import Header from "../components/header/Header";
import { CSpinner } from "@coreui/react";

const AuthLayout = () => {
  const token = localStorage.getItem("token");
  console.log("🚀 ~ AuthLayout ~ token:", token);
  return (
    <>
      {token !== null ? (
        <Navigate to={"/"} />
      ) : (
        <div>
          <Header />
          <div className="wrapper d-flex flex-column">
            <div className="body flex-grow-1 min-height">
              <Suspense fallback={<CSpinner color="primary" />}>
                <Outlet />
              </Suspense>
            </div>
          </div>
          <Footer />
        </div>
      )}
    </>
  );
};

export default AuthLayout;
