import React, { Suspense } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { CSpinner } from "@coreui/react";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";

const MainLayout = () => {
  const token = localStorage.getItem("token");
  console.log("🚀 ~ MainLayout ~ token:", token);
  return (
    <>
      {token === null ? (
        <Navigate to={"/auth/signIn"} />
      ) : (
        <div>
          <Header />
          <div className="option-detail">
            <div className="wrapper d-flex flex-column">
              <div className="body flex-grow-1 px-3 min-height">
                <Suspense fallback={<CSpinner color="primary" />}>
                  <Outlet />
                </Suspense>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      )}
    </>
  );
};

export default MainLayout;
