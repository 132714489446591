import React from "react";
import mail from "../../../assets/images/mailBaloonMessage.svg";
import { withTranslation } from "react-i18next";

const Ottimio = ({ t }) => {

  return (
    <div className="option-detail">
      <div className="container">
        <div className="row align-items-center column-r Ottimo_section">
          <div className="col-lg-5">
            <div className="left-side box-shadow-none bg-none justify-content-center p-0">
              <div>
                <img
                  src={mail}
                  alt="Mail"
                  className="img-fluid grazie-img"
                />
              </div>
            </div>
          </div>
          <div className="col-lg-7">
            <div className="right-side text-center ">
              <div className="meditation-yaga-section">
                <h2>{t("Grazie!")}</h2>
                <p>
                  {t(
                    "Ti abbiamo inviato un’email per completare la registrazione."
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(Ottimio);
