import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getUserById, updateProfile } from "../../../redux/user/userAction";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { YearPicker, MonthPicker, DayPicker } from "react-dropdown-date";

const PersonalData = ({ t }) => {
  const [date, setDate] = useState({ year: "", month: "", day: "" });
  const [monthError, setMonthError] = useState(false);
  const [dayError, setDayError] = useState(false);
  const [yearError, setYearError] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const [error, setError] = useState({});
  const userData = useSelector((state) => state?.UserReducer?.userDetails);
  const userId = localStorage.getItem("userId");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserById(userId));
  }, [dispatch, userId]);

  useEffect(() => {
    if (userData) {
      const birthDate =
        userData?.birthDate === undefined
          ? userData?.address?.m_data_nasc === undefined
            ? ""
            : userData?.address?.m_data_nasc
          : userData?.birthDate;
      let [day, month, year] = birthDate?.split("/");
      day = parseInt(day, 10);
      month = parseInt(month, 10);
      setDate({ day: day, month: month - 1, year: year });
      const updateData = {
        birthPlace: userData?.address?.m_comune || "",
        village: userData?.address?.m_naz || "",
        ...userData,
      };
      setUserDetails(updateData);
    }
  }, [userData]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    const trimmedValue =
      value?.length > 0 ? value.trimStart().replace(/  +/g, " ") : value;
    let formattedValue = trimmedValue;

    if (name === "birthDate") {
      let month = parseInt(value.month) + 1;
      let birthDateValue = moment(
        `${value.day}/${month}/${value.year}`,
        "DD/MM/YYYY"
      ).format("DD/MM/YYYY");
      console.log("birthDateValue", birthDateValue);
      setUserDetails((prev) => ({
        ...prev,
        [name]: birthDateValue,
      }));
    } else if (name === "firstName" || name === "surName") {
      formattedValue =
        trimmedValue.charAt(0).toUpperCase() + trimmedValue.slice(1);
      setUserDetails((prev) => ({
        ...prev,
        [name]: formattedValue,
      }));
    } else {
      const values =
        e.target.name === "codeTax"
          ? e.target.value.toUpperCase()
          : e.target.value;
      setUserDetails((prev) => ({
        ...prev,
        [e.target.name]: values,
      }));
    }
  };

  useEffect(() => {
    if (userDetails) {
      const userDetailsCopy = {
        firstName: userDetails.firstName,
        surName: userDetails.surName,
        birthDate: userDetails.birthDate,
        birthPlace: userDetails.birthPlace,
        village: userDetails.village,
        codeTax: userDetails.codeTax,
      };

      const newErrors = {};

      for (const key in userDetailsCopy) {
        if (userDetailsCopy.hasOwnProperty(key)) {
          if (!userDetailsCopy[key]) {
            newErrors[key] = "*Campo obbligatorio";
          } else if (
            key === "codeTax" &&
            !isValidCodeTax(userDetailsCopy[key])
          ) {
            newErrors[key] = "Inserire un Codice Fiscale valido";
          }
        }
      }
      setError(newErrors);
    }
  }, [userDetails]);

  // Function to validate codeTax
  const isValidCodeTax = (codeTax) => {
    // Check if codeTax is a valid Italian tax code format
    // Example validation: assuming codeTax should be 16 characters alphanumeric
    const codeTaxRegex = /^[A-Z0-9]{16}$/; // Regular expression for 16 alphanumeric characters
    return codeTaxRegex.test(codeTax);
  };

  const handleSubmit = () => {
    dispatch(
      updateProfile({
        body: userDetails,
        id: userId,
        callBack: () => {
          navigate("/user-details");
        },
      })
    );
  };

  return (
    <div className="option-detail personal-detail">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="right-side address-detail personal-section mt-0">
              <div className="input-box">
                <div className="option-box box-shadow-none bg-none p-0">
                  <h3>{t("Dati personali")}</h3>
                </div>
                <div className="address-detail-inner desktop-details">
                  <form action method="POST">
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                        <div className="form-group">
                          <div className="error">{error?.firstName || ""}</div>
                          <label className="profile_details_text">
                            {" "}
                            {t("Nome")}
                          </label>
                          <input
                            type="text"
                            name="firstName"
                            className="form-control"
                            value={userDetails?.firstName || ""}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                        <div className="form-group">
                          <div className="error">{error?.surName || ""}</div>
                          <label className="profile_details_text">
                            {" "}
                            {t("Cogome")}
                          </label>
                          <input
                            type="text"
                            name="surName"
                            className="form-control"
                            value={userDetails?.surName || ""}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className="form-group">
                          {monthError ? (
                            <div className="error">
                              Please Select the Valid Month
                            </div>
                          ) : (
                            ""
                          )}
                          {dayError ? (
                            <div className="error">
                              Please Select the Valid Day
                            </div>
                          ) : (
                            ""
                          )}
                          {yearError ? (
                            <div className="error">
                              Please Select the Valid year
                            </div>
                          ) : (
                            ""
                          )}
                          <label className="profile_details_text">
                            {t("Data di nascita")}
                          </label>

                          <>
                            <div className="d-flex justify-content-center birth-date">
                              <DayPicker
                                defaultValue="DD"
                                year={date.year} // mandatory
                                month={date.month} // mandatory
                                endYearGiven // mandatory if end={} is given in YearPicker
                                value={date.day} // mandatory
                                onChange={(day) => {
                                  day === undefined || day === ""
                                    ? setDayError(true)
                                    : setDayError(false);
                                  const updatedDate = { ...date, day };

                                  setDate(updatedDate);
                                  handleChange({
                                    target: {
                                      name: "birthDate",
                                      value: updatedDate,
                                    },
                                  });
                                }}
                                id={"day"}
                                name="birthDate"
                                classes={`dropdown ${dayError ? "error" : ""}`}
                                optionClasses={"option"}
                              />

                              <MonthPicker
                                defaultValue="MM"
                                numeric // to get months as numbers
                                endYearGiven // mandatory if end={} is given in YearPicker
                                year={date.year} // mandatory
                                value={date.month} // mandatory
                                onChange={(month) => {
                                  month === undefined || month === ""
                                    ? setMonthError(true)
                                    : setMonthError(false);
                                  const updatedDate = { ...date, month };
                                  setDate(updatedDate);
                                  handleChange({
                                    target: {
                                      name: "birthDate",
                                      value: updatedDate,
                                    },
                                  });
                                }}
                                id={"month"}
                                classes={`dropdown ${
                                  monthError ? "error" : ""
                                }`}
                                name="birthDate"
                                optionClasses={"option"}
                              />

                              <YearPicker
                                defaultValue="YY"
                                start={1954} // default is 1900
                                end={moment().year()} // default is current year
                                reverse // default is ASCENDING
                                value={date.year} // mandatory
                                onChange={(year) => {
                                  year === undefined || year === ""
                                    ? setYearError(true)
                                    : setYearError(false);
                                  const updatedDate = { ...date, year };
                                  setDate(updatedDate);
                                  handleChange({
                                    target: {
                                      name: "birthDate",
                                      value: updatedDate,
                                    },
                                  });
                                }}
                                id={"year"}
                                classes={`dropdown ${yearError ? "error" : ""}`}
                                name="birthDate"
                                optionClasses={"option"}
                              />
                            </div>
                          </>
                          {monthError ? (
                            <div className="error">
                              Please Select the Valid Month
                            </div>
                          ) : (
                            ""
                          )}
                          {dayError ? (
                            <div className="error">
                              Please Select the Valid Day
                            </div>
                          ) : (
                            ""
                          )}
                          {yearError ? (
                            <div className="error">
                              Please Select the Valid year
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                        <div className="form-group">
                          <div className="error">{error?.birthPlace || ""}</div>
                          <label className="profile_details_text">
                            {t("Luogo di nascita")}
                          </label>
                          <input
                            type="text"
                            name="birthPlace"
                            className="form-control"
                            value={userDetails?.birthPlace || ""}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                        <div className="form-group">
                          <div className="error">{error?.village || ""}</div>
                          <label className="profile_details_text">
                            {" "}
                            {t("Paese")}
                          </label>
                          <input
                            type="text"
                            name="village"
                            className="form-control"
                            value={userDetails?.village || ""}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className="form-group">
                          <div className="error">{error?.codeTax || ""}</div>
                          <label className="profile_details_text">
                            {t("Cod. Fiscale")}
                          </label>
                          <input
                            type="text"
                            name="codeTax"
                            className="form-control"
                            value={userDetails?.codeTax || ""}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              {Object.keys(error).length > 0 ? (
                <>
                  <div
                    style={{
                      margin: "15px 30px 0px 30px",
                      textAlign: "center",
                    }}
                  >
                    <span style={{ color: "red" }}>
                      Per procedere e necessario compilare tutti i campi
                    </span>
                  </div>
                </>
              ) : (
                <></>
              )}
              <div className="btn-section text-center mt-50 salva-button">
                <button
                  disabled={
                    Object.keys(error).length > 0 ||
                    dayError ||
                    monthError ||
                    yearError
                      ? true
                      : false
                  }
                  onClick={handleSubmit}
                  className={`solid-btn ${
                    Object.keys(error).length > 0 ? "fade-btn" : "bg-color"
                  } `}
                >
                  {t("Salva ed esci")}{" "}
                  <span className="lock-img">
                    {Object.keys(error).length > 0 ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="1em"
                        height="1em"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="currentColor"
                          d="M6 22q-.825 0-1.412-.587T4 20V10q0-.825.588-1.412T6 8h1V6q0-2.075 1.463-3.537T12 1t3.538 1.463T17 6v2h1q.825 0 1.413.588T20 10v10q0 .825-.587 1.413T18 22zm0-2h12V10H6zm6-3q.825 0 1.413-.587T14 15t-.587-1.412T12 13t-1.412.588T10 15t.588 1.413T12 17M9 8h6V6q0-1.25-.875-2.125T12 3t-2.125.875T9 6zM6 20V10z"
                        />
                      </svg>
                    ) : (
                      ""
                    )}
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(PersonalData);
