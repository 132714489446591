export const endPoints = {
  // Admin
  LOGIN: "/auth/login",
  REGISTER: "/auth/user-register",
  DIRECT_ACCOUNT_VERIFICATION: "/auth/account-verification",
  VERIFY_ACC: "/auth/mobile-verification",
  VERIFY_OTP: "/auth/verification-code",
  REVERIFY_ACC: "auth/verification-resendcode",
  UPLOADMOTHERID: "auth/document-verification",
  UPLOADDADID: "auth/document-verification-update",
  // GET_USER: '/auth/user-profile',
  UPDATE_PROFILE: "/auth/update-profile/:id",
  // ADD_USER: '/auth/register',
  // UPDATE_STATUS: '/user/update-status/:id',
  GET_USER_BY_ID: "/user/get-user-details/:id",
  GET_QUESTIONS_LIST: "/questionbook/get-questionaries-list",
  GET_SECTIONS_BY_ID: "/questionbook/get-section-by-form/:formId/:userId",
  GET_FORM: "/questionbook/get-form",
  GET_FORM_BY_ID: "/questionbook/get-questionarie-data/:id",
  SUBMIT_FORM: "/questionbook/submit-question-form",
  UPDATE_SUBMIT_FORM: "/questionbook/update-question-form",
  GET_SUBMIT_FORM: "/questionbook/get-submit-answer-form",
  GET_PRIVACY_AND_TERMS_LIST: "/privacy/get-privacy-and-terms-list",
  GET_NOTIFICATION: "/notification/get-notification-list",
  UPDATE_NOTIFICATION: "/notification/update-notification",
  VERIFY_RESET_MAIL: "auth/forgot-password",
  RESET_PASSWORD: "auth/reset-password",
  SEND_PDF_EMAIL: "/questionbook/send-email",

  // UPDATE_FORM: '/questionbook/updateQuestion/:id',
  // GET_SINGLE_FORM: '/questionbook/get-single-form/:id',
};
