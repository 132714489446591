import { toast } from 'react-hot-toast' // Assuming you are using react-toastify for toasts

const CommonToaster = ({ toastId, toastType, message }) => {
  if (toastType === 'success') {
    toast.success(message, {
      id: toastId,
    })
  } else if (toastType === 'error') {
    toast.error(message, {
      id: toastId,
    })
  }
}

export default CommonToaster
