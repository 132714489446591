import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { reVerifyAcc, verifyAcc } from "../../../redux/auth/authAction";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const AccountConfermato = ({ t }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { state } = useLocation();

  const { userId } = useParams();
  const [datas, setData] = useState({});
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prev) => ({
      ...prev,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const validateForm = () => {
    const errors = {};
    if (!datas?.phoneNumber) {
      errors.phoneNumber = t("*Campo obbligatorio");
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  /***Handles the submit form of the register***/
  const handleTelefon = (e) => {
    const sendData = { phoneNumber: datas.phoneNumber, userId: userId };
    e.preventDefault();
    const error = validateForm();
    if (error) {
      state?.resendCode === true
        ? dispatch(
            reVerifyAcc({
              sendData,
              callBack: () => {
                navigate("/auth/verifiaction-account");
              },
            })
          )
        : dispatch(
            verifyAcc({
              sendData,
              callBack: () => {
                navigate("/auth/verifiaction-account");
              },
            })
          );
    }
  };

  return (
    <div className="option-detail">
      <div className="container">
        <div className="row align-items-center column-r Ottimo_section account_confermato_section">
          <div className="col-lg-7">
            <div className="right-side address-detail">
              <div className="input-box pt-0">
                <div className="address-detail-inner">
                  <form method="POST">
                    <div
                      className={`form-group mt-0 ${
                        errors?.phoneNumber ? "error-content" : ""
                      }`}
                    >
                      {errors?.phoneNumber && (
                        <div className="error">{t(errors?.phoneNumber)}</div>
                      )}
                      <label className="profile_details_text">
                        {t("Numero di telefono")}
                      </label>
                      <input
                        type="number"
                        name="phoneNumber"
                        value={datas.phoneNumber || ""}
                        onChange={handleChange}
                        className="form-control"
                        required
                      />
                    </div>
                  </form>
                  <div className="btn-section text-center">
                    <button className="solid-btn" onClick={handleTelefon}>
                      {t("Procedi")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-5">
            <div className="meditation-yaga-section">
              <h2>
                {t(
                  state?.resendCode === true
                    ? "Inserisci il tuo numero di telefono"
                    : "Account confermato"
                )}
              </h2>
              <p>
                {t(
                  "Inserendo il tuo numero di cellulare riceverai un SMS con uncodice a 6 cifre per verificare il tuo account."
                )}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(AccountConfermato);
