import { combineReducers } from 'redux'
import AuthReducer from './auth/authReducer'
import UserReducer from './user/userReducer'
import QuestionariesReducer from './questionaries/questionariesReducer'
import PrivacyAndTermsReducer from './privacyAndTerms/privacyAndTermsReducer'
import NavigationReducer from './navigatePath/navigateReducer'
import NotificationReducer from './notification/notificationReducer'

const reducers = combineReducers({
  AuthReducer,
  UserReducer,
  QuestionariesReducer,
  PrivacyAndTermsReducer,
  NavigationReducer,
  NotificationReducer
})

export default reducers
